import { Dispatch } from 'react';
import { IAnexos } from '../../../core/models/documentos/anexosModel';

interface IHeader {
  type: string;
  title: string;
  subtitle?: string | breadcrumdHeader[];
}

export interface breadcrumdHeader {
  name: string;
  route?: string
};
interface IModal {
  show: boolean;
  title: string;
  body: React.FC<any> | null;
  size?: string;
}
export interface IMainReducer {
  header?: IHeader;
  modal?: IModal;
  language?: string;
  loading?: boolean
};
export interface IMainActions {
  type: string,
  payload?: IMainReducer
};
export interface IMainContext {
  mainState: IMainReducer,
  mainDispatch: Dispatch<IMainActions>
  handleNotification: (
    msj: string,
    type: mainTypeMsj
  ) => void
  documentosTable: {
    refresh: () => void,
    time: number,
    data: IAnexos[],
    loaded: boolean,
    getData: (modulo: string, idmodulo: string | number | null) => Promise<void>,
  }
};

// Enums
export enum mainTypeHeader {
  SUBTITLE = 'SUBTITLE',
  BREADCRUMB = 'BREADCRUMB'
}

export enum mainTypeMsj {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}
