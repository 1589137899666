import { MUIDataTableColumn } from 'mui-datatables';
import React, { FC } from 'react';
import ListComponent from './ListComponent';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

const ContratosVencidos: FC<any> = ({data, timestamp, handleEdit}) => {

  // Contextos
  const { t } = useTranslation();

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Número de proceso'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Número de proceso')}</h5>
        ),
        customBodyRender: ([consecutivo, id]) => {
          return (
            <Button color="default" onClick={() => handleEdit(id)}>{consecutivo}</Button>
          )
        }
      }
    },
    {
      name: t('Fecha fin'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha fin')}</h5>
        ),
      }
    },
    {
      name: t('Días vencido'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Días vencido')}</h5>
        ),
      }
    },
  ];

  return (
    <>
      <ListComponent
        columns={columns}
        procesos={data?.items}
        currenttime={timestamp}
      />
    </>
  );
};
export default ContratosVencidos;
