import React, { FC } from 'react';
import { IProveedores } from '../../../../core/models/precontractual/proveedoresModel';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';

const TablaCalendarioRenegociacion: FC<ITablaCalendarioRenegociacion> = ({ record }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th align="center">{t('Actividades a Realizar')}</th>
          <th align="center">{t('Fecha y Hora Máxima')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            {t('Renegociación')}
          </td>
          <td align="center">
            {record.finnegociacion}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

interface ITablaCalendarioRenegociacion {
  record: IProveedores;
}

export default TablaCalendarioRenegociacion;
