import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Spinner, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarProducto, cargaMasivaProducto, guardarProducto } from '../../../../core/services/precontractual/productoService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { IProductoForm } from '../../../../core/models/precontractual/productoModel';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { EEstadosPre, IBorrarRegistro } from '../../../../shared/utilities';
import ModalMsj from '../../../../shared/components/ModalMsj';
import FormUnidadMedida from '../Forms/FormUnidadMedida';
import { guardarUnidadMedida } from '../../../../core/services/administracion/unidadMedidaService';
import { IUnidadMedida, IUnidadMedidaForm } from '../../../../core/models/administracion/unidadMedidaModel';
import FormProducto from '../Forms/FormProducto';
import CurrencyFormat from 'react-currency-format';
import { listMoneda } from '../../../../core/services/listasDesplegables';
import FormCargaMasiva from '../../../documentos/cargaMasiva/FormCargaMasiva';
import { ICargaMasiva } from '../../../../core/models/documentos/cargaMasivaModel';
import ErroresCargaMasiva from '../../../documentos/cargaMasiva/ErroresCargaMasiva';

const TablaProducto: FC = () => {
  const { t } = useTranslation();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { estado, proceso, productos, obtenerProductos, roles } = usePrecontractualContext();

  const [loading, setLoading] = useState(true);

  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const habilitar = useMemo(() => {
    if (estado === EEstadosPre.CRITERIOS_EVALUACION && roles.solicitante) {
      return true;
    } else {
      return false;
    }
  }, [estado, roles]);

  const listarDatos = async () => {
    await obtenerProductos();
    setLoading(false);
  }

  const onSubmit = async (values: IProductoForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarProducto(values, handleNotification);
    if (guardar) {
      await listarDatos();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const onSubmitCargaMasiva = async (carga: ICargaMasiva) => {
    mainDispatch(trueLoading());
    const values = {
      idcargamasiva: carga.id,
    }
    const guardar = await cargaMasivaProducto(values, handleNotification);
    if (guardar) {
      mainDispatch(closeModal());
      if (guardar.errors) {
        mainDispatch(openModal({
          modal: {
            show: true,
            title: t('Errores encontrados en la Carga Masiva'),
            body: () => (
              <ErroresCargaMasiva response={guardar} />
            )
          }
        }))
      } else {
        await obtenerProductos();
      }
    }
    mainDispatch(falseLoading());
  }

  const onSubmitUnidad = async (values: IUnidadMedidaForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarUnidadMedida(values, handleNotification);
    if (guardar) {
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarProducto(id, handleNotification)) {
      await listarDatos();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Creación Productos o Servicios'),
        body: () => (
          <FormProducto
            onSubmit={onSubmit}
            record={null}
          />
        )
      }
    }))
  };

  const handleCargaMasiva = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Carga Masiva de Productos'),
        body: () => (
          <FormCargaMasiva
            callback={onSubmitCargaMasiva}
            entidad="PrePrecontractual"
            identidad={proceso?.id ?? 0}
            nombrecarga="productos_precontractual"
            record={null}
            plantilla="/plantillas/plantilla-productos.xlsx"
          />
        )
      }
    }))
  };

  const handleAddUnidad = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Agregar Unidad de Medida'),
        body: () => (
          <FormUnidadMedida
            onSubmit={onSubmitUnidad}
            record={null}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = productos.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar Producto o Servicio'),
        body: () => (record ? (
          <FormProducto
            onSubmit={onSubmit}
            record={record}
          />
         ) : null
        )
      }
    }))
  };

  useEffect(() => {
    listarDatos();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando productos...')}
          </span>
        </div>
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th align="center">{t('Nombre Producto y/o Servicio')}</th>
                <th align="center">{t('Cantidad')}</th>
                <th align="center">{t('Unidad de Medida')}</th>
                <th align="center">{t('Valor Unitario')}</th>
                <th align="center">{t('Valor Total')}</th>
                <th align="center">{t('Tipo de Moneda')}</th>
                {habilitar && (
                  <th align="center">{t('Acción')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {productos.map(row => (
                <tr key={row.id}>
                  <td align="center">{row.producto}</td>
                  <td align="center">{row.cantidad}</td>
                  <td align="center">
                  {row.idunidadmedida.unidad} ({row.idunidadmedida.alias})
                  </td>
                  <td align="center">
                    <CurrencyFormat
                      value={row.valorunitario}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={',00'}
                    />
                  </td>
                  <td align="center">
                    <CurrencyFormat
                      value={row.valorunitario && row.cantidad ? (Number(row.valorunitario) * row.cantidad) : ''}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={',00'}
                    />
                  </td>
                  <td align="center">
                    {listMoneda.find(item => item.id === row.tipomoneda)?.nombre}
                  </td>
                  {habilitar && (
                    <td align="center">
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleEdit(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Editar')}
                      >
                        <i className="uil-edit"></i>
                      </Button>
                      <Button
                        color="danger"
                        className="mr-1"
                        onClick={() => changeModal(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Eliminar')}
                      >
                        <i className="uil-trash-alt"></i>
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {productos.length === 0 && (
            <Alert color="warning">{t('No se han agregado productos.')}</Alert>
          )}
          {habilitar && (
            <>
              <ButtonCustom
                color="info"
                onClick={handleAdd}
                loading={mainState.loading}
                disabled={mainState.loading}
                outline
                className="mb-2"
                size="sm"
              >
                <i className="uil-mr uil-plus"></i>{t('Agregar Producto')}
              </ButtonCustom>
              <ButtonCustom
                color="success"
                onClick={handleCargaMasiva}
                loading={mainState.loading}
                disabled={mainState.loading}
                outline
                className="mb-2 ml-2"
                size="sm"
              >
                <i className="uil-mr uil-plus"></i>{t('Carga Masiva Productos')}
              </ButtonCustom>
              <ButtonCustom
                color="warning"
                onClick={handleAddUnidad}
                loading={mainState.loading}
                disabled={mainState.loading}
                outline
                className="mb-2 ml-2"
                size="sm"
              >
                <i className="uil-mr uil-plus"></i>{t('Crear Unidad de Medida')}
              </ButtonCustom>
            </>
          )}
        </>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

export default TablaProducto;
