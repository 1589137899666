import React, { FC, useEffect, useState } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IProducto, IProductoForm } from '../../../../core/models/precontractual/productoModel';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import NumberField from '../../../../shared/components/NumberField';
import { IUnidadMedida } from '../../../../core/models/administracion/unidadMedidaModel';
import { listarUnidadMedida } from '../../../../core/services/administracion/unidadMedidaService';
import UniqueSelectField from '../../../../shared/components/UniqueSelectField';
import { listMoneda } from '../../../../core/services/listasDesplegables';

const FormProducto: FC<IFormProducto> = ({ onSubmit, record }) => {
  const { proceso } = usePrecontractualContext();
  const { t } = useTranslation();

  const [unidadmedida, setUnidadmedida] = useState<IUnidadMedida[]>([]);

  const listarDatos = async () => {
    setUnidadmedida(await listarUnidadMedida());
  }

  const habilitar = true;

  const initialValues: IProductoForm = {
    id: null,
    producto: '',
    cantidad: '',
    valorunitario: '',
    tipomoneda: 0,
    trm: '',
    activo: 1,
    ...record,
    idprecontractual: proceso?.id ?? 0,
    idcargamasiva: record?.idcargamasiva?.id ?? 0,
    idunidadmedida: record?.idunidadmedida.id ?? 0,
  } as IProductoForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    producto: Yup.string().required(FormValidation.required),
    cantidad: Yup.number().required(FormValidation.required).min(1, FormValidation.required),
    valorunitario: Yup.number().required(FormValidation.required).min(0, FormValidation.required),
    tipomoneda: Yup.number().min(1, FormValidation.required),
    trm: Yup.string().when('tipomoneda', {
      is: (tipomoneda) => tipomoneda !== 1,
      then: Yup.string().required(FormValidation.required),
      otherwise: Yup.string(),
    }),
    idunidadmedida: Yup.number().min(1, FormValidation.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  useEffect(() => {
    listarDatos();
  }, []);

  return (
    <Form id="FormProducto" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Productos')}</Label>
                <Field
                  as={Input}
                  name="producto"
                  invalid={errors.producto && touched.producto}
                  disabled={!habilitar}
                />
                <FormFeedback>{errors.producto && t(errors.producto)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Cantidad')}</Label>
                <Field
                  as={NumberField}
                  name="cantidad"
                  invalid={errors.cantidad && touched.cantidad}
                  disabled={!habilitar}
                />
                <FormFeedback>{errors.cantidad && t(errors.cantidad)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Unidad de Medida')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="idunidadmedida"
                  invalid={errors.idunidadmedida && touched.idunidadmedida}
                  disabled={!habilitar}
                  options={unidadmedida.map(item => ({
                    value: item.id,
                    label: `${item.unidad} (${item.alias})`,
                  }))}
                />
                <FormFeedback>{errors.idunidadmedida && t(errors.idunidadmedida)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Valor Unitario')}</Label>
                <Field
                  as={NumberField}
                  name="valorunitario"
                  invalid={errors.valorunitario && touched.valorunitario}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{errors.valorunitario && t(errors.valorunitario)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Tipo de Moneda')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="tipomoneda"
                  invalid={errors.tipomoneda && touched.tipomoneda}
                  options={listMoneda.map(item => ({
                    label: item.nombre,
                    value: item.id,
                  }))}
                  disabled={!habilitar}
                />
                <FormFeedback>{errors.tipomoneda && t(errors.tipomoneda)}</FormFeedback>
              </FormGroup>
            </Col>
            {Number(values.tipomoneda) > 1 && (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('TRM')}</Label>
                  <Field
                    as={NumberField}
                    name="trm"
                    invalid={errors.trm && touched.trm}
                    disabled={!habilitar}
                    currency
                  />
                  <FormFeedback>{errors.trm && t(errors.trm)}</FormFeedback>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormProducto"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormProducto {
  record: IProducto | null;
  onSubmit: (values: IProductoForm) => Promise<void>;
}

export default FormProducto;
