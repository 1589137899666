import React, { createContext, useContext, useState, useMemo } from "react";
import { userContext } from ".";
import {
  cambiarEstadoService, obtenerAsesoriaService, obtenerUsuariosService, obtenerDocumentosService,
  guardarAsesoriaService,
  obtenerAnsService
} from "../../core/services/asesorias/asesoriasService";
import { ERolsProceso } from "../utilities";
import { mainContext } from "./mainContext";

export interface IRolesAsesoria {
  solicitante: boolean;
  coordinador: boolean;
  abogado: boolean;
  jefejuridico: boolean;
}
export interface IAsesoriasContext {
  id: number;
  loadedAsesoria: boolean;
  loading: boolean;
  asesoria: any;
  usuarios: any;
  documentos: any;
  ans: any;
  roles: IRolesAsesoria;
  changeState: (action: 'prev' | 'next') => void;
  getAsesoria: (id: number) => void;
  guardarAsesoria: (values: any, file, resetForm: any) => void;
  resetAsesoria: () => void;
  setLoadedAsesoria: (state: boolean) => void;
};

export const asesoriasContext = createContext<IAsesoriasContext | undefined>(undefined);

export const AsesoriasProvider = ({ children }) => {

  // Context
  const { handleNotification } = useContext(mainContext);
  const { userState } = useContext(userContext);

  // State
  const [id, setId] = useState<number>(0);
  const [loadedAsesoria, setLoadedAsesoria] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [asesoria, setAsesoria] = useState<any>(undefined);
  const [usuarios, setUsuarios] = useState<any>(undefined);
  const [documentos, setDocumentos] = useState<any>(undefined);
  const [ans, setAns] = useState<any[] | undefined>([]);

  const roles: IRolesAsesoria = useMemo(() => {
    return {
      solicitante: usuarios?.find(user => user.idusuarios?.id == userState.data?.sub && user.rol === ERolsProceso.SOLICITANTE),
      coordinador: usuarios?.find(user => user.idusuarios?.id == userState.data?.sub && user.rol === ERolsProceso.COORDINADOR),
      abogado: usuarios?.find(user => user.idusuarios?.id == userState.data?.sub && user.rol === ERolsProceso.ABOGADO),
      jefejuridico: usuarios?.find(user => user.idusuarios?.id == userState.data?.sub && user.rol === ERolsProceso.JEFEJURIDICO),
    }
  }, [usuarios]);

  // CHANGE STATE
  const changeState = async (action: 'prev' | 'next') => {
    setLoading(true);
    const data = {
      action,
      id
    };
    const response = await cambiarEstadoService(data, handleNotification, true, true);
    if (response.data?.status == "success") {
      getAsesoria(id);
    }
    setLoading(false);
  }

  // GET ASESORIA
  const getAsesoria = async (id: number = 0) => {
    setId(id);

    // Asesoria
    const asesoria = await obtenerAsesoriaService(id);
    setAsesoria(asesoria);

    // Usuarios
    const usuarios = await obtenerUsuariosService('idasesoria', id)
    setUsuarios(usuarios);

    // Documentos
    const documentos = await obtenerDocumentosService({ filter: 'idasesoria', valuefilter: id });
    setDocumentos(documentos);

    // Ans
    const ans = await obtenerAnsService('idasesoria', id);
    setAns(ans);

    setLoadedAsesoria(true);
  }
  // GUARDAR ASESORIA
  const guardarAsesoria = async (values: any, file = false, resetForm) => {
    setLoading(true);
    let response;
    if (response = await guardarAsesoriaService(values, handleNotification, file, true, true)) {
      if (id) {
        if (response?.data?.status == 'success') {
          await getAsesoria(id);
          await resetForm();
        }
      }
    }
    setLoading(false);
    return response;
  };

  // RESET
  const resetAsesoria = () => {
    setId(0);
    setAsesoria(undefined);
    setUsuarios(undefined);
    setDocumentos(undefined);
    setLoadedAsesoria(true);
  }

  return (
    <asesoriasContext.Provider
      value={{
        id,
        loadedAsesoria,
        loading,
        asesoria,
        usuarios,
        documentos,
        ans,
        roles,
        changeState,
        getAsesoria,
        guardarAsesoria,
        resetAsesoria,
        setLoadedAsesoria,
      }}
    >
      {children}
    </asesoriasContext.Provider>
  );
}

export const useAsesoriasContext = () => {
  const context = React.useContext(asesoriasContext)
  if (context === undefined) {
    throw new Error('useAsesoriasContext must be used within a AsesoriasProvider')
  }
  return context
};
