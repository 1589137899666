import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IUsuarios, IUsuariosForm } from '../../models/precontractual/usuariosModel';

/**
 * Permite obtener los registros en la bd
 */
export const listarUsuarios = async (params?): Promise<IUsuarios[]> => {
  let data: IUsuarios[] = [];
  try {
    const resp = await getRequest(urlApi.PRE_USUARIOS.LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarUsuarios = async (id: number): Promise<IUsuarios | null> => {
  let data: IUsuarios | null;
  try {
    const resp = await getRequest(`${urlApi.PRE_USUARIOS.SHOW}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarUsuarios = async (
  values: IUsuariosForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IUsuarios> => {
  const url = (!values.id) ? urlApi.PRE_USUARIOS.CREATE : `${urlApi.PRE_USUARIOS.EDIT}${values.id}`
  const request = await updateItem(url, values, notification, false, true, true);
  return request?.data?.record;
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarUsuarios = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.PRE_USUARIOS.DELETE}${id}`;
  return await deleteItem(url, notification);
};
