import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { IAreas } from '../../models/administracion/areasModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { userLogout } from '../../../shared/contexts/actions/userActions';
import { Emsg } from '../../../shared/contexts/interfaces/userInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerAreas = async (
  dishpath: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<IAreas[]> => {
  let data: IAreas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_AREAS);    
    if (resp.data.code === 403) {
      notification(Emsg.expired, mainTypeMsj.ERROR);
      dishpath(userLogout());
    }
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerAreasDistintas = async (
  dishpath: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<IAreas[]> => {
  let data: IAreas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_AREAS_DISTINCT);
    if (resp.data.code === 403) {
      notification(Emsg.expired, mainTypeMsj.ERROR);
      dishpath(userLogout());
    }
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarArea = async (
  values: IAreas,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_AREA : `${urlApi.EDIT_AREA}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarArea = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_AREA}${id}`;
  return await deleteItem(url, notification);
};
