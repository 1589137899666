import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import NumberFormat, { SyntheticInputEvent } from 'react-number-format';

const NumberField: FC<any> = ({currency, invalid, ...props}) => {
  const { setFieldValue, getFieldProps, setFieldTouched } = useFormikContext();
  return (
    <>
      {
        currency ? (
          <NumberFormat
            {...props}
            className={invalid ? "form-control is-invalid" : "form-control"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            value={getFieldProps(props.name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              const value: string = e.target.value.split('.').join('');
              const formatValue = value.split(',').join('.');
              setFieldValue(props.name, formatValue);
            }}
            onBlur={() => {
              setFieldTouched(props.name, true);
            }}
          />
        ) : (
          <NumberFormat
            {...props}
            className={invalid ? "form-control is-invalid" : "form-control"}
            type="text"
            decimalScale={0}
            allowNegative={false}
            isNumericString
            value={getFieldProps(props.name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              setFieldValue(props.name, e.target.value);
            }}
            onBlur={() => {
              setFieldTouched(props.name, true);
            }}
          />
        )
      }
    </>
  );
};
export default NumberField;
