import React, { FC, useEffect, useMemo, useState } from 'react';
import { IProveedores } from '../../../core/models/precontractual/proveedoresModel';
import { usePrecontractualContext } from '../../../shared/contexts/precontractualContext';
import DataTableSkeleton from '../../../shared/components/DataTableSkeleton';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { changeHeader } from '../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';
import { Alert } from 'reactstrap';
import ViewPreProveedores001 from './components/views/ViewPreProveedores001';
import { EEstadosPre } from '../../../shared/utilities';
import ViewPreProveedores003 from './components/views/ViewPreProveedores003';
import ViewPreProveedores002 from './components/views/ViewPreProveedores002';

const TokenPreProveedores: FC<ITokenPreProveedores> = ({ record }) => {
  const { t } = useTranslation();
  const { mainDispatch } = useMainContext();
  const { estado, obtenerProceso, proceso } = usePrecontractualContext();

  const [loading, setLoading] = useState(true);

  const obtenerDatos = async () => {
    const idProceso = Number(record.idprecontractual.id);
    await obtenerProceso(idProceso);
    setLoading(false);
  }

  const tokenActivo = useMemo(() => {
    if ([EEstadosPre.PREGUNTAS, EEstadosPre.RESPUESTAS, EEstadosPre.CARGUE_PROPUESTAS].includes(estado ?? 0)) {
      return 'Token-001';
    }
    if (estado === EEstadosPre.CARGUE_RENEGOCIACIONES) {
      return 'Token-002';
    }
    if (estado === EEstadosPre.ACEPTACION_ADJUDICACION) {
      return 'Token-003';
    }
    return null;
  }, [estado]);

  useEffect(() => {
      obtenerDatos();
  }, []);

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: proceso ? `${proceso?.consecutivo}: ${proceso?.idestados.nombre}` : '...',
        subtitle: [],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, [proceso]);

  return (
    <>
      {loading ? (
        <DataTableSkeleton />
      ) : (
        <>
          {record.activo === 2 ? (
            <Alert color="danger" className="mb-0">
              {t('El proponente no se encuentra activo en el proceso.')}
            </Alert>
          ) : (
            <>
              {tokenActivo === 'Token-001' && (
                <ViewPreProveedores001 record={record} />
              )}
              {tokenActivo === 'Token-002' && (
                <ViewPreProveedores002 record={record} />
              )}
              {tokenActivo === 'Token-003' && (
                <ViewPreProveedores003 record={record} />
              )}
              {tokenActivo === null && (
                <Alert color="success" className="mb-0">
                  {t('Puede cerrar esta ventana.')}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

interface ITokenPreProveedores {
  record: IProveedores;
}

export default TokenPreProveedores;
