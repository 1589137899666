import React, { FC, useEffect } from "react";
import ReporteUsuarios from "./components/ReporteUsuarios";
import { useTranslation } from "react-i18next";
import { mainTypeHeader } from "../../../shared/contexts/interfaces/mainInterfaces";
import {
  changeHeader,
  changeLoading,
} from "../../../shared/contexts/actions/mainActions";  
import { useMainContext } from "../../../shared/contexts/mainContext";
const ReporteBIUsuarios: FC = () => {
  // contextos
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();
  useEffect(() => {
    mainDispatch(
      changeHeader({
        header: {
          title: t("Comforce® Reports"),
          subtitle: [
            { name: t("Inicio"), route: "/home" },
            { name: t("Generales") },
            { name: t("Comforce® Reports") },
            { name: t("Reporte Usuarios") },
          ],
          type: mainTypeHeader.BREADCRUMB,
        },
      })
    );
  }, []);
  return <ReporteUsuarios />;
};
export default ReporteBIUsuarios;
