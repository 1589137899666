import React, { FC, useState, useContext } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
} from 'reactstrap';
import Main from '../components/Main';
import Wrapper from '../components/Wrapper';
import Content from '../components/Content';
import { ILayout } from "../utilities";
import NavbarComponent from "../components/NavbarComponent";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import HeaderSubtitle from '../components/HeaderSubtitle';
import { mainContext } from "../contexts";
import { breadcrumdHeader, mainTypeHeader } from "../contexts/interfaces/mainInterfaces";
import { Link } from "react-router-dom";

const Dashboard: FC<ILayout> = ({ children }) => {
  const { mainState } = useContext(mainContext);
  const [isOpen, updateOpen] = useState(true);

  return (
    (
      <Wrapper>
        <Sidebar
          isOpen={isOpen}
        />
        <Main className="d-flex flex-column justify-content-between">
          <div>
            <NavbarComponent
              isOpen={isOpen}
              toggle={updateOpen}
            />
            <Content >
              <Container fluid>
                <Header className="d-flex flex-md-row justify-content-md-between">
                  <HeaderTitle tag="h3" >{mainState.header?.title}</HeaderTitle>
                  {
                    mainState.header?.type === mainTypeHeader.SUBTITLE ? (
                      <div className="d-none d-md-block">
                        <HeaderSubtitle>
                          {mainState.header.subtitle}
                        </HeaderSubtitle>
                      </div>
                    ) : (
                      <div className="d-none d-md-block">
                        <Breadcrumb className="d-none d-md-block">
                          {
                            (mainState.header?.subtitle as breadcrumdHeader[])?.map(({ name, route }, i) => (
                              <BreadcrumbItem key={i} >
                                {
                                  route ? (
                                    <Link to={route}>{name}</Link>
                                  ) : name
                                }
                              </BreadcrumbItem>
                            ))
                          }
                        </Breadcrumb>
                      </div>
                    )
                  }
                </Header>
                {children}
              </Container>
            </Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    )
  )
};
export default Dashboard;
