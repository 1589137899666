import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ICodigos, IFormCodigos } from '../../models/documentos/codigosModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerCodigos = async (params?): Promise<ICodigos[]> => {
  let data: ICodigos[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CODIGOS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarCodigos = async (
  values: IFormCodigos,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_CODIGOS : `${urlApi.EDIT_CODIGOS}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarCodigos = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_CODIGOS}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite mostrar un registro
 * @param token 
 * @returns 
 */
export const mostrarCodigos = async (
    codigo: string,
    token: string,
    notification?: (
      msj: string,
      type: mainTypeMsj
    ) => void
  ): Promise<ICodigos|null> => {
  let data: ICodigos|null = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_CODIGOS}${codigo}/${token}`, null, true, notification);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};
