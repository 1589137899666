import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import es from "date-fns/locale/es";
import moment from 'moment';
registerLocale("es", es);

const DateField: FC<IUniqueSelectField> = ({ 
  invalid = false,
  disabled = false,
  hora = false,
  onChange,
  ...props
}) => {
  const { setFieldValue, getFieldProps, setFieldTouched } = useFormikContext();
  const { t } = useTranslation();

  const datePickerFormat = hora ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
  const momentFormat = hora ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

  const getValue = () => {
    const value = getFieldProps(props.name)?.value;
    if (value) {
      const momentDate = moment(value, momentFormat);
      const date = momentDate.isValid() ? momentDate.toDate() : null;
      return date;
    } else {
      return null;
    }
  }
  
  return (
    <ReactDatePicker
      locale="es"
      dateFormat={datePickerFormat}
      autoComplete="off"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={getValue()}
      isClearable
      wrapperClassName={invalid ? `is-invalid ${disabled ? 'calendar-disabled' : ''}` : `${disabled ? 'calendar-disabled' : ''}`}
      className={invalid ? 'is-invalid form-control' : 'form-control'}
      showTimeInput={hora}
      timeInputLabel={t('Hora: ')}
      onChange={(date) => {
        const momentDate = moment(date as Date);
        const value = momentDate.isValid() ? momentDate?.format(momentFormat) : '';
        setFieldValue(props.name, value);
        if (onChange) {
          onChange(value);
        }
      }}
      onBlur={() => {
        setFieldTouched(props.name, true);
      }}
      disabled={disabled}
    />
  )
}

interface IUniqueSelectField {
  field: any;
  invalid?: boolean;
  disabled?: boolean;
  hora?: boolean;
  name?: any;
  value?: any;
  id?: string;
  onChange: (value: string | null) => void;
}

export default DateField;
