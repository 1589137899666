import React, { FC, useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'reactstrap';
import { listarContratosPorEstadoService } from '../../../core/services/usuarios/usuariosService';
import { formatStringtoDateString } from '../../../shared/helpers/Fecha';
import { useOptionsTables } from '../../../shared/hooks/useOptionsTables';
import { useMainContext } from '../../../shared/contexts/mainContext';

const ContratosPorEstado: FC<IContratosPorEstado> = ({ handleEdit, idestado, idmodulo, nombremodulo }) => {
  const { t } = useTranslation();
  const { optionsDataTable } = useOptionsTables();
  const { handleNotification } = useMainContext();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const obtenerDatos = async () => {
    const response = await listarContratosPorEstadoService(
      idestado ?? 0,
      idmodulo ?? 0,
      handleNotification
    );
    if (response) {
      setData(response);
    }
    setLoading(false);
  }

  useEffect(() => {
    obtenerDatos();
  }, []);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Número de proceso'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Número de proceso')}</h5>
        ),
        customBodyRender: ([consecutivo, id]) => {
          return (
            <Button color="default" onClick={() => handleEdit(id, idmodulo)}>{consecutivo}</Button>
          )
        }
      }
    },
    {
      name: t('Fecha fin'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha fin')}</h5>
        ),
      }
    },
    {
      name: t('Módulo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Módulo')}</h5>
        ),
      }
    },
  ];
  return (
    <>
      {loading ? (
         <div className="d-flex flex-column justify-content-center text-center mt-4 mb-4">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando procesos...')}
          </span>
        </div>
      ) : (
        <MUIDataTable
          title=""
          data={data?.map(proceso => ([
            [proceso.consecutivo, proceso.id],
            formatStringtoDateString(proceso?.final),
            nombremodulo,
          ]))}
          columns={columns}
          options={optionsDataTable()}
        />
      )}
    </>
  )
}

interface IContratosPorEstado {
  handleEdit: (id: number, idmodulo: number) => void;
  idestado: number;
  idmodulo: number;
  nombremodulo: string;
}

export default ContratosPorEstado;
