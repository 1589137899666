import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ICargaMasiva, ICargaMasivaForm } from '../../models/documentos/cargaMasivaModel';

/**
 * Permite obtener los registros en la bd
 */
export const listarCargaMasiva = async (params?): Promise<ICargaMasiva[]> => {
  let data: ICargaMasiva[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CARGA_MASIVA, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarCargaMasiva = async (id: number): Promise<ICargaMasiva | null> => {
  let data: ICargaMasiva | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_CARGA_MASIVA}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarCargaMasiva = async (
  values: ICargaMasivaForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<ICargaMasiva> => {
  const url = (!values.id) ? urlApi.CREAR_CARGA_MASIVA : `${urlApi.EDIT_CARGA_MASIVA}${values.id}`
  const request = await updateItem(url, values, notification, false, true, true);
  return request?.data?.record;
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarCargaMasiva = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_CARGA_MASIVA}${id}`;
  return await deleteItem(url, notification);
};
