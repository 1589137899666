import React, { FC, useContext } from 'react';
import logo from '../../../assets/img/logos/logo.png';
import bg from '../../../assets/img/bg-auth.jpg';
// Reactstrap
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Button,
} from 'reactstrap';
//FORM
import { Formik, Form, Field } from 'formik';

import { FormSchema, initialState } from './index';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import { mainContext } from '../../../shared/contexts';
import { openModal } from '../../../shared/contexts/actions/mainActions';
import RecuperarContrasena from '../../../shared/components/RecuperarContrasena';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../../shared/contexts/mainContext';

const SignInComponent: FC<ISignInComponent> = ({ onSubmit, onRecovery }) => {
  const { t } = useTranslation();
  const { mainState, mainDispatch } = useMainContext();

  /**
   * Permite acceder a un registro
   * @param id 
   */
  const handleRecuperarContraseña = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Recuperación de contraseña'),
        body: () => (
          <>
            <RecuperarContrasena
              onSubmit={onRecovery}
            />
          </>
        )
      }
    }))
  };
  return (
    <div className="min-h-100">
      <div className="auth-fluid h-100" style={{ backgroundImage: `url(${bg})` }}>
        <div className="auth-fluid-left h-100">
          <CardBody className="body-sign-in">
            <div className="d-flex justify-content-center mb-4">
              <img src={logo} alt="Comforce" className="img-fluid" width="180" height="44" />
            </div>
            <div>
              <h4 className="auth-title">{t('Iniciar sesión')}</h4>
              <p className="auth-text">{t('Ingrese su usuario y contraseña para acceder a su cuenta.')}</p>
              <Formik
                initialValues={initialState}
                validationSchema={FormSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="auth-form">
                    <Row>
                      <Col sm={12}>
                        <FormGroup>
                          <Label>{t('Usuario')}</Label>
                          <Field
                            as={Input}
                            name="email"
                            placeholder={t('Ingrese su correo electrónico')}
                            bsSize="lg"
                            invalid={errors.email && touched.email}
                          />
                          <FormFeedback>{errors.email && t(errors.email)}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup>
                          <Label>Contraseña</Label>
                          <Button
                            className="text-muted float-right"
                            color="link"
                            onClick={handleRecuperarContraseña}
                          ><small>{t('¿Olvidó su contraseña?')}</small></Button>
                          <Field
                            as={Input}
                            name="password"
                            type="password"
                            placeholder={t('Ingrese su password')}
                            bsSize="lg"
                            invalid={errors.password && touched.password}
                          />
                          <FormFeedback>{errors.password && t(errors.password)}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-between mt-2">
                      <Col className="d-flex justify-content-start">
                        <ButtonCustom
                          className="d-flex justify-content-center align-items-center"
                          color="primary"
                          type="submit"
                          size="lg"
                          loading={mainState.loading}
                          disabled={mainState.loading}
                          block
                        >
                          <i className="uil-sign-out-alt" style={{ fontSize: '0.9rem' }}></i>&nbsp;{t('Ingresar')}
                      </ButtonCustom>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
            <div>
              <p className="mt-6 text-center auth-text">
                {t('Software de contratos')} <a href="https://comforce.co" target="_blank" className="text-muted">comforce.co</a>
              &copy; {new Date().getFullYear()} -{" "}
                <a href="https://comforce.co" target="_blank" className="text-muted">{t('Términos y condiciones')}</a>
              </p>
            </div>
          </CardBody>
        </div>
        <div className="auth-fluid-right h-100">
          <div className="auth-content-right">
          </div>
        </div>
      </div>
    </div>
  );
};
interface ISignInComponent {
  onSubmit: (values: any) => void;
  onRecovery: (values: any) => void;
}
export default SignInComponent;
