import React, { FC } from 'react';
import { IProveedores } from '../../../../../core/models/precontractual/proveedoresModel';
import Desplegable from '../../../../../shared/components/Desplegable';
import { useTranslation } from 'react-i18next';
import FormProveedor from '../../../../precontractual/components/Forms/FormProveedor';
import TablaDocsRenegociacion from '../../../../precontractual/components/Tables/TablaDocsRenegociacion';
import FormInfoBasicaPre from '../forms/FormInfoBasicaPre';
import TablaCalendarioRenegociacion from '../../../../precontractual/components/Tables/TablaCalendarioRenegociacion';
import { Alert } from 'reactstrap';

const ViewPreProveedores002: FC<IViewPreProveedores002> = ({ record }) => {
  const { t } = useTranslation();

  return (
    <>
      {record.negociar === 1 ? (
        <>
          <FormInfoBasicaPre proveedor={record} />
          <Desplegable
            title={t('Cronograma de Trabajo')}
            open
          >
            <TablaCalendarioRenegociacion record={record} />
          </Desplegable>
          <Desplegable
            title={t('Documentos de la Renegociación')}
            open
          >
            <TablaDocsRenegociacion record={record} />
          </Desplegable>
          <Desplegable
            title={t('Renegocicación')}
            open
          >
            <FormProveedor record={record} negociaciones />
          </Desplegable>
        </>
      ) : (
        <Alert color="warning mb-0">
          {t(`El proponente no cuenta con negociación.`)}
        </Alert>
      )}
    </>
  )
}

interface IViewPreProveedores002 {
  record: IProveedores;
}

export default ViewPreProveedores002;
