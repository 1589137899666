import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IOferta, IOfertaForm } from '../../models/precontractual/ofertaModel';
import { ICompletarCargaMasiva, IResponseCargaMasiva } from '../../models/documentos/cargaMasivaModel';

/**
 * Permite obtener los registros en la bd
 */
export const listarOferta = async (params?): Promise<IOferta[]> => {
  let data: IOferta[] = [];
  try {
    const resp = await getRequest(urlApi.PRE_OFERTA.LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarOferta = async (id: number): Promise<IOferta | null> => {
  let data: IOferta | null;
  try {
    const resp = await getRequest(`${urlApi.PRE_OFERTA.SHOW}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarOferta = async (
  values: IOfertaForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.PRE_OFERTA.CREATE : `${urlApi.PRE_OFERTA.EDIT}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarOferta = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.PRE_OFERTA.DELETE}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const cargaMasivaOferta = async (
  values: ICompletarCargaMasiva,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IResponseCargaMasiva> => {
  const url = urlApi.PRE_OFERTA.BULK;
  const resp = await updateItem(url, values, notification, false, true, true);
  return resp.data;
};
