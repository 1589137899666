import {EGroups, EGroupsNames, ETipoSolicitud} from "../../shared/utilities";

export const listGenero = [
  {
    id: 1,
    nombre: 'Femenino'
  },
  {
    id: 2,
    nombre: 'Masculino'
  }
];

export const listTipoTercero = [
  {
    id: 1,
    nombre: 'Contratante'
  },
  {
    id: 2,
    nombre: 'Contratista'
  }
];

export const contratista = "Comfacauca";

export const listRazonSocial = [
  {
    id: 1,
    nombre: 'Natural'
  },
  {
    id: 2,
    nombre: 'Jurídico'
  }
];

export const listTipoDocumento = [
  {
    id: 1,
    nombre: 'Cédula'
  },
  {
    id: 2,
    nombre: 'Cédula de extranjería'
  },
  {
    id: 3,
    nombre: 'Pasaporte'
  }
];

export const listCicloFacturacion = [
  {
    id: 1,
    nombre: 'Anual'
  },
  {
    id: 2,
    nombre: 'Bimestral'
  },
  {
    id: 3,
    nombre: 'Mensual'
  },
  {
    id: 4,
    nombre: 'Quincenal'
  },
  {
    id: 5,
    nombre: 'Semestral'
  },
  {
    id: 6,
    nombre: 'Trimestral'
  },
  {
    id: 7,
    nombre: 'Único'
  }
];

export const listTipoPago = [
  {
    id: 1,
    nombre: 'Anticipo'
  },
  {
    id: 2,
    nombre: 'Factura Avance'
  },
  {
    id: 3,
    nombre: 'Pago anticipado'
  },
  // {
  //   id: 3,
  //   nombre: 'Porcentaje según avance obra'
  // },
  // {
  //   id: 4,
  //   nombre: 'Fecha de vencimiento de factura'
  // },
];

export const listaRoles = [
  {
    "id": 1,
    "nombre": "Super Administrador",
    "codigo": "ROLE_SUPER_ADMINISTRADOR",
    "activo": 1
  },
  {
    "id": 2,
    "nombre": "Administrador",
    "codigo": "ROLE_ADMINISTRADOR",
    "activo": 1
  },
  {
    "id": 3,
    "nombre": "Solicitante",
    "codigo": "ROLE_SOLICITANTE",
    "activo": 1
  },
  {
    "id": 4,
    "nombre": "Coordinador",
    "codigo": "ROLE_COORDINADOR",
    "activo": 1
  },
  {
    "id": 5,
    "nombre": "Abogado",
    "codigo": "ROLE_ABOGADO",
    "activo": 1
  },
  {
    "id": 6,
    "nombre": "Firmante",
    "codigo": "ROLE_FIRMANTE",
    "activo": 1
  },
  {
    "id": 7,
    "nombre": "Aprobador",
    "codigo": "ROLE_APROBADOR",
    "activo": 1
  },
  {
    "id": 8,
    "nombre": "Terceros",
    "codigo": "ROLE_TERCERO",
    "activo": 1
  },
  {
    "id": 9,
    "nombre": "Auditor",
    "codigo": "ROLE_AUDITOR",
    "activo": 1
  },
  {
    "id": 10,
    "nombre": "Jefe de area",
    "codigo": "ROLE_JEFE_AREA",
    "activo": 1
  },
  {
    "id": 11,
    "nombre": "Asamblea",
    "codigo": "ROLE_ASAMBLEA",
    "activo": 1
  },
  {
    "id": 12,
    "nombre": "Concejo",
    "codigo": "ROLE_CONCEJO",
    "activo": 1
  },
  {
    "id": 13,
    "nombre": "Secretaría de Dpto Desarrollo Institucional",
    "codigo": "ROLE_SECRETARIA_DE_DPTO_DESARROLLO_INSTITUCIONAL",
    "activo": 1
  },
  {
    "id": 14,
    "nombre": "Aprobador Nivel 1 (Coordinador de área)",
    "codigo": "ROLE_APROBADOR_NIV_1",
    "activo": 1
  },
  {
    "id": 15,
    "nombre": "Aprobador Nivel 2 (Jefe de Departamento)",
    "codigo": "ROLE_APROBADOR_NIV_2",
    "activo": 1
  },
  {
    "id": 16,
    "nombre": "Aprobador Nivel 3 (Consejo Directivo)",
    "codigo": "ROLE_APROBADOR_NIV_3",
    "activo": 1
  },
  {
    "id": 17,
    "nombre": "Evaluador propuestas",
    "codigo": "ROLE_EVALUADOR",
    "activo": 1
  },
  {
    "id": 18,
    "nombre": "Validador listas restrictivas",
    "codigo": "ROLE_REGISTRO_TERCEROS",
    "activo": 1
  },
  {
    "id": 19,
    "nombre": "Aprobador Nivel 4 (Director)",
    "codigo": "ROLE_APROBADOR_NIV_4",
    "activo": 1
  },
  {
    "id": 20,
    "nombre": "Jefe de dpto jurídico",
    "codigo": "ROLE_JEFE_DE_DPTO_JURIDICO",
    "activo": 1
  },
  {
    "id": 21,
    "nombre": "Director",
    "codigo": "ROLE_DIRECTOR",
    "activo": 1
  },
  {
    "id": 22,
    "nombre": "Auxiliar Administrativo Dpto Jurídico",
    "codigo": "ROLE_AUXILIAR_ADMINISTRATIVO_DPTO_JURIDICO",
    "activo": 1
  },
  {
    "id": 23,
    "nombre": "Secretaria Jurídica",
    "codigo": "ROLE_SECRETARIA_JURIDICA",
    "activo": 1
  }
]

export const listRoles = [
  {
    value: EGroups.SUPERADMIN,
    label: 'Administrador Comforce'
  },
  {
    value: EGroups.ADMINISTRADOR,
    label: 'Administrador'
  },
  {
    value: EGroups.APROBADOR,
    label: 'Aprobador'
  },
  {
    value: EGroups.ABOGADO,
    label: 'Abogado'
  },
  {
    value: EGroups.FIRMANTE,
    label: 'Coordinador'
  },
  {
    value: EGroups.SOLICITANTE,
    label: 'Solicitante'
  },
  {
    value: EGroups.TERCERO,
    label: 'Tercero'
  },
  {
    value: EGroups.FIRMANTE,
    label: 'Firmante'
  },
  {
    value: EGroups.JEFEAREA,
    label: 'Jefe de area'
  },
  {
    value: EGroups.CONCEJO,
    label: 'Concejo'
  },
];


export const listaRolesAprobadores = [
  'ROLE_COORDINADOR',
  'ROLE_JEFE_AREA',
  'ROLE_ASAMBLEA',
  'ROLE_CONCEJO'
]

export const listPlazoEjecucion = [
  {
    id: '1',
    nombre: 'Días Hábiles (L - V 8:00 a.m. a 5:00 p.m., no incluye festivos)',
  },
  {
    id: '2',
    nombre: 'Días Corrientes',
  },
  {
    id: '3',
    nombre: 'Horas Hábiles (L - V 8:00 a.m. a 5:00 p.m., no incluye festivos)',
  },
  {
    id: '4',
    nombre: 'Semana(s)',
  },
  {
    id: '5',
    nombre: 'Mes(es)',
  },
  {
    id: '6',
    nombre: 'Año(s)',
  }
];

export const listObjetoAdenda = [
  {
    value: 1,
    label: 'Valor'
  },
  {
    value: 2,
    label: 'Vigencia'
  },
  {
    value: 3,
    label: 'Valor y Vigencia'
  },
  {
    value: 4,
    label: 'Alcance'
  },
];

export const listMoneda = [
  {
    id: 1,
    nombre: 'Peso Colombiano',
    siglas: 'COP',
  },
  {
    id: 2,
    nombre: 'Dólar Estadounidense',
    siglas: 'USD',
  },
  {
    id: 3,
    nombre: 'Euro',
    siglas: 'EUR',
  },
  {
    id: 4,
    nombre: 'Libras',
    siglas: 'LIB',
  }
];

export const listPlazos = [
  {
    id: 2,
    nombre: 'Día(s) Corriente(s)'
  },
  {
    id: 4,
    nombre: 'Mes(es)'
  },
  {
    id: 5,
    nombre: 'Año(s)'
  }
];

export const listDecision = [
  {
    id: 1,
    nombre: 'Si'
  },
  {
    id: 2,
    nombre: 'No'
  }
];

export const listCondicionPago = [
  {
    id: 1,
    nombre: 'Otra modalidad pago'
  },
  {
    id: 2,
    nombre: 'Por hitos de pago'
  },
  {
    id: 3,
    nombre: 'Según avance mensual'
  },
  {
    id: 4,
    nombre: 'Según avance por trabajos efectivamente desarrollados'
  },
  {
    id: 5,
    nombre: 'Valor fijo mensual'
  },
  {
    id: 6,
    nombre: 'Valor fijo mensual más valor variable'
  },
];

/*export enum ERolsProceso {
  SOLICITANTE = 1,
  COORDINADOR = 2,
  ABOGADO = 3,
  ESCALAMIENTO = 4,
  APROBADOR = 5,
  FIRMANTE = 6,
  JEFEAREA = 10,
  ASAMBLEA = 11,
  CONCEJO = 12,
  ABOGADO_EXTERNO = 13
}*/
export const listRolesContractuales = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
  {
    id: 4,
    nombre: 'Aprobador'
  },
  {
    id: 5,
    nombre: 'Aprobador'
  },
  {
    id: 6,
    nombre: 'Firmante'
  },
  {
    id: 10,
    nombre: 'Jefe de area'
  },
  {
    id: 11,
    nombre: 'Asamblea'
  },
  {
    id: 12,
    nombre: 'Concejo'
  },
  {
    id: 15,
    nombre: 'Jefe de Departamento'
  },
  {
    id: 21,
    nombre: "Director",
  },
  {
    id: 13,
    nombre: 'Secretaría de Dpto Desarrollo Institucional'
  },
  {
    id: 17,
    nombre: 'Evaluador propuestas'
  },
  {
    id: 22,
    nombre: 'Auxiliar Administrativo Dpto Jurídico'
  },
  {
    id: 23,
    nombre: 'Secretaria Jurídica'
  },
  {
    id: 103,
    nombre: 'Abogado Externo'
  }
];

export const listRolesContraContractual = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
  {
    id: 4,
    nombre: 'Aprobador'
  },
  {
    id: 5,
    nombre: 'Firmante'
  },
  {
    id: 10,
    nombre: 'Jefe de area'
  },
  {
    id: 11,
    nombre: 'Asamblea'
  },
  {
    id: 12,
    nombre: 'Concejo'
  },
  {
    id: 13,
    nombre: 'Secretaría de Dpto Desarrollo Institucional'
  },
  {
    id: 22,
    nombre: 'Auxiliar Administrativo Dpto Jurídico'
  },
  {
    id: 23,
    nombre: 'Secretaria Jurídica'
  },
  {
    id: 103,
    nombre: 'Abogado Externo'
  }
];

export const listRolesAsesorias = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
  {
    id: 14,
    nombre: 'Jefe de dpto jurídico'
  },
];

export const listValor = [
  {
    id: 1,
    nombre: '% + IVA'
  },
  {
    id: 2,
    nombre: '% sin IVA'
  },
  {
    id: 3,
    nombre: '% IVA incluido'
  },
  {
    id: 4,
    nombre: '$ * IVA'
  },
  {
    id: 5,
    nombre: '$ sin IVA'
  },
  {
    id: 6,
    nombre: '$ IVA incluido'
  }
]

export const listNuevo = [
  {
    id: 1,
    nombre: 'Referente'
  },
  {
    id: 2,
    nombre: 'Solicitud'
  }
]

export const listSolicitudOld = [
  {
    id: 1,
    nombre: 'Concepto',
    autogestion: false
  },
  {
    id: 2,
    nombre: 'Revisión de documentos de contenido legal',
    autogestion: false
  },
  {
    id: 3,
    nombre: 'Respuesta a derecho de petición',
    autogestion: false
  },
  {
    id: 4,
    nombre: 'Revisión escrituras públicas',
    autogestion: false
  },
  {
    id: 5,
    nombre: 'Elaboración de estudios de títulos',
    autogestion: false
  },
  {
    id: 6,
    nombre: 'Elaboración de poderes',
    autogestion: true
  },
  {
    id: 7,
    nombre: 'Elaboración de acuerdos de confidencialidad',
    autogestion: true
  },
  {
    id: 8,
    nombre: 'Atención de requerimiento de entidades estatales',
    autogestion: false
  },
  {
    id: 9,
    nombre: 'Elaboración de acuerdos de transmisión o de cesión de datos',
    autogestion: true
  },
  {
    id: 10,
    nombre: 'Elaboración de las certificaciones',
    autogestion: true
  },
]

export const listSolicitud = [
  {
    id: 1,
    nombre: "Concepto",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 2,
    nombre: "Copia de Documento",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 3,
    nombre: "Creación de Documento",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 4,
    nombre: "Información",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 5,
    nombre: "Otros",
    autogestion: false,
    complejidad: 1,
  }
];

export const listAreasAsesoria = [
  { id: 1,
    nombre: 'Legal'
  },
  {
    id: 2,
    nombre: 'Finanzas'
  },
  {
    id: 3,
    nombre: 'Comercial'
  }
];

export const listTemaConsulta = [
  {
    id: 1,
    nombre: 'ENTREGA DE HISTORIAS CLÍNICAS'
  },
  {
    id: 2,
    nombre: 'PROTECCIÓN AL CESANTE'
  },
  {
    id: 3,
    nombre: 'MANUAL DE CONTRATACIÓN'
  },
  {
    id: 4,
    nombre: 'GARANTÍA LEGAL'
  },
  {
    id: 5,
    nombre: 'PAGO DE SUBSIDIOS'
  },
  {
    id: 6,
    nombre: 'PROTECCIÓN DE DATOS PERSONALES'
  },
]

export const listEncuesta = [
  {
    id: 1,
    nombre: 'Atención',
  },
  {
    id: 2,
    nombre: 'Calidad de la respuesta',
  },
  {
    id: 3,
    nombre: 'Completitud de la respuesta',
  },
  {
    id: 4,
    nombre: 'Información suministrada',
  },
  {
    id: 5,
    nombre: 'Se dió respuesta a su consulta',
  },
]

export const listComplejidad = [
  {
    id: 1,
    nombre: 'Baja'
  },
  {
    id: 2,
    nombre: 'Media'
  },
  {
    id: 3,
    nombre: 'Alta'
  },
]

export const listTipoSolicitud = [
  {
    value: ETipoSolicitud.INVITACION,
    label: 'Invitación a cotizar abierta/cerrada',
  },
  {
    value: ETipoSolicitud.COTIZACION,
    label: 'Contratación Directa',
  },
]

export const listTipoLicitacion = [
  {
    value: 1,
    label: 'Abierta',
  },
  {
    value: 2,
    label: 'Cerrada',
  },
]

export const listTipoOferta = [
  {
    value: 1,
    label: 'Valor Total',
  },
  {
    value: 2,
    label: 'Precios Unitarios',
  },
]

export const listSituacion = [
  {
    value: 1,
    label: 'Material/equipo o Servicio con Distribuidor Fabricante, Proveedores Exclusivos y/o Únicos		',
  },
  {
    value: 2,
    label: 'Procesos no competitivos',
  },
  {
    value: 3,
    label: 'Compras Delegadas',
  },
  {
    value: 4,
    label: 'Contratación de los Servicios Técnicos Especializados que se mencionan a continuación',
  },
  {
    value: 5,
    label: 'Condiciones del mercado',
  },
]

export const listDocsRequeridos = [
  {
    value: 1,
    label: 'Términos de Referencia',
    codigo: 'terminos_referencia',
  },
  {
    value: 2,
    label: 'Especificaciones Técnicas',
    codigo: 'especificaciones_tecnicas',
  },
  {
    value: 3,
    label: 'Pliego de Condiciones',
    codigo: 'pliego_condiciones',
  },
  {
    value: 4,
    label: 'Cuadro de Items y Cantidades a Contratar',
    codigo: 'cuadro_items',
  },
  {
    value: 5,
    label: 'Plano de Diseños',
    codigo: 'plano_disenos',
  },
  {
    value: 6,
    label: 'Otros',
    codigo: 'otros',
  },
  {
    value: 7,
    label: 'Informes técnicos',
    codigo: 'informes_tecnicos',
  },
  {
    value: 8,
    label: 'Autorización',
    codigo: 'autorizacion',
  },
  {
    value: 9,
    label: 'Anexos',
    codigo: 'anexos',
  },
]


export const listDocsContraRequeridos = [
  {
    id: 35,
    label: 'Carta de presentación de la propuesta',
    requerido: 0,
    nombre_maquina: 'carta_propuesta'
  },
  {
    id: 36,
    label: 'Documentos de la propuesta',
    requerido: 0,
    nombre_maquina: 'documento_propuesta'
  },
  {
    id: 37,
    label: 'Certificado de existencia y representación legal personas juridicas y/o personas naturales con establecimiento de comercio',
    requerido: 0,
    nombre_maquina: 'certificado_existencia'
  },
  {
    id: 38,
    label: 'Antecedentes judiciales, contraloria, y procuraduria',
    requerido: 0,
    nombre_maquina: 'antecedentes'
  },
  {
    id: 39,
    label: 'Copia de cédula',
    requerido: 0,
    nombre_maquina: 'copia_cedula'
  },
  {
    id: 40,
    label: 'Copia de RUT con impresión inferior a 12 meses',
    requerido: 0,
    nombre_maquina: 'copia_rut'
  },
  {
    id: 41,
    label: 'Copia de planilla de seguridad social o certificación de pago de Seguridad Social debidamente firmada por el Contador Público y/o Revisor Fiscal anexando copia de la cédula de ciudadanía, tarjeta profesional de contador y junta central de contadores.',
    requerido: 1,
    nombre_maquina: 'planilla_seguridad_social'
  },
  {
    id: 42,
    label: 'Cuadro de ítems y cantidades diligenciado con los precios ofertados.',
    requerido: 0,
    nombre_maquina: 'cuadro_precios'
  },
  {
    id: 43,
    label: 'Manual para la gestión del sistema de gestión y seguridad en el trabajo. Opcional',
    requerido: 0,
    nombre_maquina: 'manual_sgsst'
  }
]

export const listTipoDocsRequeridos = [
  {
    value: 'pdf',
    label: 'PDF',
  },
  {
    value: 'xlsx',
    label: 'XLSX',
  },
]


export const ListTipoDocsSolicitud = [
  {
    value: 1,
    label: 'PDF',
  },
  {
    value: 2,
    label: 'XLSX',
  },
]

export const listSiNo = [
  {
    value: 1,
    label: 'Si'
  },
  {
    value: 2,
    label: 'No'
  }
];

export const listTipoContrato = [
  {
    value: 1,
    label: 'Contrato',
  },
  {
    value: 2,
    label: 'Orden',
  },
];

export const listAprobar = [
  {
    value: 1,
    label: 'Aprobado'
  },
  {
    value: 2,
    label: 'No aprobado'
  }
];

export const listDocsProveedores = [
  {
    value: 1,
    label: 'Anexos',
    default: true,
  },
]

export const listActividades = [
  {
    value: 1,
    label: 'Dar Intención de Participación',
    default: true,
  },
  {
    value: 2,
    label: 'Recepción de Propuestas',
    default: true,
  },
  {
    value: 3,
    label: 'Apertura de Propuestas',
    default: true,
  },
  {
    value: 4,
    label: 'Atención Consultas',
    default: false,
  },
  {
    value: 5,
    label: 'Envío Respuestas',
    default: false,
  },
  {
    value: 6,
    label: 'Adjudicación Proceso',
    default: false,
  },
]

export const listCriterios = [
  {
    value: 1,
    label: 'Comercial',
  },
  {
    value: 2,
    label: 'Económico',
  },
  {
    value: 3,
    label: 'Financiero',
  },
  {
    value: 4,
    label: 'Otro',
  },
  {
    value: 5,
    label: 'Técnico',
  },
];

export const listTipoFecha = [
  {
    value: 1,
    label: 'Solo fecha',
  },
  {
    value: 2,
    label: 'Fecha y hora',
  },
  {
    value: 3,
    label: 'Sin fecha ni hora',
  },
]

export const listRolesPrecontractual = (tipo?: ETipoSolicitud | null) => [
  {
    id: 1,
    nombre: "Solicitante",
    role: EGroupsNames.SOLICITANTE,
  },
  {
    id: 7,
    nombre: "Validador Listas Restrictivas",
    role: EGroupsNames.REGISTRO_TERCEROS,
  },
  {
    id: 8,
    nombre: "Evaluador",
    role: EGroupsNames.EVALUADOR,
  },
  {
    id: 101,
    nombre: "Aprobador Nivel 1 (Coordinador de área)",
    role: EGroupsNames.APROBADOR_NIV1,
  },
  {
    id: 102,
    nombre: tipo === ETipoSolicitud.INVITACION ? "Aprobador Nivel 2 (Jefe de Departamento)" : "Aprobador Nivel 2 (Jefe de Sección)",
    role: EGroupsNames.APROBADOR_NIV2,
  },
  {
    id: 103,
    nombre: tipo === ETipoSolicitud.INVITACION ? "Aprobador Nivel 3 (Consejo Directivo)" : "Aprobador Nivel 3 (Jefe de Departamento)",
    role: EGroupsNames.APROBADOR_NIV3,
  },
  {
    id: 104,
    nombre: "Aprobador Nivel 4 (Director)",
    role: EGroupsNames.APROBADOR_NIV4,
  },
  {
    id: 111,
    nombre: "Aprobador Nivel 1 - Etapa 2 (Coordinador de área)",
    role: EGroupsNames.APROBADOR_NIV1,
  },
  {
    id: 112,
    nombre: "Aprobador Nivel 2 - Etapa 2 (Jefe de Departamento)",
    role: EGroupsNames.APROBADOR_NIV2,
  },
  {
    id: 114,
    nombre: "Aprobador Nivel 3 - Etapa 2 (Director)",
    role: EGroupsNames.APROBADOR_NIV4,
  },
]


export  const listaTipoPolizaGarantia = [
  {
    value: 1,
    nombre: "Pólizas"
  },
  {
    value: 2,
    nombre: "Garantías"
  }
]

export const listTipoProceso = [
  {
    id: 1,
    nombre: "Tutelas",
  },
  {
    id: 2,
    nombre: "Requerimientos de Información",
  },
  {
    id: 3,
    nombre: "Derechos de petición",
  },
  {
    id: 4,
    nombre: "Procesos Administrativos",
  },
  {
    id: 5,
    nombre: "Conciliaciones",
  },
  {
    id: 6,
    nombre: "Demanda",
  },
  {
    id: 7,
    nombre: "Otro",
  },
];

export const listNivelProbabilidad = [
  {
    id: 1,
    nombre: "Remoto"
  },
  {
    id: 2,
    nombre: "Eventual"
  },
  {
    id: 3,
    nombre: "Probable"
  }
]


export const listEntidades = [
  {
    id: 1,
    nombre: "Centros de conciliación",
  },
  {
    id: 2,
    nombre: "Fiscalía",
  },
  {
    id: 3,
    nombre: "Juzgados",
  },
  {
    id: 4,
    nombre: "Otros",
  },
  {
    id: 5,
    nombre: "UGPP",
  },
  {
    id: 6,
    nombre: "Procuraduría",
  },
  {
    id: 7,
    nombre: "SIC",
  },
  {
    id: 8,
    nombre: "Contraloría",
  },
  {
    id: 9,
    nombre: "Superintendencia de Subsidio Familiar",
  },
  {
    id: 10,
    nombre: "Dagma",
  },
  {
    id: 11,
    nombre: "Superintendencia Nacional de Salud",
  },
  {
    id: 12,
    nombre: "Ministerio de Trabajo",
  },
];

export const listTipoTiempo = [
  {
    id: 1,
    nombre: "Días corrientes",
  },
  {
    id: 2,
    nombre: "Días hábiles",
  },
  {
    id: 3,
    nombre: "Horas",
  },
];

export const listMotivoJuridico = [
  {
    id: 1,
    nombre: "Atención en salud",
  },
  {
    id: 2,
    nombre: "Protección de datos personales / Habeas data",
  },
  {
    id: 3,
    nombre: "Contestación Derechos de Petición",
  },
  {
    id: 4,
    nombre: "Solicitud de Reconocimiento de Subsidio",
  },
  {
    id: 5,
    nombre: "Solicitud de Conciliación por Evento Médico",
  },
  {
    id: 6,
    nombre:
      "Solicitud de Conciliación por responsabilidad civil extracontractual",
  },
  {
    id: 7,
    nombre: "PQRs",
  },
  {
    id: 8,
    nombre: "Reclamación salarios y otros beneficios",
  },
  {
    id: 9,
    nombre: "Otro",
  },
];

export const listPartes = [
  {
    id: 1,
    nombre: 'Demandante'
  },
  {
    id: 2,
    nombre: 'Demandado'
  },
  {
    id: 3,
    nombre: 'N/A'
  }
];

export const listPretensiones = [
  {
    id: 1,
    nombre: "Económicas",
  },
  {
    id: 2,
    nombre: "Otras",
  },
  {
    id: 3,
    nombre: "Ambas",
  },
];

export const listRolesJuriJuridico = [
  {
    id: 3,
    nombre: "Abogado",
  },
];


export const listDependencias = [
  {
    "id": 1,
    "nombre": "ARCHIVO Y CORRESPONDENCIA"
  },
  {
    "id": 2,
    "nombre": "ASESORÍA JURÍDICA"
  },
  {
    "id": 3,
    "nombre": "AUDITORIA INTERNA"
  },
  {
    "id": 4,
    "nombre": "CAPACITACIÓN POPAYÁN"
  },
  {
    "id": 5,
    "nombre": "CAPACITACIÓN PUERTO TEJADA"
  },
  {
    "id": 6,
    "nombre": "CAPACITACIÓN SANTANDER DE QUILICHAO"
  },
  {
    "id": 7,
    "nombre": "CENTRO DE ATENCIÓN INTEGRAL A LA FAMILIA – CAIF"
  },
  {
    "id": 8,
    "nombre": "CENTRO EDUCATIVO EMPRESARIAL COMFACAUCA - CEEC"
  },
  {
    "id": 9,
    "nombre": "CENTRO RECREATIVO GUAYACANES"
  },
  {
    "id": 10,
    "nombre": "CENTRO RECREATIVO LAS CEIBAS"
  },
  {
    "id": 11,
    "nombre": "CENTRO RECREATIVO LOS ALMENDROS"
  },
  {
    "id": 12,
    "nombre": "CENTRO RECREATIVO PISOJE"
  },
  {
    "id": 13,
    "nombre": "COLEGIO PUERTO TEJADA"
  },
  {
    "id": 14,
    "nombre": "COLEGIO TECNICO POPAYÁN"
  },
  {
    "id": 15,
    "nombre": "COMUNICACIONES"
  },
  {
    "id": 16,
    "nombre": "CONTABILIDAD"
  },
  {
    "id": 17,
    "nombre": "CRÉDITO Y COBRANZAS"
  },
  {
    "id": 18,
    "nombre": "DEPARTAMENTO ADMINISTRATIVO Y FINANCIERO"
  },
  {
    "id": 19,
    "nombre": "DEPARTAMENTO DE PLANEACIÓN Y DESARROLLO"
  },
  {
    "id": 20,
    "nombre": "DEPARTAMENTO DE SALUD"
  },
  {
    "id": 21,
    "nombre": "DEPARTAMENTO DE SERVICIOS SOCIALES"
  },
  {
    "id": 22,
    "nombre": "DEPARTAMENTO DE VIVIENDA Y OBRAS"
  },
  {
    "id": 23,
    "nombre": "DEPARTAMENTO DESARROLLO INSTITUCIONAL"
  },
  {
    "id": 24,
    "nombre": "DEPARTAMENTO ZONA NORTE"
  },
  {
    "id": 25,
    "nombre": "DIRECCIÓN ADMINISTRATIVA"
  },
  {
    "id": 26,
    "nombre": "EDUCACIÓN, CAPACITACIÓN Y BIBLIOTECAS"
  },
  {
    "id": 27,
    "nombre": "GIMNASIO"
  },
  {
    "id": 28,
    "nombre": "IPS MIRANDA"
  },
  {
    "id": 29,
    "nombre": "IPS PUERTO TEJADA"
  },
  {
    "id": 30,
    "nombre": "IPS SANTANDER DE QUILICHAO"
  },
  {
    "id": 31,
    "nombre": "OFICINA GESTIÓN DE EMPLEO POPAYÁN"
  },
  {
    "id": 32,
    "nombre": "OFICINA GESTIÓN DE EMPLEO PUERTO TEJADA"
  },
  {
    "id": 33,
    "nombre": "OFICINA GESTIÓN DE EMPLEO SANTANDER DE QUILICHAO"
  },
  {
    "id": 34,
    "nombre": "OFICINA MECANISMO Y PROTECCION AL CESANTE – FOSFEC POPAYÁN"
  },
  {
    "id": 35,
    "nombre": "OFICINA MECANISMO Y PROTECCION AL CESANTE – FOSFEC PUERTO TEJADA"
  },
  {
    "id": 36,
    "nombre": "PROMOCIÓN Y CONTROL DE APORTES"
  },
  {
    "id": 37,
    "nombre": "RECREACIÓN, DEPORTE Y TURISMO POPAYÁN"
  },
  {
    "id": 38,
    "nombre": "RECREACIÓN, DEPORTE Y TURISMO PUERTO TEJADA"
  },
  {
    "id": 39,
    "nombre": "RECREACIÓN, DEPORTE Y TURISMO SANTANDER DE QUILICHAO"
  },
  {
    "id": 40,
    "nombre": "RELACIONES CORPORATIVAS Y GESTIÓN COMERCIAL"
  },
  {
    "id": 41,
    "nombre": "REVISORÍA FISCAL"
  },
  {
    "id": 42,
    "nombre": "SERVICIOS COMPLEMENTARIOS"
  },
  {
    "id": 43,
    "nombre": "SERVICIOS DE APOYO"
  },
  {
    "id": 44,
    "nombre": "SUBSIDIO FAMILIAR"
  },
  {
    "id": 45,
    "nombre": "TALENTO HUMANO"
  },
  {
    "id": 46,
    "nombre": "TECNOLOGÍA"
  },
  {
    "id": 47,
    "nombre": "TESORERÍA"
  },
  {
    "id": 48,
    "nombre": "UDS EL BORDO"
  },
  {
    "id": 49,
    "nombre": "UDS GUAPI"
  },
  {
    "id": 50,
    "nombre": "UDS MIRADA"
  },
  {
    "id": 51,
    "nombre": "UDS PUERTO TEJADA"
  },
  {
    "id": 52,
    "nombre": "UDS SANTANDER DE QUILICHAO"
  },
  {
    "id": 53,
    "nombre": "VILLA OLIMPICA"
  }
];

export const listDependenciasModificada = [...listDependencias, { id: 54, nombre: "IPS POPAYÁN" }].sort((a, b) => a.nombre.localeCompare(b.nombre));

