import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { ITareas } from "../../models/procesos/tareasModel";
import { getRequest, updateItem } from "../peticionesService";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPolizaGarantia = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_POLIZAGARANTIA;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearPolizaGarantia = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.EDIT_POLIZAGARANTIA}${values.id}`;
  return await updateItem(url, values, notification);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerRequerimientos = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_REQUERIMIENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerRequerimiento = async (id): Promise<any | null> => {
  let data: any = null;
  try {
    const url = `${urlApi.SHOW_REQUERIMIENTO}${id}`;
    const resp = await getRequest(url);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const saveRequerimiento = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREATE_REQUERIMIENTO : `${urlApi.EDIT_REQUERIMIENTOS}${values.id}`;
  return await updateItem(url, values, notification, true);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerAmparos = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.CONTRA_AMPARO_LIST;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const saveAmparos = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CONTRA_AMPARO_CREATE : `${urlApi.CONTRA_AMPARO_EDIT}${values.id}`;
  return await updateItem(url, values, notification);
};
