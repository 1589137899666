import React, { useState, FC, useRef } from 'react'
import { Button } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { downloadFile } from '../../helpers/generales';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import UbicacionesEditView from './UbicacionesEditView';
import { useVisorPDFContext } from '../../contexts/visorPDFContext';
import { useFirmaContext } from '../../contexts/firmaContext';
import UbicacionesImageView from './UbicacionesImageView';

const PDFObject: FC<IPDFObject> = ({
  path,
  nombre,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc =  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.js`;

  const { pageNumber, setPageNumber, verFirma } = useVisorPDFContext();
  const { firma, imagen } = useFirmaContext();

  const [numPages, setNumPages] = useState(0);
  const refDoc = useRef(null);
  const documentRef = useRef(null);

  const { t } = useTranslation();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber(pageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const handleDownload = () => {
    const nombrefile = `${nombre}.pdf`;
    downloadFile(path, nombrefile);
  }

  return (
    <>
      <Document
        file={path}
        onLoadSuccess={onDocumentLoadSuccess}
        className="pdf-viewer position-relative d-inline-block"
        ref={documentRef}
      >
        {!verFirma && (
          <UbicacionesEditView numPages={numPages} />
        )}
        {!!firma && !!imagen && (
          <UbicacionesImageView />
        )}
        <div>
          <Page
            pageNumber={pageNumber}
            ref={refDoc}
          />
          <Button
            onClick={handleDownload}
            color="link"
            className="btn"
            title={t('Descargar archivo')}
            style={{ position: 'absolute', right: 0, bottom: 0, opacity: '0.8', zIndex: 2 }}
          >
            {t('Descargar')} <i className="uil-download-alt"></i>
          </Button>
        </div>
      </Document>
      {!!pageNumber && !!numPages &&
        <Pagination
          pageNumber={pageNumber}
          previousPage={previousPage}
          nextPage={nextPage}
          numPages={numPages}
          setPageNumber={setPageNumber}
        />
      }
    </>
  )
}

interface IPDFObject {
  path: string;
  nombre: string;
};

export default PDFObject
