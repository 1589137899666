import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const Pagination: FC<any> = ({ pageNumber, previousPage, nextPage, numPages, setPageNumber }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-4" style={{textAlign: 'center'}}>
      <ButtonGroup aria-label="Páginas" className="d-inline-block" style={{maxWidth: 600}} size="sm">
        <Button color="primary" disabled={pageNumber <= 1} onClick={previousPage} outline>{t('Anterior')}</Button>
        {[...Array(numPages)].map((key, page) => (
            <Button key={uuidv4()} color="primary" onClick={() => setPageNumber(page + 1)} outline={pageNumber !== (page + 1)}>
              {page + 1}
            </Button>
        ))}
        <Button color="primary" disabled={pageNumber >= numPages} onClick={nextPage} outline>{t('Siguiente')}</Button>
      </ButtonGroup>
    </div>
  )
}

export default Pagination;
