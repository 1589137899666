import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Card, CardBody, Spinner } from 'reactstrap';
import { useMainContext } from '../../shared/contexts/mainContext';
import { changeHeader } from '../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../shared/contexts/interfaces/mainInterfaces';
import { useParams } from 'react-router-dom';
import { useFirmaContext } from '../../shared/contexts/firmaContext';
import AprobacionForm from './components/AprobacionForm';

const AprobacionDocumento: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { t, i18n: { language } } = useTranslation();
  const { mainDispatch } = useMainContext();
  const { obtenerFirma, loading, firma } = useFirmaContext();

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Aprobación documento'),
        type: mainTypeHeader.SUBTITLE
      }
    }));
  }, [language]);

  useEffect(() => {
    obtenerFirma(token);
  }, [token]);

  return (
    <Card>
      <CardBody>
        {loading ? (
           <div className="d-flex flex-column justify-content-center text-center">
            <Spinner color="primary" className="m-auto" />
            <span className="mt-3">
              {t('Cargando documento...')}
            </span>
          </div>
        ) : null}
        {(firma ? (
          <>
            {firma.aprueba === 1 && (
              <Alert color="success" className="mt-4">
                {t('El documento fue aprobado correctamente. Puede cerrar esta ventana.')}
              </Alert>
            )}
            {firma.aprueba === 2 && (
              <Alert color="success" className="mt-4">
                {t('El documento fue desaprobado correctamente. Puede cerrar esta ventana.')}
              </Alert>
            )}
            {firma.aprueba === null && (
              <>
                {firma.noAprobaciones?.length ? (
                  <Alert color="info" className="mt-4">
                    {t('Este documento ha sido desaprobado por uno de los aprobadores.')}
                  </Alert>
                ) : (
                  <AprobacionForm />
                )}
              </>
            )}
          </>
        ) : (!loading && (
          <Alert color="info" className="mt-4">
            {t('Este enlace ya no se encuentra disponible.')}
          </Alert>
        )))}
      </CardBody>
    </Card>
  )
}

export default AprobacionDocumento;
