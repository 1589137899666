import React, { FC, useEffect } from 'react';
import { IPrecontractual } from '../../../core/models/precontractual/precontractualModel';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { changeHeader } from '../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';
import { useTranslation } from 'react-i18next';
import { usePrecontractualContext } from '../../../shared/contexts/precontractualContext';
import { EEstadosPre } from '../../../shared/utilities';
import { Alert, Spinner } from 'reactstrap';
import FormRegistroTerceros from './components/forms/FormRegistroTerceros';

const TokenPrePrecontractual: FC<ITokenPrePrecontractual> = ({ record }) => {
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { estado, obtenerProceso, proceso } = usePrecontractualContext();

  const habilitar = estado === EEstadosPre.SELECCION_PROPONENTES;

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: proceso ? `${t('Proceso')} ${proceso?.consecutivo}: ${proceso?.idestados.nombre}` : '...',
        subtitle: [],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, [proceso]);

  useEffect(() => {
    if (record.id) {
      obtenerProceso(record.id); 
    }
  }, [record]);

  return (
    <>
      {proceso ? (
        <>
          {habilitar ? (
            <FormRegistroTerceros />
          ) : (
            <Alert color="warning" className="mb-0">
              {t('El registro de proveedores ya no se encuentra disponible.')}
            </Alert>
          )}
        </> 
      ) : (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando proceso...')}
          </span>
        </div>
      )}
    </>
  )
}

interface ITokenPrePrecontractual {
  record: IPrecontractual;
}

export default TokenPrePrecontractual;
