import { postRequest } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite descargar el reporte Terceros
 */
export const descargarReporteTerceros = async (
  values: any
): Promise<boolean> => {

  const url = urlApi.REPORTE_TERCEROS;
  
  return await postRequest(url, values, true);
};