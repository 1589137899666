import React, { FC, createContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IVersiones } from '../../core/models/documentos/versionesModel';
import { obtenerVersiones } from '../../core/services/documentos/versionesService';
import { IFirmas, IFormFirmas } from '../../core/models/documentos/firmasModel';
import { guardarFirmas, obtenerFirmas } from '../../core/services/documentos/firmasService';
import { useMainContext } from './mainContext';
import { closeModal, falseLoading, trueLoading } from './actions/mainActions';
import { IFormUbicacionesFirma, IUbicacionesFirma } from '../../core/models/documentos/ubicacionesFirmaModel';
import { guardarUbicacionesFirma, obtenerUbicacionesFirma } from '../../core/services/documentos/ubicacionesFirmaService';

const visorPDFContext = createContext<IVisorPDFContext | undefined>(
  undefined
);

export const VisorPDFProvider: FC<IVisorPDFProvider> = ({
  entidad,
  idproceso,
  editar,
  comentar,
  firmable = true,
  children,
}) => {
  const { mainDispatch, handleNotification } = useMainContext();

  const [versiones, setVersiones] = useState<IVersiones[]>([]);
  const [version, setVersion] = useState<IVersiones|null>(null);
  const [loadingVersiones, setLoadingVersiones] = useState(true);
  const [firmas, setFirmas] = useState<IFirmas[]>([]);
  const [loadingFirmas, setLoadingFirmas] = useState<boolean>(true);
  const [ubicaciones, setUbicaciones] = useState<IUbicacionesFirma[]>([]);
  const [loadingUbicaciones, setLoadingUbicaciones] = useState<boolean>(true);
  const [showMargin, setShowMargin] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [verFirma, setVerFirma] = useState<IFirmas|null>(null);

  const listarVersiones = async () => {
    setLoadingVersiones(true);
    const resp = await obtenerVersiones({
      filter: 'entidad,idproceso',
      valuefilter: `${entidad},${idproceso}`,
    });
    setVersiones(resp);
    if (resp.length) {
      setVersion(resp[0]);
    }
    setLoadingVersiones(false);
  }

  const listarFirmas = async () => {
    mainDispatch(trueLoading());
    setVerFirma(null);
    const resp = await obtenerFirmas({
      filter: 'iddocumento',
      valuefilter: version?.id,
    });
    setFirmas(resp);
    setLoadingFirmas(false);
    mainDispatch(falseLoading());
  }

  const listarUbicaciones = async () => {
    setLoadingUbicaciones(true);
    const resp = await obtenerUbicacionesFirma({
      filter: 'iddocumento',
      valuefilter: version?.id,
    });
    setUbicaciones(resp);
    setLoadingUbicaciones(false);
  }

  const onSubmitFirmas = async (values: IFormFirmas) => {
    mainDispatch(trueLoading());
    if (await guardarFirmas(values, handleNotification)) {
      await listarFirmas();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  };

  const onSubmitUbicacion = async (values: IFormUbicacionesFirma) => {
    mainDispatch(trueLoading());
    const resp = await guardarUbicacionesFirma(values, handleNotification);
    if (resp) {
      await listarUbicaciones();
    }
    mainDispatch(falseLoading());
  }

  useEffect(() => {
    if (entidad && idproceso) {
      listarVersiones();
    }
  }, [entidad, idproceso]);

  return (
    <visorPDFContext.Provider
      value={{
        entidad,
        idproceso,
        editar,
        comentar,
        firmable,
        versiones,
        version,
        loadingVersiones,
        listarVersiones,
        setVersion,
        firmas,
        loadingFirmas,
        listarFirmas,
        onSubmitFirmas,
        ubicaciones,
        listarUbicaciones,
        loadingUbicaciones,
        pageNumber,
        setPageNumber,
        onSubmitUbicacion,
        showMargin,
        setShowMargin,
        verFirma,
        setVerFirma,
      }}
    >
      {children}
    </visorPDFContext.Provider>
  );
};

interface IVisorPDFProvider {
  entidad: string;
  idproceso: number;
  editar: boolean;
  comentar: boolean;
  firmable?: boolean;
}

interface IVisorPDFContext extends IVisorPDFProvider {
  versiones: IVersiones[];
  version: IVersiones | null;
  loadingVersiones: boolean;
  listarVersiones: () => Promise<void>;
  setVersion: (version: IVersiones | null) => void;
  firmas: IFirmas[];
  loadingFirmas: boolean;
  listarFirmas: () => Promise<void>;
  onSubmitFirmas: (values: IFormFirmas) => Promise<void>;
  ubicaciones: IUbicacionesFirma[],
  listarUbicaciones: () => Promise<void>;
  loadingUbicaciones: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  onSubmitUbicacion: (values: IFormUbicacionesFirma) => Promise<void>,
  showMargin: boolean;
  setShowMargin: (showMargin: boolean) => void;
  verFirma: IFirmas | null,
  setVerFirma: (verFirma: IFirmas | null) => void;
}

export const useVisorPDFContext = () => {
  const context = React.useContext(visorPDFContext);
  if (context === undefined) {
    throw new Error(
      "useVisorPDFContext must be used within a VisorPDFProvider"
    );
  }
  return context;
};
