import React, {createContext, useReducer, useEffect, useContext, useState, useMemo} from 'react';
import {useGetGeneric} from '../hooks/useGet';
import {
  updateAprobaciones,
  updateDocumentos,
  updateContraUsuario,
  updateProceso,
  updateRequerimientos,
  updateRoles,
  updateState,
  updateTareas,
  updateUsuarios
} from './actions/contractualActions';
import moment from 'moment';
import {mainContext} from './mainContext';
import {mainTypeMsj} from './interfaces/mainInterfaces';
import {userContext} from './userContext';
import urlApi from '../../core/api';
import {obtenerPolizaGarantia, obtenerRequerimientos} from '../../core/services/procesos/polizaGarantiasService';
import {cargarDocumento as loadDocumento, obtenerDocumentos} from '../../core/services/procesos/documentoService';
import {EGroups, EGroupsNames, EModulos, ERolsProceso} from '../utilities';
import {obtenerTareas} from '../../core/services/procesos/tareasService';
import contractualReducer, {contractualInitialState} from './reducers/contractualReducer';
import {IContractualContext} from './interfaces/contractualInterfaces';
import {obtenerContractualUsuarios} from '../../core/services/contractual/contraUsuariosService';
import {
  aprobarProceso,
  guardarContractual,
  obtenerAprobaciones,
  obtenerListaUsuariosAprob
} from '../../core/services/contractual/contractualService';
import {falseLoading, trueLoading} from './actions/mainActions';

export const contractualContext = createContext<IContractualContext | undefined>(undefined);

export const ContractualProvider = ({children}) => {
  // Contexts
  const {handleNotification, mainDispatch} = useContext(mainContext);
  const {userState} = useContext(userContext);
  // States
  const [state, dispatch] = useReducer(contractualReducer, contractualInitialState);
  const [polizaGarantia, setPolizaGarantia] = useState<any | null>(null);
  const [aprobaciones, setAprobaciones] = useState<any[]>([]);
  const [devoluciones, setDevoluciones] = useState<any[]>([]);
  const [comentarioCancelacion, setComentarioCancelacion] = useState<any>(null);
  const [requestProceso, errProceso, obtenerProceso] = useGetGeneric({
    url: (state?.id) ? `${urlApi.SHOW_CONTRACTUAL}${state?.id}` : null
  });

  const obtenerDataProceso = () => {
    obtenerUsuarioContractual();
    listarPolizaGarantia();
    listarUsuarios();
    listarAprobaciones();
    listarAprobacionesPorUsuario();
    listarDocumentos();
    listarTareas();
  };

  const roles = useMemo(() => {
    return {
      solicitante: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.SOLICITANTE && usuario?.activo === 1),
      coordinador: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.COORDINADOR && usuario?.activo === 1),
      abogado: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.ABOGADO && usuario?.activo === 1),
      aprobador: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.APROBADOR && usuario?.activo === 1),
      firmante: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.FIRMANTE && usuario?.activo === 1),
      jefearea: state?.contraUsuario?.find(usuario => usuario?.idusuarios?.id === userState?.data?.sub && usuario?.rol === ERolsProceso.JEFEAREA && usuario?.activo === 1),
      isAdmin: userState?.data?.roles?.find(rol => rol === EGroupsNames.ADMINISTRADOR || rol === EGroupsNames.SUPERADMIN),
    }
  }, [userState, state])

  const listarAprobaciones = async () => {
    const params = {
      filter: 'idcontractual',
      valuefilter: state.id
    };
    const resp = await obtenerAprobaciones(params);
    setDevoluciones(resp.filter(aprobacion => aprobacion?.aprueba === 2 && aprobacion?.comentario) || []);
    setAprobaciones(resp || []);
  };

  const handleLimpiarProceso = () => {
    setPolizaGarantia(null);
    setAprobaciones([]);
    setDevoluciones([]);
  };

  const listarAprobacionesPorUsuario = async () => {
    const usuarios = await obtenerListaUsuariosAprob((state.id as string));
    dispatch(updateRoles(usuarios));
    dispatch(updateAprobaciones(usuarios.reduce((prev: any[], current) => {
      let exist: any = null;

      const aprobaciones = current?.aprobaciones?.filter(aprobacion => aprobacion?.idestados?.codigo !== '10');
      setComentarioCancelacion(current?.aprobaciones?.find(aprobacion => aprobacion?.idestados?.codigo === '10'));

      if (prev?.length > 0) {
        exist = prev?.find(usuario => usuario?.id === current?.usuario?.idusuarios?.id && usuario?.tipo === 1);
        if (!exist) {
          exist = prev?.find(usuario => usuario?.id === current?.proveedor?.id && usuario?.tipo === 2);
        }
      }

      if (exist) {
        return prev.map(usuario => {
          if (usuario?.id === current?.usuario?.idusuarios?.id) {
            return {
              ...usuario,
              roles: (usuario?.roles?.find(rol => rol === current?.usuario?.rol)) ? exist?.roles : [
                ...usuario.roles,
                {
                  id: current?.usuario?.id,
                  rol: current?.usuario?.rol,
                  activo: current?.usuario?.activo
                }
              ],
            };
          } else {
            return usuario;
          }
        })
      } else {
        return [
          ...prev,
          (current?.usuario) ? {
            id: current?.usuario?.idusuarios?.id,
            tipo: 1,
            roles: [{
              id: current?.usuario?.id,
              rol: current?.usuario?.rol,
              activo: current?.usuario?.activo
            }],
            nombre: `${current?.usuario?.idusuarios?.nombres} ${current?.usuario?.idusuarios?.apellidos}`,
            creado: current?.usuario?.creado,
            aprobaciones
          } : {
            id: current?.proveedor?.id,
            tipo: 2,
            roles: [{
              id: current?.proveedor?.id,
              rol: 'Tercero',
              activo: current?.proveedor?.activo
            }],
            nombre: `${current?.proveedor?.razonsocial}`,
            creado: current?.proveedor?.creado,
            aprobaciones
          }
        ];
      }
    }, [])));
  };

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarDocumentos = async () => {
    const params = {
      filter: 'idcontractual',
      valuefilter: state.id
    };
    dispatch(updateDocumentos(await obtenerDocumentos(EModulos.CONTRACTUAL.nombre, params)));
  };

  /**Carga un documento */
  const cargarDocumento = async (e: any) => {
    mainDispatch(trueLoading());
    const exist = state?.documentos?.find(file => file.categoria === e.target.name);
    const json = {
      id: exist ? exist?.id : undefined,
      idcontractual: state?.proceso?.id,
      idcargadopor: userState.data?.sub,
      categoria: e.target.name,
    };
    const dataSend = {
      archivo: e.target.files[0],
      json
    };
    if (await loadDocumento(dataSend, handleNotification)) {
      listarDocumentos();
    }
    mainDispatch(falseLoading());
  };

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarTareas = async () => {
    const params = {
      filter: 'idcontractual',
      valuefilter: state.id
    };
    dispatch(updateTareas(await obtenerTareas(params)));
  };

  /**
   * Obtiene los datos del usuario logueado (roles)
   */
  const obtenerUsuarioContractual = async () => {
    dispatch(updateContraUsuario(await obtenerContractualUsuarios('idcontractual,idusuarios', `${state?.id},${userState.data?.sub}`)));
  };

  /**
   * Obtiene la poliza garantía del proceso
   */
  const listarPolizaGarantia = async () => {
    const params = {
      filter: 'idcontractual',
      valuefilter: state?.id
    };

    const resp = await obtenerPolizaGarantia(params);
    if (resp?.length > 0) {
      setPolizaGarantia(resp[0]);
      listarRequerimientos(resp[0]?.id);
    } else {
      setPolizaGarantia(null);
      dispatch(updateRequerimientos([]));
    }
  };

  /**
   * Obtiene los requerimientos guardados del proceso guardadas en la bd
   */
  const listarRequerimientos = async (id: any) => {
    const params = {
      filter: 'idpolizagarantia',
      valuefilter: id
    };
    const requerimientos = await obtenerRequerimientos(params);
    dispatch(updateRequerimientos(requerimientos));
  };

  /**
   * Obtiene los usuarios contractuales registrados en la bd
   */
  const listarUsuarios = async () => {
    dispatch(updateUsuarios(await obtenerContractualUsuarios('idcontractual', (state?.id as string))));
  };

  const handleAprobacion = async (values: any, changeState = true) => {
    mainDispatch(trueLoading());
    const dataSend = {
      ...values,
      idcontractual: state?.proceso?.id,
      activo: 1,
      id: null
    }
    if (await aprobarProceso(dataSend, handleNotification) && changeState) {
      if (await handleChangeState(false)) {
        obtenerProceso();
      }
    }
    mainDispatch(falseLoading());
  };

  const guardarProcesoContractual = async (values: any, file = false, changeState = true, history = undefined) => {
    mainDispatch(trueLoading());
    const dataSend = {
      ...values,
      guardar: 1,
    };
    await handleGuardarProceso(dataSend, file, changeState, history);
    mainDispatch(falseLoading());
  };

  const handleGuardarProceso = async (data: any, file: boolean, changeState = false, history = undefined) => {
    if (await guardarContractual(data, handleNotification, file, !changeState)) {
      if (changeState) {
        await handleChangeState(false, history);
      }
      obtenerProceso();
    }
  };

  const handleChangeState = async (only = false, history?: any) => {
    if (only) {
      mainDispatch(trueLoading());
    }
    const resp = await guardarContractual({
      guardar: 2,
      id: state?.id
    }, handleNotification, false, true, true);

    if (resp?.data?.postcontractualid) {
      mainDispatch(falseLoading());
      setTimeout(() => history.push(`/postcontractual/edit/${resp?.data?.postcontractualid}`), 1000);
      return resp;
    }
    ;

    if (only) {
      obtenerProceso();
      mainDispatch(falseLoading());
    }
    return true;
  };

  const handleChangeBackState = async () => {
    mainDispatch(trueLoading());
    const resp = await guardarContractual({
      guardar: 3,
      id: state?.id
    }, handleNotification, false, true, true);

    obtenerProceso();
    mainDispatch(falseLoading());

    return true;
  };

  useEffect(() => {
    if (errProceso) {
      handleNotification(errProceso?.data?.message, mainTypeMsj.ERROR);
    } else if (requestProceso) {
      dispatch(updateProceso({
        ...requestProceso?.data?.record,
        polizas: requestProceso?.data?.polizas || [],
      }));
      obtenerDataProceso();
    }
  }, [errProceso, requestProceso]);

  useEffect(() => {
    if (!userState?.isAutenticated) {
      dispatch(updateState(undefined));
    }
  }, [userState]);

  return (
    <contractualContext.Provider
      value={{
        polizaGarantia,
        devoluciones,
        aprobaciones,
        comentarioCancelacion,
        contractualState: state,
        contractualDispatch: dispatch,
        handleLimpiarProceso,
        obtenerProceso,
        listarDocumentos,
        listarTareas,
        listarUsuarios,
        listarAprobaciones,
        listarAprobacionesPorUsuario,
        listarRequerimientos,
        obtenerUsuarioContractual,
        cargarDocumento,
        listarPolizaGarantia,
        handleAprobacion,
        guardarProcesoContractual,
        handleGuardarProceso,
        handleChangeState,
        handleChangeBackState,
        roles
      }}
    >
      {children}
    </contractualContext.Provider>
  );
};

export const useContractualContext = () => {
  const context = React.useContext(contractualContext)
  if (context === undefined) {
    throw new Error('useContractualState must be used within a ContractualProvider');
  }
  return context
};
