import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IUnidadMedida, IUnidadMedidaForm } from '../../../../core/models/administracion/unidadMedidaModel';
import ModalsFooter from '../../../../shared/components/ModalsFooter';

const FormUnidadMedida: FC<IFormUnidadMedida> = ({ onSubmit, record }) => {
  const { t } = useTranslation();

  const habilitar = true;

  const initialValues: IUnidadMedidaForm = {
    id: null,
    unidad: '',
    alias: '',
    activo: 1,
    ...record,
  } as IUnidadMedidaForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    unidad: Yup.string().required(FormValidation.required),
    alias: Yup.string().required(FormValidation.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormUnidadMedida" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Unidad')}</Label>
                <Field
                  as={Input}
                  name="unidad"
                  invalid={errors.unidad && touched.unidad}
                  disabled={!habilitar}
                  placeholder="Kilómetro"
                />
                <FormFeedback>{errors.unidad && t(errors.unidad)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Alias')}</Label>
                <Field
                  as={Input}
                  name="alias"
                  invalid={errors.alias && touched.alias}
                  disabled={!habilitar}
                  placeholder="KM"
                />
                <FormFeedback>{errors.alias && t(errors.alias)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormUnidadMedida"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormUnidadMedida {
  record: IUnidadMedida | null;
  onSubmit: (values: IUnidadMedidaForm) => Promise<void>;
}

export default FormUnidadMedida;
