import React, { FC, useEffect } from 'react'
import { IFormReportesTerceros } from '../../../core/models/reportes/reporteTercerosModel';
import dev from '../../../core/enviroments';
// contextos
import { changeHeader, changeLoading } from '../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';

import { descargarReporteTerceros } from '../../../core/services/reportes/reporteTercerosService';
import FormReporteTerceros from './components/FormReporteTerceros';
import { downloadFile } from '../../../shared/helpers/generales';
import { newDate } from '../../../shared/helpers/Fecha';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { useTranslation } from 'react-i18next';


const ReporteTerceros: FC = () => {

  // contextos
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();

  /**
 * Descargar Reporte //{dev.URL_SERVER}/reports/{id}/Terceros.xlsx
 * @param values 
 */
  const onSubmit = async (values: IFormReportesTerceros) => {

    mainDispatch(changeLoading());
    let result = await descargarReporteTerceros(values);

    if (result) {
      downloadFile(`${dev.URL_SERVER}/uploads/reports/${values.id}/Terceros.xlsx`, `${t('Reporte terceros')} - ${newDate()}`);
      mainDispatch(changeLoading());
    } else {
      mainDispatch(changeLoading());
    }
  };

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Reportes'),
        subtitle: [
          { name: t('Inicio'), route: '/home' },
          { name: t('Reportes') },
          { name: t('Reporte Terceros') }
        ],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, []);


  return (
    <FormReporteTerceros
      onSubmit={onSubmit}
    />
  )
}

export default ReporteTerceros
