import React, { FC, ReactNode, useState } from 'react';
import { Button, Collapse } from 'reactstrap';

const Desplegable: FC<IDesplegable> = ({ title, children, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="collapse-custom">
      <div color="primary" className="collapse-button" onClick={toggle}>
        {title}
        {isOpen ? (
          <i className="uil-ml uil-angle-up"></i>
        ) : (
          <i className="uil-ml uil-angle-down"></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="collapse-body">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

interface IDesplegable {
  title: string;
  children: ReactNode;
  open?: boolean;
}

export default Desplegable;
