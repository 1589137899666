import React, {createContext, useMemo, useState} from "react";
import {IFirmas, IFormAprobacion, IFormPrevisualizar} from '../../core/models/documentos/firmasModel';
import {guardarFirmas, mostrarFirma} from '../../core/services/documentos/firmasService';
import {useMainContext} from './mainContext';
import {changeLoading, falseLoading, trueLoading} from './actions/mainActions';
import {IFormCodigos} from '../../core/models/documentos/codigosModel';
import {guardarCodigos} from '../../core/services/documentos/codigosService';
import {
  listarFirmasService,
  obtenerFirmaInternaService,
  obtenerFirmaTerceroService
} from "../../core/services/firmas/firmaService";

export interface IFirmaContext {
  activeStep: number;
  setActiveStep: (step: number) => void;
  values: any;
  codigo: string;
  loading: boolean;
  firma: IFirmas | null;

  firmas: any;
  setFirma: any;
  getFirmaTercero: (token: string) => void;
  getFirmaInterna: (id: number) => void;
  resetFirma: () => void;
  setValues: (values: any) => void;
  setCodigo: (codigo: any) => void;
  setLoading: (loading: boolean) => void;

  obtenerFirma: (token: string) => void;
  handleAprobacion: (values: IFormAprobacion, token: string) => Promise<void>;
  handleFirmar: (values: IFormPrevisualizar, token: string) => Promise<void>;
  handleCrearCodigo: (values: IFormCodigos) => Promise<boolean>;
  imagen: string;
  setImagen: (imagen: string) => void;
  listarFirmas: (filtername: string, filtervalue: string | number) => void;
  obtenerFirmas: (filtername: string, filtervalue: string | number) => any;
  aprobacionFirma: boolean;
  setAprobacionFirma: (aprobacionFirma: boolean) => void;
};

export const firmaContext = createContext<IFirmaContext | undefined>(undefined);

export const FirmaProvider = ({children}) => {
  const {mainDispatch, handleNotification} = useMainContext();

  const [loadedFirma, setLoadedFirma] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [ values, setValues ] = useState<any>(undefined);
  const [firma, setFirma] = useState<IFirmas | null>(null);
  const [firmas, setFirmas] = useState<IFirmas | any[]>([]);
  const [codigo, setCodigo] = useState<string>('');
  const [imagen, setImagen] = useState<string>('');
  const [aprobacionFirma, setAprobacionFirma] = useState<boolean>(false);


  const obtenerFirma = async (token: string) => {
    setLoading(true);
    setFirma(await mostrarFirma(token));
    setLoading(false);
  }

  const handleAprobacion = async (values: IFormAprobacion, token: string) => {
    mainDispatch(changeLoading());
    if (await guardarFirmas(values, handleNotification)) {
      await obtenerFirma(token);
    }
    mainDispatch(changeLoading());
  }

  const handleFirmar = async (values: IFormPrevisualizar, token: string) => {
    mainDispatch(changeLoading());
    if (await guardarFirmas(values, handleNotification)) {
      await obtenerFirma(token);
    }
    mainDispatch(changeLoading());
  }

  const handleCrearCodigo = async (values: IFormCodigos): Promise<boolean> => {
    mainDispatch(trueLoading());
    const response = await guardarCodigos(values, handleNotification)
    if (response) {
      await obtenerFirma(values.token);
    }
    mainDispatch(falseLoading());
    return response;
  }

  const getFirmaTercero = async (token: string) => {

    // Firma
    const firma = await obtenerFirmaTerceroService(token);
    setFirma(firma || null);

    setLoadedFirma(true);
  }

  const getFirmaInterna = async (id: number) => {

    // Firma
    const firma = await obtenerFirmaInternaService(id);
    setFirma(firma || null);

    setLoadedFirma(true);
  }

  const listarFirmas = async (filtername: string, filtervalue: string | number) => {

    // Firma
    const firmas = await listarFirmasService(filtername, filtervalue);
    setFirmas(firmas || []);

  }

  const obtenerFirmas = async (filtername: string, filtervalue: string | number) => {

    const firmas = await listarFirmasService(filtername, filtervalue);
    return firmas || [];

  }

  const resetFirma = () => {
    setFirma(null);
    setLoadedFirma(true);
  }

  const contextValue = {
    activeStep,
    setActiveStep,
    values,
    codigo,
    setCodigo,
    loading,
    firma,
    firmas,
    obtenerFirma,
    handleAprobacion,
    handleFirmar,
    handleCrearCodigo,
    imagen,
    setImagen,
    getFirmaTercero,
    getFirmaInterna,
    listarFirmas,
    obtenerFirmas,
    aprobacionFirma,
    setAprobacionFirma,
    setFirma,
    resetFirma,
    setValues,
    setLoading
  };

  return (
    <firmaContext.Provider
      value={contextValue}
    >
      {children}
    </firmaContext.Provider>
  );
}

export const useFirmaContext = () => {
  const context = React.useContext(firmaContext)
  if (context === undefined) {
    throw new Error('useFirmaContext must be used within a FirmaProvider')
  }
  return context
};
