import moment from 'moment';

const Fecha = (timestamp, format = 'YYYY-MM-DD') => {
  return moment(moment.unix(timestamp).format('MM/DD/YYYY')).format(format).toString();
}
export default Fecha;

/**
 * Permite crear una fecha actual con moment
 * @param format 
 * @returns moment
 */
export const newDate = (format = 'YYYY-MM-DD') => moment().format(format).toString();

/**
 * Permite crear una fecha actual
 * @param format 
 * @returns DateTime
 */
export const newDateJs = () => new Date();

/**
 * Permite dar un formato de fecha valido para un timestamp
 * @param timestamp 
 * @param format default is 'YYYY-MM-DD'
 * @returns DateTime
 */
export const formatTimestampDate = (timestamp, format = 'YYYY-MM-DD') => moment(moment.unix(timestamp).format('MM/DD/YYYY')).format(format).toString();

/**
 * Permite dar crear un objeto Date desde un timestamp
 * @param timestamp 
 * @returns new Date()
 */
export const formatTimestampJs = (timestamp) => new Date(moment.unix(timestamp).format('MM/DD/YYYY').toString());

/**
 * Toma una cadena de fecha y devuelve una cadena de fecha en el formato que desee
 * @param fecha - string de fecha a formatear.
 * @param format /string [format=YYYY-MM-DD] - formato de fecha a retornar.
 */
export const formatStringtoDateString = (fecha, format = "YYYY-MM-DD") => moment(fecha).format(format).toString();

/**
 * Devuelve una cadena con la fecha actual en el formato que usted especifique
 * @param format -string = [format=YYYY-MM-DD] - formato de fecha a retornar.
 */
export const newDateToString = (format = "YYYY-MM-DD") => {
  const localeZone = navigator.languages.length > 0 ? navigator.languages[0] : 'es-CO'
  const formater = new Intl.DateTimeFormat(localeZone, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  })
  return moment(new Date()).format(format).toString();
};

/**
 * permite obtener dias de diferencia entre dos valores tipo timestamp.
 * @param {number} timestamp - number = 1575158400
 * @param currenttime - number = 1575158400
 * @returns retorna diferencia en dias
 */
export const diasDiff = (timestamp: number, currenttime: number) => {
  const oneDay = 24 * 60 * 60 * 1000;
  var today = new window.Date();
  const firstDate = new Date(
    moment.unix(currenttime).format('YYYY').toString() as any,
    moment.unix(currenttime).format('M').toString() as any,
    moment.unix(currenttime).format('D').toString() as any);
  const secondDate = new Date(
    moment.unix(timestamp).format('YYYY').toString() as any,
    moment.unix(timestamp).format('M').toString() as any,
    moment.unix(timestamp).format('D').toString() as any);

  const diffDays = Math.round(Math.abs(((firstDate as any) - (secondDate as any)) / oneDay));

  return diffDays;
}
