import { FormValidation } from '../../../shared/utilities';
import * as Yup from 'yup';

//Model base

export interface IFormReportesTerceros { 
    id?: number | null;
    inicio: Date | undefined;
    final: Date | undefined;
}

// Esquema de formulario
export const formSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    inicio: Yup.date().required(FormValidation.required),
    final: Yup.date().required(FormValidation.required)
});

// Estado inicial
export const initialState = (): IFormReportesTerceros => {
    const usuarioLocal = localStorage.getItem('usuario');
    const usuarioID = (usuarioLocal) && JSON.parse(usuarioLocal).data.sub;
    return {
        id: usuarioID,
        inicio: undefined,
        final: undefined
    };
}