import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Button, PopoverBody, Spinner, Table, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarCalendario, guardarCalendario, listarCalendario } from '../../../../core/services/precontractual/calendarioService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { ICalendarioForm } from '../../../../core/models/precontractual/calendarioModel';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { EEstadosPre, EGroupsNames, IBorrarRegistro } from '../../../../shared/utilities';
import FormCalendario from '../Forms/FormCalendario';
import ModalMsj from '../../../../shared/components/ModalMsj';
import { listActividades } from '../../../../core/services/listasDesplegables';
import { useUserContext } from '../../../../shared/contexts/userContext';
import FormCambioCronograma from '../Forms/FormCambioCronograma';
import { Field, FormikProvider, useFormik } from 'formik';
import DateField from '../../../../shared/components/DateField';

const TablaCalendario: FC = () => {
  const { t } = useTranslation();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { userState } = useUserContext();
  const { estado, obtenerActividades, calendario, proceso, roles } = usePrecontractualContext();

  const [loading, setLoading] = useState(true);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const esAdmin = !!userState.data?.roles.find(rol => rol === EGroupsNames.SUPERADMIN);

  const procesoCerrado = estado && [
    EEstadosPre.ADJUDICADO,
    EEstadosPre.CANCELADO,
    EEstadosPre.DESIERTO,
    EEstadosPre.FINALIZADO,
  ].includes(estado);

  const habilitar = useMemo(() => {
    if (estado === EEstadosPre.CRONOGRAMA_SOLICITUD && roles.solicitante) {
      return true;
    } else if (esAdmin && !procesoCerrado) {
      return true;
    } else if (proceso?.requiereadenda === 1 && roles.solicitante && estado === EEstadosPre.VERIFICACION_ADENDAS) {
      return true;
    } else {
      return false;
    }
  }, [proceso, esAdmin, estado, procesoCerrado, roles]);

  const listar = async () => {
    await obtenerActividades();
    setLoading(false);
  }

  const onSubmit = async (values: ICalendarioForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarCalendario(values, handleNotification);
    if (guardar) {
      await listar();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarCalendario(id, handleNotification)) {
      await listar();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Agregar Actividades'),
        body: () => (
          <FormCalendario
            onSubmit={onSubmit}
            record={null}
            actividades={calendario}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = calendario.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar actividad'),
        body: () => (record ? (
          <FormCalendario
            onSubmit={onSubmit}
            record={record}
            actividades={calendario}
          />
         ) : null
        )
      }
    }))
  };

  useEffect(() => {
    listar();
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      calendario
    },
    onSubmit: () => {},
  });

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando actividades...')}
          </span>
        </div>
      ) : (
        <FormikProvider value={formik}>
          <Table>
            <thead>
              <tr>
                <th align="center">{t('No.')}</th>
                <th align="center">{t('Actividades a Realizar')}</th>
                <th align="center">{t('Fecha Inicio')}</th>
                <th align="center">{t('Fecha Fin')}</th>
                {habilitar && (
                  <th align="center">{t('Acciones')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {calendario.map((row, index) => (
                <tr key={row.id}>
                  <td align="center">{row.nroactividad}</td>
                  <td align="left">
                    {listActividades.find(act => act.value === row.tipoactividad)?.label || t('Ninguna')}
                    {((row.tipoinicio !== 3 && !row.inicio) || (row.tipofin !== 3 && !row.fin)) && (
                      <>
                        <UncontrolledPopover
                          placement="top"
                          target={`cal-req-${row.id}`}
                          trigger="hover"
                        >
                          <PopoverBody>
                            {t('No se han asignado las fechas')}
                          </PopoverBody>
                        </UncontrolledPopover>
                        <span className="ml-1">
                          <i className="uil-bell text-danger cursor-pointer" id={`cal-req-${row.id}`}></i>
                        </span>
                      </>
                    )}
                  </td>
                  <td align="center">
                    {row.tipoinicio && row.tipoinicio !== 3 && (
                      <Field
                        as={DateField}
                        name={`calendario.${index}.inicio`}
                        invalid={false}
                        disabled={!habilitar}
                        hora={row.tipoinicio === 2}
                        onChange={(value) => {
                          if (habilitar) {
                            const values = {
                              ...row,
                              inicio: value,
                              idprecontractual: row.idprecontractual.id,
                            } as ICalendarioForm;
                            onSubmit(values);
                          }
                        }}
                      />
                    )}
                  </td>
                  <td align="center">
                    {row.tipofin && row.tipofin !== 3 && (
                      <Field
                        as={DateField}
                        name={`calendario.${index}.fin`}
                        invalid={false}
                        disabled={!habilitar}
                        hora={row.tipofin === 2}
                        onChange={(value) => {
                          const values = {
                            ...row,
                            fin: value,
                            idprecontractual: row.idprecontractual.id,
                          } as ICalendarioForm;
                          onSubmit(values);
                        }}
                      />
                    )}
                  </td>
                  {habilitar && (
                    <td align="center">
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleEdit(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Editar')}
                      >
                        <i className="uil-edit"></i>
                      </Button>
                      {!listActividades.find(act => act.value === row.tipoactividad)?.default && (
                        <Button
                          color="danger"
                          className="mr-1"
                          onClick={() => changeModal(row.id || 0)}
                          size="sm"
                          outline
                          title={t('Eliminar')}
                        >
                          <i className="uil-trash-alt"></i>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {calendario.length === 0 && (
            <Alert color="warning">{t('No se han agregado actividades.')}</Alert>
          )}
          {habilitar && (
            <ButtonCustom
              color="info"
              onClick={handleAdd}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-plus"></i>{t('Agregar Actividades')}
            </ButtonCustom>
          )}
          {userState.isAutenticated && (
            <>
              <hr />
              <FormCambioCronograma />
            </>
          )}
        </FormikProvider>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

export default TablaCalendario;
