import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { CustomInput, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const BooleanField: FC<IBooleanField> = ({ 
  invalid = false,
  disabled = false,
  labels = {
    "true": "Si",
    "false": "No"
  },
  ...props
}) => {
  const { setFieldValue, values, getFieldProps } = useFormikContext();
  const { t } = useTranslation();
  return (
    <div className={invalid ? "d-flex is-invalid" : "d-flex"}>
      <Label className="mr-4">
        <CustomInput
          type="radio"
          name={props.name}
          label={t(labels.true)}
          disabled={disabled}
          invalid={invalid}
          checked={getFieldProps(props.name)?.value === 1}
          {...props}
          onChange={() => setFieldValue(props.name, 1)}
          id={`${props.name}1`}
          value={undefined}
        />
      </Label>
      <Label className="mr-4">
        <CustomInput
          type="radio"
          name={props.name}
          label={t(labels.false)}
          disabled={disabled}
          invalid={invalid}
          checked={getFieldProps(props.name)?.value === 2}
          {...props}
          onChange={() => setFieldValue(props.name, 2)}
          value={undefined}
          id={`${props.name}2`}
        />
      </Label>
    </div>
  )
}

interface IBooleanField {
  field: any;
  labels: {
    "true": string,
    "false": string
  }
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
}

export default BooleanField;