import { IPaises } from "../../models/administracion/paisesModel";
import { deleteItem, getRequest, updateItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";

/**
 * Permite obtener los paises registrados en la bd
 */
 export const obtenerPaises = async (params?: any): Promise<IPaises[] | any> => {
  let data: IPaises[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_PAISES, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarPais = async (
  values: IPaises,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_PAIS : `${urlApi.EDIT_PAIS}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarPais = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_PAIS}${id}`;
  return await deleteItem(url, notification);
};
