import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import dev from "../../core/enviroments";
import axiosInt from "../../core/services/axiosService";
import { userLogout } from "../contexts/actions/userActions";
import { mainTypeMsj } from "../contexts/interfaces/mainInterfaces";
import { Emsg, IUserReducer } from "../contexts/interfaces/userInterfaces";
import { useMainContext } from "../contexts/mainContext";
import { useUserContext } from "../contexts/userContext";
import { isUserAuthenticated } from "../helpers/authUtils";

export interface IRequestUpdate {
  url?: string | null;
  params?: any;
  auth?: true;
  body?: any;
  requestData?: boolean;
  sendNotification?: boolean;
  contentType?: string;
  file?: boolean;
};

export const useUpdateRequest = (): [({ }: IRequestUpdate) => void, boolean] => {
  const { userDispatch } = useUserContext();
  const { handleNotification } = useMainContext();
  const [loading, setLoading] = useState(false);

  /**handleSubmit
   * Función para realizar peticiones get
   */
  const handleUpdate = async ({ params, body, url = null, requestData = false, auth = true, sendNotification = true, file = false, contentType = 'application/x-www-form-urlencoded' }: IRequestUpdate) => {
    setLoading(true);
    try {
      const DATA = createBody(file, body, contentType);
      const CONFIG = createConfig(file, auth, params, contentType);
      if (!CONFIG) {
        userDispatch(userLogout());
        return;
      }
      let resp: any = null;
      if (file) {
        resp = await axiosInt.post(`${dev.URL_SERVER}${url}`, DATA, CONFIG);
      } else {
        resp = body?.id ? await axiosInt.put(`${dev.URL_SERVER}${url}`, DATA, CONFIG) : await axiosInt.post(`${dev.URL_SERVER}${url}`, DATA, CONFIG)
      }

      setLoading(false);
      if (resp?.data?.code === 403) {
        if (sendNotification) {
          handleNotification(Emsg.expired, mainTypeMsj.ERROR);
        }
        userDispatch(userLogout());
        return;
      }
      if (resp?.data?.code === 400) {
        if (sendNotification) {
          handleNotification(resp?.data?.message, mainTypeMsj.ERROR);
        }
        return false;
      }
      if (sendNotification) {
        handleNotification(resp.data.message, mainTypeMsj.SUCCESS);
      }
      return requestData ? resp : true;
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (sendNotification) {
        handleNotification('Error inesperado', mainTypeMsj.ERROR);
      }
      return false;
    }
  };

  return [
    handleUpdate,
    loading
  ];
};

export const usePostForm = ({ params, body, url = null, auth = true }: IRequestUpdate): [any, () => void, boolean] => {
  const { userDispatch } = useUserContext();
  const { handleNotification } = useMainContext();
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Función para realizar peticiones get
   */
  const handlePost = async () => {
    setLoading(true);
    try {
      const FORM = new FormData();
      if (body.archivo) {
        FORM.append('archivo', body.archivo);
      }
      const CHAIN = JSON.stringify(body.json);
      FORM.append('json', CHAIN);
      let CONFIG: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      };
      if (auth) { // En caso de necesitar estar logueado se pasan esos parametros
        if (isUserAuthenticated()) { // si su token se encuentra activo se ejecuta la petición
          const dataJson: IUserReducer = isUserAuthenticated(true);
          CONFIG.headers = {
            Authorization: dataJson.data?.token
          };
        } else {
          userDispatch(userLogout());
          return;
        }
      }
      if (params) {
        CONFIG.params = params;
      }

      const resp: any = await axiosInt.post(`${dev.URL_SERVER}${url}`, FORM, CONFIG);
      if (resp?.data?.code === 403) {
        handleNotification(Emsg.expired, mainTypeMsj.ERROR);
        userDispatch(userLogout());
        setLoading(false);
        return;
      }
      setState(resp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      handleNotification('Error inesperado', mainTypeMsj.ERROR);
    }
  };

  useEffect(() => {
    if (url) {
      handlePost();
    }
  }, [url]);

  return [
    state,
    handlePost,
    loading
  ];
};

const createBody = (file: boolean, body: any, contentType: string) => {
  if (file) {
    const form = new FormData();
    if (body?.archivo) {
      form.append('archivo', body.archivo);
    }

    const archivos = body?.archivos || body?.json?.archivos;
    if (archivos) {
      Object.entries(archivos).map(archivo => {
        const [key] = archivo;
        archivos[key].map((file, i) => {
          form.append(`archivos[${key}][${i}]`, file);
        })
      })
    }

    const anexos = body?.anexos || body?.json?.anexos;
    if (anexos) {
      Object.entries(anexos)?.map((categoria) => {
        let index = 0;
        anexos[categoria[0]]?.map((archivo) => {
          if (archivo instanceof File) {
            form.append(`anexos[${categoria[0]}][${index}]`, archivo);
            index++;
          }
        })
      })
    }
    const CHAIN = JSON.stringify(body);
    form.append('json', CHAIN);
    return form;
  }
  const CHAIN = JSON.stringify(body);
  return contentType === 'application/x-www-form-urlencoded' ? `json=${CHAIN}` : body;
};

const createConfig = (file, auth, params, contentType) => {
  let CONFIG: AxiosRequestConfig = {
    headers: {
      'Content-Type': file ? 'multipart/form-data' : contentType
    },
  };
  if (auth) { // En caso de necesitar estar logueado se pasan esos parametros
    if (isUserAuthenticated()) { // si su token se encuentra activo se ejecuta la petición
      const dataJson: IUserReducer = isUserAuthenticated(true);
      CONFIG.headers = {
        Authorization: dataJson.data?.token
      };
    } else {
      return false
    }
  }

  if (params) {
    CONFIG.params = params;
  }

  return CONFIG;
}
