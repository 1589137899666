import React, { FC, useEffect } from 'react';
import { XYCoord, useDrop } from 'react-dnd';
import UbicacionesCaja from './UbicacionesCaja';
import { useVisorPDFContext } from '../../contexts/visorPDFContext';
import { v4 as uuidv4 } from 'uuid';
import { Button, Input, Spinner } from 'reactstrap';
import { IFormUbicacionesFirma, IUbicacionesFirma } from '../../../core/models/documentos/ubicacionesFirmaModel';
import { useMainContext } from '../../contexts/mainContext';
import { useTranslation } from 'react-i18next';
import { changeLoading } from '../../contexts/actions/mainActions';
import { borrarUbicacionesFirma } from '../../../core/services/documentos/ubicacionesFirmaService';

const UbicacionesEditView: FC<IUbicacionesEditView> = ({ numPages }) => {
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();

  const {
    version,
    listarUbicaciones,
    listarFirmas,
    ubicaciones,
    showMargin,
    pageNumber,
    onSubmitUbicacion,
    editar,
  } = useVisorPDFContext();

  useEffect(() => {
    if (version?.id) {
      listarUbicaciones();
    }
  }, [version?.id]);

  const handleChangeHoja = (e: React.ChangeEvent<HTMLInputElement>, ubicacion: IUbicacionesFirma) => {
    const nuevaHoja = Number(e.target.value);
    const values: IFormUbicacionesFirma = {
      id: ubicacion.id,
      posx: ubicacion.posx,
      posy: ubicacion.posy,
      hoja: nuevaHoja,
      iddocumento: ubicacion.iddocumento.id,
      idfirma: ubicacion.idfirma.id,
      activo: ubicacion.activo,
    }
    onSubmitUbicacion(values);
  }

  const handleMoveBox = (id: number, nuevaPosx: number, nuevaPosy: number) => {
    const ubicacion = ubicaciones.find(item => item.id === id);
    if (ubicacion) {
      const values: IFormUbicacionesFirma = {
        id: ubicacion.id,
        posx: nuevaPosx,
        posy: nuevaPosy,
        hoja: ubicacion.hoja,
        iddocumento: ubicacion.iddocumento.id,
        idfirma: ubicacion.idfirma.id,
        activo: ubicacion.activo,
      }
      onSubmitUbicacion(values);
    }
  }

  const handleDelete = async (ubicacion: IUbicacionesFirma) => {
    mainDispatch(changeLoading());
    const id = (ubicacion.id || 0);
    if (await borrarUbicacionesFirma(id, handleNotification)) {
      await listarFirmas();
      await listarUbicaciones();
    }
    mainDispatch(changeLoading());
  }
  
  const [, drop] = useDrop({
		accept: 'box',
		drop(item: IDragItem, monitor) {
			const delta = monitor.getDifferenceFromInitialOffset() as XYCoord
			const posx = Math.round(item.left + delta.x)
			const posy = Math.round(item.top + delta.y)
			handleMoveBox(item.id, posx, posy)
			return undefined
		},
	})

  const ubicacionesPage = ubicaciones.filter(({ hoja }) => hoja === pageNumber);
  
  return (
    <>
      <div ref={editar ? drop : null} className={showMargin ? "ubicaciones-view show-margin" : "ubicaciones-view"}>
        {ubicacionesPage.map(ubicacion => (
          <UbicacionesCaja key={ubicacion.id} id={ubicacion.id} item={ubicacion}>
            <div className="ubicaciones-title">{ubicacion.idfirma.idroles.nombre}</div>
            <div className="ubicaciones-subtitle">{ubicacion.idfirma.emailfirmante}</div>
            <div className="mt-1">
              <Input
                type="select"
                id={`hoja_${ubicacion.id}`}
                name={`hoja_${ubicacion.id}`}
                onChange={(e) => {
                  handleChangeHoja(e, ubicacion);
                }}
                value={ubicacion.hoja}
                disabled={!editar}
              >
                {[...Array(numPages)].map((x, i) => 
                  <option key={uuidv4()} value={(i + 1).toString()} >Hoja {i + 1}</option>
                )}
              </Input>
            </div>
            {mainState.loading ? (
              <Spinner color="primary" className="ubicaciones-loading" size="sm" />
            ) : (editar && (
              <Button
                color="danger"
                className="ubicaciones-delete"
                onClick={() => handleDelete(ubicacion)}
                size="sm"
                outline
                title={t('Eliminar')}
              >
                <i className="uil-trash-alt"></i>
              </Button>
            ))}
          </UbicacionesCaja>
        ))}
      </div>
    
    </>
  )
}

interface IUbicacionesEditView {
  numPages: number;
}

interface IDragItem {
	type: string;
	id: number;
	top: number;
	left: number;
}

export default UbicacionesEditView;
