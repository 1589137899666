const urlApi = {
  // Auth
  LOGIN: '/login',
  // Perfiles
  LIST_PERFILES: '/usuarios/groups/list',
  LIST_PERFILES_BY_CODE: '/usuarios/groups/fetch',
  // RECUPERAR CONTRASEÑA
  RECOVERY_PASSWORD: '/usuarios/users/recover',
  // RECUPERAR CONTRASEÑA
  CHANGE_PASSWORD: '/usuarios/users/change/',
  // LEER NOTIFICACIÓN
  NOTIFICATION_READ: '/noti/seguimiento/edit/',
  // Usuarios
  LIST_USUARIOS: '/usuarios/users/list',
  LIST_FILTER_USUARIOS: '/usuarios/users/list/filter',
  CREAR_USUARIO: '/usuarios/users/create',
  EDIT_USUARIO: '/usuarios/users/edit/',
  BORRAR_USUARIO: '/usuarios/users/remove/',
  SHOW_USUARIO: '/usuarios/users/show/',
  DASHBOARD_USUARIO: '/usuarios/users/dashboard',
  DATATABLE_USUARIOS: '/usuarios/users/datatable',
  LIST_FILTRO_USUARIOS: '/usuarios/users/lista_filtros',
  // Códigos
  CREATE_CODIGO: '/usuarios/codigos/generateforsign',
  CREATE_CODIGO_THIRD: '/usuarios/codigos/generateforsignthird',
  VALIDATE_CODIGO: '/usuarios/codigos/validatecode',
  VALIDATE_CODIGO_THIRD: '/usuarios/codigos/validatecodethird',
  CONTRATOS_POR_ESTADO: '/usuarios/users/contracts_by_state/',
  // Tokens
  LIST_TOKENS: '/usuarios/tokens/list',
  DELETE_TOKENS: '/usuarios/tokens/remove/',
  SHOW_TOKENS: '/usuarios/tokens/show/',
  EDIT_TOKENS: '/usuarios/tokens/edit/',
  CREATE_TOKENS: '/usuarios/tokens/create',
  // Variables
  OBTENER_VARIABLE: '/admin/variables/show/',
  LIST_VARIABLES: '/admin/variables/list',
  CREAR_VARIABLES: '/admin/variables/create',
  EDIT_VARIABLES: '/admin/variables/edit/',
  BORRAR_VARIABLES: '/admin/variables/remove/',
  // Paises
  LIST_PAISES: '/admin/paises/list',
  CREAR_PAIS: '/admin/paises/create',
  EDIT_PAIS: '/admin/paises/edit/',
  BORRAR_PAIS: '/admin/paises/remove/',
  // Paises
  LIST_DEPARTAMENTOS: '/admin/departamentos/list',
  CREAR_DEPARTAMENTO: '/admin/departamentos/create',
  EDIT_DEPARTAMENTO: '/admin/departamentos/edit/',
  BORRAR_DEPARTAMENTO: '/admin/departamentos/remove/',
  // Ciudades
  LIST_CIUDADES: '/admin/ciudades/list',
  CREAR_CIUDAD: '/admin/ciudades/create',
  EDIT_CIUDAD: '/admin/ciudades/edit/',
  BORRAR_CIUDAD: '/admin/ciudades/remove/',
  // Categorías de Contrato
  LIST_CATEGORIAS_CONRATO: '/admin/categorias/list',
  CREAR_CATEGORIA_CONTRATOS: '/admin/categorias/create',
  EDIT_CATEGORIA_CONTRATOS: '/admin/categorias/edit/',
  BORRAR_CATEGORIA_CONTRATOS: '/admin/categorias/remove/',
  // Tipos de Contrato
  LIST_TIPOS_CONTRATO: '/admin/tipocontrato/list',
  CREAR_TIPO_CONTRATO: '/admin/tipocontrato/create',
  EDIT_TIPO_CONTRATO: '/admin/tipocontrato/edit/',
  BORRAR_TIPO_CONTRATO: '/admin/tipocontrato/remove/',
  // Filiales
  LIST_FILIALES: '/admin/filiales/list',
  CREAR_FILIAL: '/admin/filiales/create',
  EDIT_FILIAL: '/admin/filiales/edit/',
  BORRAR_FILIAL: '/admin/filiales/remove/',
  // Condiciones de pago
  LIST_CONDICIONES_PAGO: '/admin/condicionpago/list',
  CREAR_CONDICION_PAGO: '/admin/condicionpago/create',
  EDIT_CONDICION_PAGO: '/admin/condicionpago/edit/',
  BORRAR_CONDICION_PAGO: '/admin/condicionpago/remove/',
  // Estados
  LIST_ESTADOS: '/admin/estados/list',
  CREAR_ESTADO: '/admin/estados/create',
  EDIT_ESTADO: '/admin/estados/edit/',
  BORRAR_ESTADO: '/admin/estados/remove/',
  // Centro de costos
  LIST_CENTRO_COSTOS: '/admin/centrocostos/list',
  CREAR_CENTRO_COSTO: '/admin/centrocostos/create',
  EDIT_CENTRO_COSTO: '/admin/centrocostos/edit/',
  BORRAR_CENTRO_COSTO: '/admin/centrocostos/remove/',
  // Plantillas
  LIST_PLANTILLAS: '/admin/plantillas/list',
  CREAR_PLANTILLA: '/admin/plantillas/create',
  EDIT_PLANTILLA: '/admin/plantillas/edit/',
  BORRAR_PLANTILLA: '/admin/plantillas/remove/',
  // Empresas
  LIST_EMPRESAS: '/admin/empresas/list',
  LIST_FILTER_EMPRESAS: '/admin/empresas/list/filter',
  CREAR_EMPRESA: '/admin/empresas/create',
  EDIT_EMPRESA: '/admin/empresas/edit/',
  BORRAR_EMPRESA: '/admin/empresas/remove/',
  // Terceros
  LIST_TERCEROS: '/terce/proveedorcliente/list',
  LIST_FILTER_TERCEROS: '/terce/proveedorcliente/list/filter',
  CREAR_TERCERO: '/terce/proveedorcliente/create',
  EDIT_TERCERO: '/terce/proveedorcliente/edit/',
  BORRAR_TERCERO: '/terce/proveedorcliente/remove/',
  DATATABLE_TERCERO: '/terce/proveedorcliente/datatable',
  LIST_FILTRO_TERCERO: '/terce/proveedorcliente/lista_filtros',
  // Vicepresidencias
  LIST_VICEPRESIDENCIAS: '/admin/vicepresidencias/list',
  CREAR_VICEPRESIDENCIA: '/admin/vicepresidencias/create',
  EDIT_VICEPRESIDENCIA: '/admin/vicepresidencias/edit/',
  BORRAR_VICEPRESIDENCIA: '/admin/vicepresidencias/remove/',
  // Gerencias
  LIST_GERENCIAS: '/admin/gerencias/list',
  CREAR_GERENCIA: '/admin/gerencias/create',
  EDIT_GERENCIA: '/admin/gerencias/edit/',
  BORRAR_GERENCIA: '/admin/gerencias/remove/',
  // Areas
  LIST_AREAS: '/admin/areas/list',
  LIST_AREAS_DISTINCT: '/admin/areas/list_distinct',
  CREAR_AREA: '/admin/areas/create',
  EDIT_AREA: '/admin/areas/edit/',
  BORRAR_AREA: '/admin/areas/remove/',
  // Proceso postcontractual
  CREAR_POSTCONTRACTUAL: '/post/postcontractual/create',
  EDIT_POSTCONTRACTUAL: '/post/postcontractual/edit/',
  LIST_POSTCONTRACTUAL: '/post/postcontractual/list',
  SHOW_POSTCONTRACTUAL: '/post/postcontractual/show/',
  DATATABLE_POSTCONTRACTUAL: '/post/postcontractual/datatable',
  LIST_FILTRO_POSTCONTRACTUAL: '/post/postcontractual/lista_filtros',
  // Adendas Postcontractual
  LIST_POST_ADENDAS: '/post/adendas/list',
  CREAR_POST_ADENDA: '/post/adendas/create',
  EDIT_POST_ADENDA: '/post/adendas/edit/',
  // Contractual
  LIST_CONTRACTUAL: '/contra/contractual/list',
  DATATABLE_CONTRACTUAL: '/contra/contractual/datatable',
  LIST_FILTRO_CONTRACTUAL: '/contra/contractual/lista_filtros',
  BORRAR_CONTRACTUAL: '/contra/contractual/remove/',
  SHOW_CONTRACTUAL: '/contra/contractual/show/',
  EDIT_CONTRACTUAL: '/contra/contractual/edit/',
  LIST_CONTRACTUAL_ALL: '/contra/contractual/list_all',
  //Step 1
  CREAR_CONTRACTUAL: '/contra/contractual/create',
  EDIT_CONTRACTUAL_STEP1: '/contra/contractualstep1/edit/',
  //Step 2
  EDIT_CONTRACTUAL_STEP2: '/contra/contractualstep2/edit/',
  SHOW_CONTRACTUAL_STEP2: '/contra/contractualstep2/show/',
  //Step 3
  EDIT_CONTRACTUAL_STEP3: '/contra/contractualstep3/edit/',
  SHOW_CONTRACTUAL_STEP3: '/contra/contractualstep3/show/',
  //ContractualUsuarios
  LIST_USUARIOS_APROBACIONES: '/contra/usuarios/list_aprobaciones/',
  LIST_CONTRACTUAL_USUARIOS: '/contra/usuarios/list',
  CREAR_CONTRACTUAL_USUARIOS: '/contra/usuarios/create',
  EDIT_CONTRACTUAL_USUARIOS: '/contra/usuarios/edit/',
  BORRAR_CONTRACTUAL_USUARIOS: '/contra/usuarios/remove/',
  SHOW_CONTRACTUAL_USUARIOS: '/contra/usuarios/show/',
  SHOW_POST_USUARIOS: '/post/usuarios/show/',
  //ContractualUsuarios
  LIST_POSTCONTRACTUAL_USUARIOS: '/post/usuarios/list',
  CREAR_POSTCONTRACTUAL_USUARIOS: '/post/usuarios/create',
  EDIT_POSTCONTRACTUAL_USUARIOS: '/post/usuarios/edit/',
  LIST_USUARIOS_APROBACIONES_POST: '/post/usuarios/list_aprobaciones/',
  //ContractualCondicionesPago
  LIST_CONTRACTUAL_CONDICIONES_PAGO: '/contra/condicionespago/list',
  CREAR_CONTRACTUAL_CONDICIONES_PAGO: '/contra/condicionespago/create',
  EDIT_CONTRACTUAL_CONDICIONES_PAGO: '/contra/condicionespago/edit/',
  BORRAR_CONTRACTUAL_CONDICIONES_PAGO: '/contra/condicionespago/remove/',
  // Suspensiones Postcontractual
  LIST_POST_SUSPENSIONES: '/post/suspension/list',
  CREAR_POST_SUSPENSION: '/post/suspension/create',
  EDIT_POST_SUSPENSION: '/post/suspension/edit/',
  CREAR_POST_REINICIO: '/post/reinicio/create',
  EDIT_POST_REINICIO: '/post/reinicio/edit/',
  // Liquidaciones Postcontractual
  LIST_POST_LIQUIDACIONES: '/post/liquidacion/list',
  CREAR_POST_LIQUIDACION: '/post/liquidacion/create',
  EDIT_POST_LIQUIDACION: '/post/liquidacion/edit/',
  // Renovacion Postcontractual
  LIST_POST_RENOVACIONES: '/post/renovacion/list',
  CREAR_POST_RENOVACION: '/post/renovacion/create',
  EDIT_POST_RENOVACION: '/post/renovacion/edit/',
  // DOCUMENTOS TAREAS
  LIST_TAREA_DOCUMENTOS: '/tareas/documentos/list',
  CREAR_TAREA_DOCUMENTOS: '/tareas/documentos/create',
  // DOCUMENTOS CONTRACTUAL
  LIST_CONTRA_DOCUMENTOS: '/contra/documentos/list',
  CREAR_CONTRA_DOCUMENTO: '/contra/documentos/create',
  EDIT_CONTRA_DOCUMENTO: '/contra/documentos/edit/',
  DOWN_CONTRA_DOCUMENTO: '/contra/documentos/download/',
  BORRAR_CONTRA_DOCUMENTO: '/contra/documentos/remove/',
  // DOCUMENTOS CONTRACTUAL
  LIST_TAREAS: '/tareas/tareas/list',
  CREATE_TAREAS: '/tareas/tareas/create',
  EDIT_TAREAS: '/tareas/tareas/edit/',

  // COMENTARIOS TAREAS CONTRACTUAL
  LIST_COMMENT_TAREAS: '/tareas/foro/list',
  CREATE_COMMENT_TAREAS: '/tareas/foro/create',
  // DOCUMENTOS POSTCONTRACTUAL
  LIST_POST_DOCUMENTOS: '/post/documentos/list',
  CREAR_POST_DOCUMENTO: '/post/documentos/create',
  EDIT_POST_DOCUMENTO: '/post/documentos/edit/',
  // APROBACION LEGAL CONTRACTUAL
  INFO_COMPLETA_CONTRACTUAL: '/contra/informacioncompleta/edit/',
  // COMENTARIOS CONTRACTUAL
  COMENT_CONTRACTUAL: '/contra/comentarios/list',
  CREATE_COMENT_CONTRACTUAL: '/contra/comentarios/create',
  // FORO MINUTA
  CONSULTAR_TRM: '/api/v1/trm',
  // FORO MINUTA
  CREATE_COMMENT_CONTRACTUAL: '/contra/foro/create',
  LIST_COMMENTS_CONTRACTUAL: '/contra/foro/list',
  // FORO POSTCONTRACTUAL
  CREATE_COMMENT_POSTCONTRACTUAL: '/post/foro/create',
  LIST_COMMENTS_POSTCONTRACTUAL: '/post/foro/list',
  // APROBAR SOLICITANTE
  APROBAR_CONTRA_SOLICITANTE: '/contra/aprobarsolicitante/edit/',
  // APROBAR TERCERO
  APROBAR_CONTRA_TERCERO: '/contra/aprobarminuta/edit/',
  // APROBAR PROCESO CONTRACTUAL
  APROBAR_PROCESO_CONTRA: '/contra/aprobaciones/create',
  APROBAR_PROCESO_CONTRA_LIST: '/contra/aprobaciones/list',
  APROBAR_PROCESO_CONTRA_EDIT: '/contra/aprobaciones/edit/',
  // APROBAR APROBADOR
  APROBAR_CONTRA_APROBADOR: '/contra/usuarios/edit/',
  APROBAR_POST_APROBADOR: '/post/usuarios/edit/',
  // REQUIERE POLIZA O GARANTIA
  REQUIEREPOLIZA_CONTRA: '/contra/requierepolizas/edit/',
  // CREETE POLIZA GARANTIA
  CREATE_POLIZAGARANTIA: '/contra/polizagarantia/create',
  LIST_POLIZAGARANTIA: '/contra/polizagarantia/list/',
  EDIT_POLIZAGARANTIA: '/contra/polizagarantia/edit/',
  // REQUERIMIENTOS CONTRACTUAL
  SHOW_REQUERIMIENTO: '/contra/requerimientos/show/',
  LIST_REQUERIMIENTOS: '/contra/requerimientos/list',
  CREATE_REQUERIMIENTO: '/contra/requerimientos/create',
  EDIT_REQUERIMIENTOS: '/contra/requerimientos/edit/',
  // AMPARO CONTRACTUAL
  CONTRA_AMPARO_CREATE: '/contra/amparos/create',
  CONTRA_AMPARO_EDIT: '/contra/amparos/edit/',
  CONTRA_AMPARO_LIST: '/contra/amparos/list',
  //Aprobar Acta
  POST_APROBAR_ACTA: '/post/aprobaracta/create',
  // Reportes
  REPORTE_CONTRACTUAL: '/reporte/contractual/list',
  REPORTE_POSTCONTRACTUAL: '/reporte/postcontractual/list',
  REPORTE_TERCEROS: '/reporte/proveedorcliente/list',
  // Notificaciones
  NOTIFICACIONES_LIST: '/noti/notificaciones/list/',
  // Financiero
  SHOW_CHART_FINANCIERO: '/finan/pagos/showchart/',
  SHOW_DATAPROCESO_FINANCIERO: '/finan/pagos/dataproceso/',
  SHOW_PAGOS_FINANCIERO: '/finan/pagos/listbypostcontractual/',
  CHANGE_STATUS_PAGO: '/finan/pagos/changestatus/',
  CREATE_PLAN_PAGO: '/finan/planpagos/create',
  CREATE_PAGO: '/finan/pagos/create',
  EDIT_PAGO: '/finan/pagos/edit/',
  SHOW_FINANCIERO_BY_POSTCONTRACTUAL: '/finan/financiero/showbypostcontractual/',
  CREATE_FINANCIERO: '/finan/financiero/create',
  EDIT_FINANCIERO: '/finan/financiero/edit/',
  // Comentarios postcontractual
  COMENT_POSTCONTRACTUAL: '/post/comentarios/list',
  CREATE_COMENT_POSTCONTRACTUAL: '/post/comentarios/create',
  BORRAR_PAGO: '/finan/pagos/remove/',
  // APROBAR PROCESO POSTCONTRACTUAL
  APROBAR_PROCESO_POST: '/post/aprobaciones/create',
  APROBAR_PROCESO_POST_LIST: '/post/aprobaciones/list',
  APROBAR_PROCESO_POST_EDIT: '/post/aprobaciones/edit/',
  // TERCEROS REPRESENTANTES LEGALES
  LIST_REPRESENTANTES: '/terce/representanteslegales/list',
  CREAR_REPRESENTANTE: '/terce/representanteslegales/create',
  EDIT_REPRESENTANTE: '/terce/representanteslegales/edit/',
  BORRAR_REPRESENTANTE: '/terce/representanteslegales/remove/',
  // TERCEROS DOCUMENTOS 
  LIST_TERCE_DOCUMENTOS: '/terce/documentos/list',
  CREAR_TERCE_DOCUMENTOS: '/terce/documentos/create',
  EDIT_TERCE_DOCUMENTOS: '/terce/documentos/edit/',
  DOWN_TERCE_DOCUMENTOS: '/terce/documentos/download/',
  // Validación financiera
  LIST_VALID_FINANCIERA: '/contra/financiero/list',
  CREAR_VALID_FINANCIERA: '/contra/financiero/create',
  EDIT_VALID_FINANCIERA: '/contra/financiero/edit/',
  // Asesorias
  CREATE_ASESORIAS: '/ase/asesorias/create',
  EDIT_ASESORIAS: '/ase/asesorias/edit/',
  LIST_ASESORIAS: '/ase/asesorias/list',
  SHOW_ASESORIAS: '/ase/asesorias/show/',
  DATATABLE_ASESORIAS: '/ase/asesorias/datatable',
  LIST_FILTRO_ASESORIAS: '/ase/asesorias/lista_filtros',
  STATES_ASESORIAS: '/ase/asesorias/states/',
  LIST_ASESORIAS_USUARIOS: '/ase/usuarios/list',
  LIST_ASESORIAS_DOCUMENTOS: '/ase/documentos/list',
  LIST_ASESORIAS_ABOGADOS: '/ase/usuarios/list_abogados',
  LIST_PREGUNTAS: '/ase/preguntas/list',
  BORRAR_ASESORIAS_USUARIOS: '/ase/usuarios/remove/',
  CREAR_ASESORIAS_USUARIOS: '/ase/usuarios/create',
  EDIT_ASESORIAS_USUARIOS: '/ase/usuarios/edit/',
  SEARCH_PREGUNTAS: '/ase/preguntas/search',
  LIST_ASESORIAS_LOGS: '/ase/logs/list',
  LIST_ASESORIAS_ANS: '/ase/ans/list',
  CREATE_COMENTARIO:'/ase/observaciones/create',
  LIST_COMENTARIOS_ASESORIAS: '/ase/observaciones/list',
  // Permisos
  LIST_PERMISOS: '/usuarios/permission/list',
  CREAR_PERMISO: '/usuarios/permission/create',
  EDIT_PERMISO: '/usuarios/permission/edit/',
  BORRAR_PERMISO: '/usuarios/permission/remove/',
  // JURIDICO
  LIST_JURIDICO: '/juri/juridico/list',
  BORRAR_JURIDICO: '/juri/juridico/remove/',
  SHOW_JURIDICO: '/juri/juridico/show/',
  EDIT_JURIDICO: '/juri/juridico/edit/',
  STATES_JURIDICO: '/juri/juridico/states/',
  CREATE_JURIDICO: '/juri/juridico/create',
  DATATABLE_JURIDICO: '/juri/juridico/datatable',
  LIST_FILTRO_JURIDICO: '/juri/juridico/lista_filtros',
  CREATE_OBSERVACION:'/juri/observaciones/create',
  LIST_OBSERVACION: '/juri/observaciones/list',
  EDIT_OBSERVACION: '/juri/observaciones/edit/',
  // JURIDICO USUARIOS
  LIST_JURIDICO_USUARIOS: '/juri/usuarios/list',
  CREAR_JURIDICO_USUARIOS: '/juri/usuarios/create',
  EDIT_JURIDICO_USUARIOS: '/juri/usuarios/edit/',
  BORRAR_JURIDICO_USUARIOS: '/juri/usuarios/remove/',
  SHOW_JURIDICO_USUARIOS: '/juri/usuarios/show/',
  // JURIDICO DOCUMENTOS
  LIST_JURIDICO_DOCUMENTOS: '/juri/documentos/list',
  CREAR_JURIDICO_DOCUMENTO: '/juri/documentos/create',
  EDIT_JURIDICO_DOCUMENTO: '/juri/documentos/edit/',
  DOWN_JURIDICO_DOCUMENTO: '/juri/documentos/download/',
  // JURIDICO NOVEDADES
  LIST_JURIDICO_NOVEDADES: '/juri/novedades/list',
  BORRAR_JURIDICO_NOVEDADES: '/juri/novedades/remove/',
  SHOW_JURIDICO_NOVEDADES: '/juri/novedades/show/',
  SHOW_PROCESO_JURIDICO_NOVEDADES: '/juri/novedades/show/proceso',
  EDIT_JURIDICO_NOVEDADES: '/juri/novedades/edit/',
  CREATE_JURIDICO_NOVEDADES: '/juri/novedades/create',
  // Modules
  LIST_MODULES: '/usuarios/modules/list',
  CREAR_MODULE: '/usuarios/modules/create',
  EDIT_MODULE: '/usuarios/modules/edit/',
  BORRAR_MODULE: '/usuarios/modules/remove/',
  // Roles
  LIST_ROLES: '/usuarios/groups/list',
  CREAR_ROL: '/usuarios/groups/create',
  EDIT_ROL: '/usuarios/groups/edit/',
  BORRAR_ROL: '/usuarios/groups/remove/',
  // Cargos
  LIST_CARGOS: '/admin/cargos/list',
  CREAR_CARGO: '/admin/cargos/create',
  EDIT_CARGO: '/admin/cargos/edit/',
  BORRAR_CARGO : '/admin/cargos/remove/',
  // Unidad medida
  LIST_UNIDAD_MEDIDA: '/admin/unidad_medida/list',
  SHOW_UNIDAD_MEDIDA: '/admin/unidad_medida/show/',
  CREATE_UNIDAD_MEDIDA: '/admin/unidad_medida/create',
  EDIT_UNIDAD_MEDIDA: '/admin/unidad_medida/edit/',
  DELETE_UNIDAD_MEDIDA: '/admin/unidad_medida/remove/',
  // Zoho editar
  ZOHO_EDIT: '/service/zoho/editar/',
  // Versiones
  LIST_VERSIONES: '/docu/versiones/list',
  CREAR_VERSIONES: '/docu/versiones/create',
  EDIT_VERSIONES: '/docu/versiones/edit/',
  BORRAR_VERSIONES: '/docu/versiones/remove/',
  EDIT_ZOHO_URL_VERSIONES: '/docu/versiones/edit_zoho_url/',
  UPLOAD_FILE_VERSIONES: '/docu/versiones/upload_file',
  // Comentarios
  LIST_COMENTARIOS: '/docu/comentarios/list',
  CREAR_COMENTARIOS: '/docu/comentarios/create',
  EDIT_COMENTARIOS: '/docu/comentarios/edit/',
  BORRAR_COMENTARIOS: '/docu/comentarios/remove/',
  // Roles firma
  LIST_ROLES_FIRMA: '/docu/roles_firma/list',
  BORRAR_ROLES_FIRMA: '/docu/roles_firma/remove/',
  SHOW_ROLES_FIRMA: '/docu/roles_firma/show/',
  EDIT_ROLES_FIRMA: '/docu/roles_firma/edit/',
  CREATE_ROLES_FIRMA: '/docu/roles_firma/create',
  // Firmas
  LIST_FIRMAS: '/docu/firmas/list',
  CREAR_FIRMAS: '/docu/firmas/create',
  EDIT_FIRMAS: '/docu/firmas/edit/',
  BORRAR_FIRMAS: '/docu/firmas/remove/',
  SHOW_FIRMAS: '/docu/firmas/show/',

  // Firma de usuarios
  SHOW_FIRMA: '/usuarios/firmas/show/',
  SHOW_FIRMA_THIRD: '/usuarios/firmas/showthird/',
  SIGN_FIRMA: '/usuarios/firmas/sign',
  LIST_FIRMA: '/usuarios/firmas/list',

  // Ubicaciones firma usuarios
  //LIST_UBICACIONES_FIRMA: '/usuarios/ubicacionesfirma/list',
  //BORRAR_UBICACIONES_FIRMA: '/usuarios/ubicacionesfirma/remove/',
  //SHOW_UBICACIONES_FIRMA: '/usuarios/ubicacionesfirma/show/',
  //EDIT_UBICACIONES_FIRMA:  '/usuarios/ubicacionesfirma/edit/',
  CREATE_UBICACIONES_FIRMA: '/usuarios/ubicacionesfirma/create',

  // Ubicaciones
  LIST_UBICACIONES_FIRMA: '/docu/ubicaciones_firma/list',
  CREAR_UBICACIONES_FIRMA: '/docu/ubicaciones_firma/create',
  EDIT_UBICACIONES_FIRMA: '/docu/ubicaciones_firma/edit/',
  BORRAR_UBICACIONES_FIRMA: '/docu/ubicaciones_firma/remove/',
  // Carga Masiva
  LIST_CARGA_MASIVA: '/docu/carga_masiva/list',
  CREAR_CARGA_MASIVA: '/docu/carga_masiva/create',
  EDIT_CARGA_MASIVA: '/docu/carga_masiva/edit/',
  BORRAR_CARGA_MASIVA: '/docu/carga_masiva/remove/',
  SHOW_CARGA_MASIVA: '/docu/carga_masiva/show/',
  // Códigos
  LIST_CODIGOS: '/docu/codigos/list',
  CREAR_CODIGOS: '/docu/codigos/create',
  EDIT_CODIGOS: '/docu/codigos/edit/',
  BORRAR_CODIGOS: '/docu/codigos/remove/',
  SHOW_CODIGOS: '/docu/codigos/show/',
  // Anexos
  LIST_ANEXOS: '/docu/anexos/list',
  CREAR_ANEXOS: '/docu/anexos/create',
  EDIT_ANEXOS: '/docu/anexos/edit/',
  BORRAR_ANEXOS: '/docu/anexos/remove/',
  SHOW_ANEXOS: '/docu/anexos/show/',
  // PreAdendas
  PRE_ADENDAS: {
    LIST:   '/pre/adendas/list',
    SHOW:   '/pre/adendas/show/',
    CREATE: '/pre/adendas/create',
    EDIT:   '/pre/adendas/edit/',
    DELETE: '/pre/adendas/remove/',
  },
  // PreAprobaciones
  PRE_APROBACIONES: {
    LIST:   '/pre/aprobaciones/list',
    SHOW:   '/pre/aprobaciones/show/',
    CREATE: '/pre/aprobaciones/create',
    EDIT:   '/pre/aprobaciones/edit/',
    DELETE: '/pre/aprobaciones/remove/',
  },
  // PreCalendario
  PRE_CALENDARIO: {
    LIST:   '/pre/calendario/list',
    SHOW:   '/pre/calendario/show/',
    CREATE: '/pre/calendario/create',
    EDIT:   '/pre/calendario/edit/',
    DELETE: '/pre/calendario/remove/',
  },
  // PreCancelaciones
  PRE_CANCELACIONES: {
    LIST:   '/pre/cancelaciones/list',
    SHOW:   '/pre/cancelaciones/show/',
    CREATE: '/pre/cancelaciones/create',
    EDIT:   '/pre/cancelaciones/edit/',
    DELETE: '/pre/cancelaciones/remove/',
  },
  // PreCriterios
  PRE_CRITERIOS: {
    LIST:   '/pre/criterios/list',
    SHOW:   '/pre/criterios/show/',
    CREATE: '/pre/criterios/create',
    EDIT:   '/pre/criterios/edit/',
    DELETE: '/pre/criterios/remove/',
  },
  // PreDocumentosOferta
  PRE_DOCUMENTOS_OFERTA: {
    LIST:   '/pre/documentos_oferta/list',
    SHOW:   '/pre/documentos_oferta/show/',
    CREATE: '/pre/documentos_oferta/create',
    EDIT:   '/pre/documentos_oferta/edit/',
    DELETE: '/pre/documentos_oferta/remove/',
  },
  // PreDocumentosProveedores
  PRE_DOCUMENTOS_PROVEEDORES: {
    LIST:   '/pre/documentos_proveedores/list',
    SHOW:   '/pre/documentos_proveedores/show/',
    CREATE: '/pre/documentos_proveedores/create',
    EDIT:   '/pre/documentos_proveedores/edit/',
    DELETE: '/pre/documentos_proveedores/remove/',
  },
  // PreDocumentosRequeridos
  PRE_DOCUMENTOS_REQUERIDOS: {
    LIST:   '/pre/documentos_requeridos/list',
    SHOW:   '/pre/documentos_requeridos/show/',
    CREATE: '/pre/documentos_requeridos/create',
    EDIT:   '/pre/documentos_requeridos/edit/',
    DELETE: '/pre/documentos_requeridos/remove/',
  },
  // PreEvaluacion
  PRE_EVALUACION: {
    LIST:   '/pre/evaluacion/list',
    SHOW:   '/pre/evaluacion/show/',
    CREATE: '/pre/evaluacion/create',
    EDIT:   '/pre/evaluacion/edit/',
    DELETE: '/pre/evaluacion/remove/',
  },
  // PreOferta
  PRE_OFERTA: {
    LIST:   '/pre/oferta/list',
    SHOW:   '/pre/oferta/show/',
    CREATE: '/pre/oferta/create',
    EDIT:   '/pre/oferta/edit/',
    DELETE: '/pre/oferta/remove/',
    BULK:   '/pre/oferta/bulk_load',
  },
  // PrePrecontractual
  PRE_PRECONTRACTUAL: {
    CONTRACTS:'/pre/precontractual/contracts/',
    LIST:   '/pre/precontractual/list',
    SHOW:   '/pre/precontractual/show/',
    CREATE: '/pre/precontractual/create',
    EDIT:   '/pre/precontractual/edit/',
    DELETE: '/pre/precontractual/remove/',
    STATES: '/pre/precontractual/states/',
    DATATABLE: '/pre/precontractual/datatable',
    FILTROS: '/pre/precontractual/lista_filtros'
  },
  // PrePreguntas
  PRE_PREGUNTAS: {
    LIST:   '/pre/preguntas/list',
    SHOW:   '/pre/preguntas/show/',
    CREATE: '/pre/preguntas/create',
    EDIT:   '/pre/preguntas/edit/',
    DELETE: '/pre/preguntas/remove/',
    DOWNLOAD:'/pre/preguntas/download/',
  },
  // PreProducto
  PRE_PRODUCTO: {
    LIST:   '/pre/producto/list',
    SHOW:   '/pre/producto/show/',
    CREATE: '/pre/producto/create',
    EDIT:   '/pre/producto/edit/',
    DELETE: '/pre/producto/remove/',
    BULK:   '/pre/producto/bulk_load',
    DOWNLOAD:'/pre/producto/download/',
  },
  // PreProveedores
  PRE_PROVEEDORES: {
    LIST:   '/pre/proveedores/list',
    SHOW:   '/pre/proveedores/show/',
    CREATE: '/pre/proveedores/create',
    EDIT:   '/pre/proveedores/edit/',
    NOTIFI: '/pre/proveedores/notification/',
    DELETE: '/pre/proveedores/remove/',
  },
  // PreUsuarios
  PRE_USUARIOS: {
    LIST:   '/pre/usuarios/list',
    SHOW:   '/pre/usuarios/show/',
    CREATE: '/pre/usuarios/create',
    EDIT:   '/pre/usuarios/edit/',
    DELETE: '/pre/usuarios/remove/',
  },
};

export default urlApi;
