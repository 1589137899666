import React, { FC, useState, useContext } from "react";
import {
  Container,
} from 'reactstrap';
import Main from '../components/Main';
import Wrapper from '../components/Wrapper';
import Content from '../components/Content';
import { ILayout } from "../utilities";
import Footer from "../components/Footer";
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import { mainContext } from "../contexts";
import logo from '../../assets/img/logos/logo.png';

const Third: FC<ILayout> = ({ children }) => {
  const { mainState } = useContext(mainContext);

  return (
    (
      <Wrapper>
        <Main className="d-flex flex-column justify-content-between">
          <div>
            <Content >
              <Container>
                <Header className="d-flex flex-md-row justify-content-md-between">
                  <HeaderTitle tag="h2">{mainState.header?.title}</HeaderTitle>
                  <img src={logo} alt="Comforce" className="img-fluid" width="180" height="44" />
                </Header>
                {children}
              </Container>
            </Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    )
  )
};
export default Third;
