import React, { FC } from 'react';
import NumberFormat, { SyntheticInputEvent } from 'react-number-format';

const OldOldNumberField: FC<any> = ({currency, ...props}) => {
  return (
    <>
      {
        currency ? (
          <NumberFormat
            {...props}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            value={props.form.getFieldProps(props.field.name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              const value: string = e.target.value.split('.').join('');
              const formatValue = value.split(',').join('.');
              props.form.setFieldValue(props.field.name, formatValue);
            }}
          />
        ) : (
          <NumberFormat
            {...props}
            type="text"
            decimalScale={0}
            allowNegative={false}
            isNumericString
            value={props.form.getFieldProps(props.field.name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              props.form.setFieldValue(props.field.name, e.target.value);
            }}
          />
        )
      }
    </>
  );
};
export default OldOldNumberField;
