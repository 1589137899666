import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { IPrecontractualForm } from '../../../../core/models/precontractual/precontractualModel';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import * as Yup from 'yup';
import { EEstadosPre, EGroupsNames, FormValidation } from '../../../../shared/utilities';
import { Col, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import UniqueFileField from '../../../../shared/components/UniqueFileField';
import { useUserContext } from '../../../../shared/contexts/userContext';

const FormCambioCronograma: FC = () => {

  const { t } = useTranslation();
  const { mainState } = useMainContext();
  const { userState } = useUserContext();
  const { estado, guardarProceso, obtenerProceso, proceso, roles } = usePrecontractualContext();

  const esAdmin = !!userState.data?.roles.find(rol => rol === EGroupsNames.SUPERADMIN);

  const estadoAdendas = estado === EEstadosPre.VERIFICACION_ADENDAS;
  const procesoCerrado = estado && [
    EEstadosPre.ADJUDICADO,
    EEstadosPre.CANCELADO,
    EEstadosPre.DESIERTO,
    EEstadosPre.FINALIZADO,
  ].includes(estado);
  const habilitar = (
    (esAdmin && !procesoCerrado) || (proceso?.requiereadenda === 1 && roles.solicitante && estadoAdendas)
  );

  const initialValues: IPrecontractualForm = {
    id: proceso?.id,
    idaprobacioncambio: proceso?.idaprobacioncambio?.id || 0,
  } as IPrecontractualForm;

  const validationSchema = Yup.object().shape({
    idaprobacioncambio: Yup.number(),
  });

  const onSubmit = async (values: IPrecontractualForm) => {
    const proceso = await guardarProceso(values);
    if (proceso?.id) {
      await obtenerProceso(proceso.id);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  return (
    <Form id="FormCambioCronograma" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Row>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label>{t('Cargue Aprobación Cambio Cronograma')}</Label>
              <Field
                as={UniqueFileField}
                name="idaprobacioncambio"
                invalid={errors.idaprobacioncambio && touched.idaprobacioncambio}
                disabled={!habilitar}
                category="cambio_cronograma"
                modulo="PrePrecontractual"
                idmodulo={proceso?.id}
                record={proceso?.idaprobacioncambio}
              />
              <FormFeedback>{errors.idaprobacioncambio && t(errors.idaprobacioncambio)}</FormFeedback>
            </FormGroup>
          </Col>
          {habilitar && (
            <Col sm={12} md={12}>
              <ButtonCustom
                type="submit"
                color="secondary"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Guardar documento')}
              </ButtonCustom>
            </Col>
          )}
        </Row>
      </FormikProvider>
    </Form>
  )
}

export default FormCambioCronograma;
