//TODO adjust to report Terceros

import React, { FC } from 'react';
// Context
import { useMainContext } from '../../../../shared/contexts/mainContext';
// Model
import { initialState, formSchema, IFormReportesTerceros } from '../../../../core/models/reportes/reporteTercerosModel';
// Reactstrap
import {
  FormGroup,
  Label,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
//FORM
import { Formik, Form, Field, useFormikContext } from 'formik';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import OldDateField from '../../../../shared/components/OldDateField';
import { useTranslation } from 'react-i18next';

const FormReporteTerceros: FC<IFormReporteTerceros> = ({ onSubmit, data }) => {

  const { mainState } = useMainContext();
  const { t } = useTranslation();
  const contextForm = useFormikContext();

  return (
    <Formik
      initialValues={
        initialState()
      }
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched }) => {
        const errorInicio: any = errors.inicio || '';
        const errorFinal: any = errors.final || '';
        return (
          <Form id="formReporteTerceros">
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>{t('Fecha inicio')}</Label>
                  <Field
                    name='inicio'
                    dateFormat="yyyy-MM-dd"
                    selected={values?.inicio ? new Date(values.inicio) : null}
                    component={OldDateField}
                    className={(errors.inicio && touched.inicio) ? 'is-invalid form-control' : 'form-control'}
                  />
                  <FormFeedback style={{
                    display: (errors.inicio && touched.inicio) ? 'block' : 'none'
                  }}>{t(errorInicio)}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>{t('Fecha fin')}</Label>
                  <Field
                    name='final'
                    dateFormat="yyyy-MM-dd"
                    selected={values?.final ? new Date(values.final) : null}
                    component={OldDateField}
                    className={(errors.final && touched.final) ? 'is-invalid form-control' : 'form-control'}
                  />
                  <FormFeedback style={{
                    display: (errors.final && touched.final) ? 'block' : 'none'
                  }}>{t(errorFinal)}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={12}>
                <ButtonCustom
                  form={`formReporteTerceros`}
                  className="d-flex align-items-center"
                  type="submit"
                  color="primary"
                  loading={contextForm?.isSubmitting || mainState.loading}
                  disabled={contextForm?.isSubmitting || mainState.loading}
                >
                  {t('Generar reporte')}
                </ButtonCustom>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

interface IFormReporteTerceros {
  data?: IFormReportesTerceros
  onSubmit: (values: IFormReportesTerceros) => void
}

export default FormReporteTerceros






