import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IAnexos, IAnexosForm } from '../../models/documentos/anexosModel';

/**
 * Permite obtener los registros en la bd
 */
export const listarAnexos = async (params?): Promise<IAnexos[]> => {
  let data: IAnexos[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ANEXOS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarAnexos = async (id: number): Promise<IAnexos | null> => {
  let data: IAnexos | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_ANEXOS}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarAnexos = async (
  values: IAnexosForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IAnexos | null> => {
  const url = (!values.id) ? urlApi.CREAR_ANEXOS : `${urlApi.EDIT_ANEXOS}${values.id}`
  const response = await updateItem(url, values, notification, true, false, true);
  return response?.data?.record;
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarAnexos = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_ANEXOS}${id}`;
  return await deleteItem(url, notification);
};
