const env = typeof process != 'undefined' ? (process.env.REACT_APP_ENV || 'production') : 'production';
const config = require(`./env.${env}`)

const dev = {
  ...config.env,
  blockchain: true,
  blockchain_otp: true
};

export default dev;
