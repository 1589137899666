import React, { FC, useEffect, useState } from 'react';
import { IFormTerceros } from '../../../../../core/models/administracion/tercerosModel';
import { falseLoading, trueLoading } from '../../../../../shared/contexts/actions/mainActions';
import { useMainContext } from '../../../../../shared/contexts/mainContext';
import { guardarTercero } from '../../../../../core/services/administracion/tercerosService';
import FormTerceros from '../../../../administracion/terceros/components/FormTercero';
import { IPaises } from '../../../../../core/models/administracion/paisesModel';
import { obtenerPaises } from '../../../../../core/services/administracion/paisesService';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { IProveedoresForm } from '../../../../../core/models/precontractual/proveedoresModel';
import { usePrecontractualContext } from '../../../../../shared/contexts/precontractualContext';
import { guardarProveedores } from '../../../../../core/services/precontractual/proveedoresService';

const FormRegistroTerceros: FC = () => {
  const { mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { proceso } = usePrecontractualContext();

  const [finalizar, setFinalizar] = useState(false);
  const [paises, setPaises] = useState<IPaises[]>([]);

  const listarPaises = async () => {
    const params = {
      filter: 'activo',
      valuefilter: 1
    };
    setPaises(await obtenerPaises(params));
  };

  const onSaveTercero = async (values: IFormTerceros, tipo?: number | string) => {
    mainDispatch(trueLoading());
    const resp = await guardarTercero(values, handleNotification);
    if (resp) {
      const proveedor: IProveedoresForm = {
        id: null,
        idprecontractual: proceso?.id ?? 0,
        idproveedorcliente: resp.id ?? 0,
        iddocoferta: 0,
        activo: 1,
      } as IProveedoresForm;
      await guardarProveedores(proveedor, handleNotification);
      setFinalizar(true);
    }
    mainDispatch(falseLoading());
  };

  useEffect(() => {
    listarPaises();
  }, []);

  return (
    <>
      {!finalizar ? (
        <>
          <Alert color="info">
            {t('Realice el registro completando la siguiente información.')}
          </Alert>
          <FormTerceros
            onSubmit={(data: IFormTerceros) => onSaveTercero(data, 1)}
            onCancel={() => setFinalizar(true)}
            empresas={[]}
            paises={paises}
            showactivar={false}
            tipo={1}
          />
        </>
      ) : (
        <Alert color="success" className="mb-0">
          {t('Su registro se realizó correctamente, puede cerrar esta ventana.')}
        </Alert>
      )}
    </>
  )
}

export default FormRegistroTerceros;
