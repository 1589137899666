import React, { FC, useEffect } from "react";
import { Alert, Button, ButtonGroup, Col, CustomInput, Row } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import { useRef } from "react";
import { useFirmaContext } from "../../../shared/contexts/firmaContext";
import { useState } from "react";
import ButtonCustom from "../../../shared/components/ButtonCustom";
import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import { useMainContext } from "../../../shared/contexts/mainContext";
import { useTranslation } from "react-i18next";
import { SyntheticInputEvent } from "react-number-format";

const FirmarForm: FC = () => {
  // Context
  const { setImagen, activeStep, setActiveStep } = useFirmaContext();
  const { handleNotification } = useMainContext();
  const { t } = useTranslation();

  // State
  const [upload, setUpload] = useState<boolean>(false);
  const [urlimage, setUrlimage] = useState<string>('');

  // Ref
  const sigPad = useRef<SignaturePad>(null);

  const handleClear = () => {
    sigPad.current?.clear();
    setImagen('');
    setUrlimage('');
  };

  const handleSign = () => {
    if (upload) {
      if (!urlimage) {
        handleNotification(t('Debe cargar una firma.'), mainTypeMsj.ERROR);
      } else {
        setImagen(urlimage);
        setActiveStep(activeStep + 1);
      }
    } else {
      if (sigPad.current?.isEmpty()) {
        handleNotification(t('Debe ingresar una firma.'), mainTypeMsj.ERROR);
      } else {
        setImagen(sigPad.current?.getCanvas().toDataURL('image/png') as string);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const resizeImage = (src, newX, newY) => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext("2d");
        if (ctx) {
          ctx.drawImage(img, 0, 0, newX, newY);
          const data = ctx.canvas.toDataURL();
          res(data);
        }
      };
      img.onerror = (error) => rej(error);
    });
  };

  const handleUploadFile = async (e: SyntheticInputEvent) => {
    if (e.target.files?.length) {
      try {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        const test = await resizeImage(base64, 500, 250);
        setUrlimage(test as string);
      } catch (err) {
        console.error(err);
        handleNotification(
          t('La imagen no tiene un formato válido.'),
          mainTypeMsj.ERROR
        );
      }
    }
  };

  // useEffect(() => {
  //   if (firma?.ultimafirma && !imagen) {
  //     setUpload(true);
  //     setUrlimage(firma?.ultimafirma);
  //   }
  // }, [firma]);

  return (
    <Row>
      <Col sm={12} md={12}>
        <ButtonGroup className="mb-3 w-100">
          <Button
            color="secondary"
            outline
            onClick={() => setUpload(false)}
            active={!upload}
            style={{ width: "50%" }}
            size="lg"
          >
            {t("Realizar firma")}
          </Button>
          <Button
            color="secondary"
            outline
            active={upload}
            onClick={() => setUpload(true)}
            style={{ width: "50%" }}
            size="lg"
          >
            {t('Cargar firma')}
          </Button>
        </ButtonGroup>
      </Col>
      {upload && (
        <>
          <Col sm={12} md={12}>
            <Alert color="info" className="mb-0">
              {t('Cargue la imagen con la firma a insertar en el documento.')}
              &nbsp;
              {t('Se recomienda que la imagen tenga un tamaño de 500x250 pixeles.')}
            </Alert>
            <hr />
            <div
              className="mb-3"
              style={{
                width: 500,
                margin: "0 auto",
              }}
            >
              <CustomInput
                type="file"
                id="file"
                name="file"
                label="Seleccione una imagen"
                accept=".jpg,.jpeg,.png"
                onChange={handleUploadFile}
                invalid={false}
              />
            </div>
          </Col>
          {urlimage && (
            <Col sm={12} md={12}>
              <div
                style={{
                  width: 500,
                  height: 250,
                  margin: "0 auto",
                  outline: "2px dotted #CCCCCC",
                  borderRadius: "5px",
                }}
              >
                <img src={urlimage} alt="Imagen firma" />
              </div>
            </Col>
          )}
        </>
      )}
      {!upload && (
        <>
          <Col sm={12} md={12}>
            <Alert color="info" className="mb-0">
              {t('Utilice el mouse para realizar la firma digital dentro del siguiente espacio.')}
            </Alert>
            <hr />
          </Col>
          <Col sm={12} md={12}>
            <div
              style={{
                width: 500,
                height: 250,
                margin: "0 auto",
                outline: "2px dotted #CCCCCC",
                borderRadius: "5px",
                cursor: "crosshair",
              }}
            >
              <SignaturePad
                canvasProps={{
                  width: 500,
                  height: 250,
                }}
                ref={sigPad}
              />
            </div>
          </Col>
        </>
      )}
      <Col sm={12} md={12}>
        <hr />
        <ButtonCustom
          className="d-flex align-items-center"
          type="button"
          color="link"
          loading={false}
          disabled={false}
          style={{ float: "left" }}
          onClick={handleClear}
        >
          {t('Volver a firmar')}
        </ButtonCustom>
        <ButtonCustom
          className="d-flex align-items-center"
          type="button"
          color="primary"
          loading={false}
          disabled={false}
          style={{ float: "right" }}
          onClick={handleSign}
        >
          {t('Continuar')}
        </ButtonCustom>
      </Col>
    </Row>
  );
};

export default FirmarForm;
