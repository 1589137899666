import React, { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { mainContext } from '../contexts';
import ButtonCustom from './ButtonCustom';

const ModalMsj: FC<IModalMsj> = ({ children, show, id, closeModal, action, title, form }) => {
  // Contextos
  const { mainState } = useContext(mainContext);
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={show}
    >
      {children ?? (
        <>
          <ModalBody>
            {title ?? t('¿Seguro desea eliminar el registro seleccionado?')}
            {form ? form : null}
          </ModalBody>
          <ModalFooter>
            <ButtonCustom
              form="formPais"
              className="d-flex align-items-center"
              color="primary"
              loading={mainState.loading}
              disabled={mainState.loading}
              onClick={() => action(id)}
            >
              {t('Aceptar')}
            </ButtonCustom>
            <ButtonCustom
              form="formPais"
              className="d-flex align-items-center"
              color="danger"
              disabled={mainState.loading}
              onClick={closeModal}
            >
              {t('Cerrar ventana')}
            </ButtonCustom>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
interface IModalMsj {
  show: boolean;
  id: number;
  closeModal: () => void;
  action: (id: number) => void;
  title?: string;
  form?: ReactNode | null;
}
export default ModalMsj;
