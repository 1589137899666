import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
registerLocale("es", es);

const OldDateField = (props) => {
  return (
    <DatePicker
      locale="es"
      {...props}
      {...props.field}
      autoComplete="off"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      // minDate={new Date()}
      onChangeRaw={(e: Event) => {
        e.preventDefault();
      }}
      onChange={(value: any) => {
        props.form.setFieldValue(props.field.name, value)
      }}
    />
  );
}

export default OldDateField;
