import React, { FC, useEffect } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { ICalendario, ICalendarioForm } from '../../../../core/models/precontractual/calendarioModel';
import UniqueSelectField from '../../../../shared/components/UniqueSelectField';
import { listActividades, listSiNo, listTipoFecha } from '../../../../core/services/listasDesplegables';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import DateField from '../../../../shared/components/DateField';
import RadioButtonField from '../../../../shared/components/RadioButtonField';

const FormCalendario: FC<IFormCalendario> = ({ onSubmit, record, actividades }) => {
  const { proceso } = usePrecontractualContext();
  const { t } = useTranslation();

  const habilitar = true;

  const listActividadesFiltradas = record ? listActividades : (
    listActividades.filter(act => !actividades.find(row => row.tipoactividad === act.value))
  );

  const initialValues: ICalendarioForm = {
    id: null,
    tipoactividad: 0,
    tipoinicio: 0,
    inicio: null,
    tipofin: 0,
    fin: null,
    primeractividad: 0,
    despuesde: 0,
    activo: 1,
    ...record,
    idprecontractual: proceso?.id || 0,
  } as ICalendarioForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    tipoactividad: Yup.number().min(1, FormValidation.required),
    tipoinicio: Yup.number().min(1, FormValidation.required),
    inicio: Yup.string().when('tipoinicio', {
      is: (tipoinicio) => tipoinicio === 1 || tipoinicio === 2,
      then: Yup.string().required(FormValidation.required),
      otherwise: Yup.string(),
    }).nullable(),
    tipofin: Yup.number().min(1, FormValidation.required),
    fin: Yup.string().when('tipofin', {
      is: (tipofin) => tipofin === 1 || tipofin === 2,
      then: Yup.string().required(FormValidation.required),
      otherwise: Yup.string(),
    }).nullable(),
    primeractividad: Yup.number().when('id', {
      is: (id) => !id,
      then: Yup.number().min(1, FormValidation.required),
      otherwise: Yup.number().nullable(),
    }),
    despuesde: Yup.number().when('primeractividad', {
      is: (primeractividad) => primeractividad === 2,
      then: Yup.number().required(FormValidation.required),
      otherwise: Yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (values.tipoinicio === 3) {
      setFieldValue('inicio', null);
    }
    if (values.tipofin === 3) {
      setFieldValue('fin', null);
    }
  }, [values])

  return (
    <Form id="FormCalendario" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <Label>{t('Actividades Disponibles')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="tipoactividad"
                  invalid={errors.tipoactividad && touched.tipoactividad}
                  options={listActividadesFiltradas}
                  disabled={listActividades.find(act => act.value === values.tipoactividad)?.default}
                />
                <FormFeedback>{errors.tipoactividad && t(errors.tipoactividad)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Tipo Inicio')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="tipoinicio"
                  invalid={errors.tipoinicio && touched.tipoinicio}
                  options={listTipoFecha}
                  disabled={!habilitar || listActividades.find(act => act.value === values.tipoactividad)?.default}
                />
                <FormFeedback>{errors.tipoinicio && t(errors.tipoinicio)}</FormFeedback>
              </FormGroup>
            </Col>
            {!!values.tipoinicio && values.tipoinicio !== 3 && (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Fecha Inicio')}</Label>
                  <Field
                    as={DateField}
                    name="inicio"
                    invalid={errors.inicio && touched.inicio}
                    disabled={!habilitar}
                    hora={values.tipoinicio === 2}
                  />
                  <FormFeedback>{errors.inicio && t(errors.inicio)}</FormFeedback>
                </FormGroup>
              </Col>
            )}
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Tipo Fin')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="tipofin"
                  invalid={errors.tipofin && touched.tipofin}
                  options={listTipoFecha}
                  disabled={!habilitar || listActividades.find(act => act.value === values.tipoactividad)?.default}
                />
                <FormFeedback>{errors.tipofin && t(errors.tipofin)}</FormFeedback>
              </FormGroup>
            </Col>
            {!!values.tipofin && values.tipofin !== 3 && (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Fecha Fin')}</Label>
                  <Field
                    as={DateField}
                    name="fin"
                    invalid={errors.fin && touched.fin}
                    disabled={!habilitar}
                    hora={values.tipofin === 2}
                  />
                  <FormFeedback>{errors.fin && t(errors.fin)}</FormFeedback>
                </FormGroup>
              </Col>
            )}
            {!values.id && (
              <>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label>{t('Agregar como Primera Actividad')}</Label>
                    <Field
                      as={RadioButtonField}
                      name="primeractividad"
                      invalid={errors.primeractividad && touched.primeractividad}
                      options={listSiNo}
                      disabled={!habilitar}
                    />
                    <FormFeedback>{errors.primeractividad && t(errors.primeractividad)}</FormFeedback>
                  </FormGroup>
                </Col>
                {values.primeractividad === 2 && (
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Agregar Actividad despues de')}</Label>
                      <Field
                        as={UniqueSelectField}
                        name="despuesde"
                        invalid={errors.despuesde && touched.despuesde}
                        options={actividades.map(row => ({
                          label: listActividades.find(act => act.value === row.tipoactividad)?.label || t('Ninguna'),
                          value: row.id,
                        }))}
                        disabled={!habilitar}
                      />
                      <FormFeedback>{errors.despuesde && t(errors.despuesde)}</FormFeedback>
                    </FormGroup>
                  </Col>
                )}
              </>
            )}
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormCalendario"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormCalendario {
  record: ICalendario | null;
  onSubmit: (values: ICalendarioForm) => Promise<void>;
  actividades: ICalendario[];
}

export default FormCalendario;
