import { FC, lazy, ReactElement } from 'react';

// Componentes
import Dashboard from '../shared/layouts/Dashboard';
// import Auth from '../shared/layouts/Auth';
// import SignIn from '../pages/auth/SignIn';
import Home from '../pages/home';
import ReporteTerceros from '../pages/reportes/terceros';
import { EGroups, EGroupsNames } from '../shared/utilities';
import ReporteBIUsuarios from "../pages/reportes/reportes-bi-usuarios";

// Lazy componentes
const Paises = lazy(() => import('../pages/administracion/paises'));
const Departamentos = lazy(() => import('../pages/administracion/departamentos'));
const Ciudades = lazy(() => import('../pages/administracion/ciudades'));
const CategoriasContrato = lazy(() => import('../pages/administracion/categoriasContrato'));
const TiposContrato = lazy(() => import('../pages/administracion/tiposContrato'));
const Filiales = lazy(() => import('../pages/administracion/filiales'));
const CondicionesPago = lazy(() => import('../pages/administracion/condicionesPago'));
const CentroCostos = lazy(() => import('../pages/administracion/centroCostos'));
const Plantillas = lazy(() => import('../pages/administracion/plantillas'));
const RolesFirma = lazy(() => import('../pages/administracion/rolesFirma'));
const Estados = lazy(() => import('../pages/administracion/estados'));
const Empresas = lazy(() => import('../pages/administracion/empresas'));
const Terceros = lazy(() => import('../pages/administracion/terceros'));
const Vicepresidencias = lazy(() => import('../pages/administracion/vicepresidencias'));
const Gerencias = lazy(() => import('../pages/administracion/gerencias'));
const Areas = lazy(() => import('../pages/administracion/areas'));
const Usuarios = lazy(() => import('../pages/usuarios'));
const ListContractual = lazy(() => import('../pages/contractual/list'));
const CreateContractual = lazy(() => import('../pages/contractual/create'));
const EditContractual = lazy(() => import('../pages/contractual/edit'));
const ListPostcontractual = lazy(() => import('../pages/postcontractual/list'));
const CreatePostcontractual = lazy(() => import('../pages/postcontractual/create'));
const EditPostcontractual = lazy(() => import('../pages/postcontractual/edit'));
const ListAsesorias = lazy(() => import('../pages/asesorias/list'));
const CreateAsesorias = lazy(() => import('../pages/asesorias/create'));
const EditAsesorias = lazy(() => import('../pages/asesorias/edit'));
const DetailTercero = lazy(() => import('../pages/administracion/terceros/detail'));
const KpisTercero = lazy(() => import('../pages/administracion/terceros/kpis'));
const ContratoTercero = lazy(() => import('../pages/administracion/terceros/contrato'));
const ReporteContractual = lazy(() => import('../pages/reportes/contractual'));
const ReportePostcontractual = lazy(() => import('../pages/reportes/postcontractual'));
const Modulos = lazy(() => import('../pages/usuarios/modulos'));
const Roles = lazy(() => import('../pages/usuarios/roles'));
const Cargos = lazy(() => import('../pages/administracion/cargos'));
const CreatePrecontractual = lazy(() => import('../pages/precontractual/create'));
const EditPrecontractual = lazy(() => import('../pages/precontractual/edit'));
const ListPrecontractual = lazy(() => import('../pages/precontractual/list'));

const ListJuridico = lazy(() => import('../pages/juridico/list'));
const CreateJuridico = lazy(() => import('../pages/juridico/create'));
const EditJuridico = lazy(() => import('../pages/juridico/edit'));


// const authRoutes: IRoutes = {
//   path: '/login',
//   name: 'Login',
//   requireAuth: false,
//   layout: Auth,
//   component: SignIn,
// };

// const thirdRoutes: IRoutes = {
//   path: '/tercero/firma/:modulo/:token',
//   name: 'Aprobación',
//   requireAuth: false,
//   layout: Third,
//   component: FirmaTercero,
// };

const ListPostcontractualRoute: IRoutes = {
  path: '/postcontractual/list',
  name: 'Postcontractual',
  component: ListPostcontractual,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.SOLICITANTE,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.FIRMANTE,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
    EGroupsNames.JEFEAREA,
    EGroupsNames.ASAMBLEA,
    EGroupsNames.CONCEJO,
    EGroupsNames.DESARROLLOINSTITUCIONAL,
    EGroupsNames.SECRETARIA_JURIDICA,
    EGroupsNames.DIRECTOR
  ],
  layout: Dashboard,
  icon: 'uil-file-info-alt',
  requireAuth: true,
};

const CreatePostcontractualRoute: IRoutes = {
  path: '/postcontractual/create',
  name: 'Crear proceso postcontractual',
  component: CreatePostcontractual,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.ABOGADO,
  ],
  layout: Dashboard,
  requireAuth: true,
};

const EditPostcontractualRoute: IRoutes = {
  path: '/postcontractual/edit/:id',
  name: 'Modificar proceso postcontractual',
  component: EditPostcontractual,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.SOLICITANTE,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.FIRMANTE,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
    EGroupsNames.ASAMBLEA,
    EGroupsNames.CONCEJO,
    EGroupsNames.SECRETARIA_JURIDICA,
  ],
  layout: Dashboard,
  requireAuth: true,
};

const ListAsesoriasRoute: IRoutes = {
  path: '/asesorias/list',
  name: 'Asesorías',
  component: ListAsesorias,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.SOLICITANTE,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.FIRMANTE,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
    EGroupsNames.DESARROLLOINSTITUCIONAL,
    EGroupsNames.JEFE_DE_DPTO_JURIDICO
  ],
  layout: Dashboard,
  icon: 'uil-comment-alt-dots',
  requireAuth: true,
};

const CreateAsesoriasRoute: IRoutes = {
  path: '/asesorias/create',
  name: 'Crear asesoría',
  component: CreateAsesorias,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.JEFE_DE_DPTO_JURIDICO
  ],
  layout: Dashboard,
  requireAuth: true,
};

const EditAsesoriasRoute: IRoutes = {
  path: '/asesorias/edit/:id',
  name: 'Modificar asesoría',
  component: EditAsesorias,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.SOLICITANTE,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.FIRMANTE,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
    EGroupsNames.JEFE_DE_DPTO_JURIDICO
  ],
  layout: Dashboard,
  requireAuth: true,
};

const DetailTerceroRoute: IRoutes = {
  path: '/terceros/detail/:id',
  name: 'Detalle de tercero',
  component: DetailTercero,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
  ],
  layout: Dashboard,
  requireAuth: true,
};

const KpisTerceroRoute: IRoutes = {
  path: '/terceros/kpis/:id',
  name: 'KPIs de tercero',
  component: KpisTercero,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
  ],
  layout: Dashboard,
  requireAuth: true,
};

const ContratoTerceroRoute: IRoutes = {
  path: '/terceros/contrato/:id',
  name: 'Contrato de tercero',
  component: ContratoTercero,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
  ],
  layout: Dashboard,
  requireAuth: true,
};

const precontractualRoutes: IRoutes[] = [
  {
    path: '/precontractual/create',
    name: 'Crear Proceso Precontractual',
    icon: 'uil-home-alt',
    component: CreatePrecontractual,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/precontractual/edit/:id',
    name: 'Proceso Precontractual',
    icon: 'uil-home-alt',
    component: EditPrecontractual,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
    ],
    layout: Dashboard,
    requireAuth: true,
  }
];

const contractualRoutes: IRoutes[] = [
  {
    path: '/contractual/create',
    name: 'Crear Proceso',
    icon: 'uil-home-alt',
    component: CreateContractual,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL,
      EGroupsNames.ASAMBLEA,
      EGroupsNames.CONCEJO,
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/contractual/edit/:id',
    name: 'Proceso Contractual',
    icon: 'uil-home-alt',
    component: EditContractual,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL,
      EGroupsNames.ASAMBLEA,
      EGroupsNames.CONCEJO,
    ],
    layout: Dashboard,
    requireAuth: true,
  }
];

const reportsBIRoutes: IRoutes = {
  path: "/reportes-bi",
  header: "Comforce® Reports",
  name: "Comforce® Reports",
  grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
  icon: "uil-file-download",
  children: [
    {
      path: "/reportes-bi/usuarios",
      name: "Usuarios",
      component: ReporteBIUsuarios,
      grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
      layout: Dashboard,
      requireAuth: true,
    },
  ],
};

const reportRoutes: IRoutes = {
  path: "/reportes",
  header: "Reportes",
  name: "Generales",
  grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
  icon: "uil-file-download",
  children: [
    {
      path: "/reportes/contractual",
      name: "Contractual",
      component: ReporteContractual,
      grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: "/reportes/postcontractual",
      name: "Postcontractual",
      component: ReportePostcontractual,
      grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: "/reportes/terceros",
      name: "Terceros",
      component: ReporteTerceros,
      grupos: [EGroupsNames.SUPERADMIN, EGroupsNames.ADMINISTRADOR],
      layout: Dashboard,
      requireAuth: true,
    },
    reportsBIRoutes,
  ],
};

const ListJuridicoRoute: IRoutes = {
  path: '/juridico/list',
  name: 'Procesos jurídicos',
  component: ListJuridico,
  grupos: [
    EGroupsNames.SUPERADMIN,
    EGroupsNames.ADMINISTRADOR,
    EGroupsNames.SOLICITANTE,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.FIRMANTE,
    EGroupsNames.APROBADOR,
    EGroupsNames.AUDITOR,
    EGroupsNames.DESARROLLOINSTITUCIONAL,
    EGroupsNames.AUXILIAR_ADMINISTRATIVO_DPTO_JURIDICO
  ],
  layout: Dashboard,
  icon: 'uil-file-info-alt',
  requireAuth: true,
};

const juridicoRoutes: IRoutes[] = [
  {
    path: '/juridico/create',
    name: 'Crear proceso juríduco',
    component: CreateJuridico,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.COORDINADOR,
      EGroupsNames.AUXILIAR_ADMINISTRATIVO_DPTO_JURIDICO
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/juridico/edit/:id',
    name: 'Proceso Jurídico',
    icon: 'uil-home-alt',
    component: EditJuridico,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.AUXILIAR_ADMINISTRATIVO_DPTO_JURIDICO
    ],
    layout: Dashboard,
    requireAuth: true,
  }
];


const menuRoutes: IRoutes[] = [
  {
    path: '/home',
    name: 'Inicio',
    header: 'Principal',
    icon: 'uil-home-alt',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL,
      EGroupsNames.JEFE_DE_DPTO_JURIDICO,
      EGroupsNames.DESARROLLOINSTITUCIONAL,
      EGroupsNames.ASAMBLEA,
      EGroupsNames.CONCEJO,
      EGroupsNames.DIRECTOR,
      EGroupsNames.AUXILIAR_ADMINISTRATIVO_DPTO_JURIDICO,
      EGroupsNames.SECRETARIA_JURIDICA,
    ],
    component: Home,
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/precontractual/list',
    name: 'Precontractual',
    header: 'Procesos',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL
    ],
    component: ListPrecontractual,
    layout: Dashboard,
    icon: 'uil-book',
    requireAuth: true,
  },
  {
    path: '/contractual/list',
    name: 'Contractual',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
      EGroupsNames.SOLICITANTE,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.FIRMANTE,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
      EGroupsNames.DESARROLLOINSTITUCIONAL
    ],
    component: ListContractual,
    layout: Dashboard,
    icon: 'uil-file-alt',
    requireAuth: true,
  },
  ListPostcontractualRoute,
  ListJuridicoRoute,
  ListAsesoriasRoute,
  {
    path: '/estructura-org',
    name: 'Estructura Organizacional',
    requireAuth: true,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    header: 'Administración',
    icon: 'uil-building',
    children: [
      {
        name: 'Filial',
        path: '/estructura-org/filial/',
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        requireAuth: true,
        children: [{
          path: '/estructura-org/filial/list',
          name: 'Lista de filiales',
          component: Filiales,
          grupos: [
            EGroupsNames.SUPERADMIN,
            EGroupsNames.ADMINISTRADOR,
          ],
          layout: Dashboard,
          requireAuth: true,
        },]
      },
      {
        name: 'Empresa',
        path: '/estructura-org/empresa/',
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        requireAuth: true,
        children: [
          {
            path: '/estructura-org/empresa/list',
            name: 'Lista de empresas',
            component: Empresas,
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            layout: Dashboard,
            requireAuth: true,
          }
        ]
      },
      {
        name: 'Vicepresidencia',
        path: '/estructura-org/vicepresidencia/',
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        requireAuth: true,
        children: [
          {
            path: '/estructura-org/vicepresidencia/list',
            name: 'Lista de vicepresidencias',
            component: Vicepresidencias,
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            layout: Dashboard,
            requireAuth: true,
          },
          {
            name: 'Gerencia',
            path: '/estructura-org/vicepresidencia/gerencia',
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            layout: Dashboard,
            requireAuth: true,
            children: [
              {
                path: '/estructura-org/vicepresidencia/gerencia/list',
                name: 'Lista de gerencias',
                component: Gerencias,
                grupos: [
                  EGroupsNames.SUPERADMIN,
                  EGroupsNames.ADMINISTRADOR,
                ],
                layout: Dashboard,
                requireAuth: true,
              },
              {
                name: 'Área',
                path: '/estructura-org/vicepresidencia/gerencia/area',
                grupos: [
                  EGroupsNames.SUPERADMIN,
                  EGroupsNames.ADMINISTRADOR,
                ],
                requireAuth: true,
                children: [
                  {
                    path: '/estructura-org/vicepresidencia/gerencia/area/list',
                    name: 'Lista de áreas',
                    component: Areas,
                    grupos: [
                      EGroupsNames.SUPERADMIN,
                      EGroupsNames.ADMINISTRADOR,
                    ],
                    layout: Dashboard,
                    requireAuth: true,
                  },
                ]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    name: 'Estructura geográfica',
    path: '/estructura-geo',
    icon: 'uil-map',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    requireAuth: true,
    children: [
      {
        name: 'País',
        path: '/estructura-geo/pais',
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        requireAuth: true,
        children: [
          {
            name: 'Lista de paises',
            path: '/estructura-geo/pais/list',
            component: Paises,
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            layout: Dashboard,
            requireAuth: true,
          },
          {
            name: 'Departamento',
            path: '/estructura-geo/pais/departamento',
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            requireAuth: true,
            children: [
              {
                path: '/estructura-geo/pais/departamento/list',
                name: 'Lista de departamentos',
                component: Departamentos,
                grupos: [
                  EGroupsNames.SUPERADMIN,
                  EGroupsNames.ADMINISTRADOR,
                ],
                layout: Dashboard,
                requireAuth: true,
              },
              {
                name: 'Ciudad',
                path: '/estructura-geo/pais/departamento/ciudad',
                grupos: [
                  EGroupsNames.SUPERADMIN,
                  EGroupsNames.ADMINISTRADOR,
                ],
                requireAuth: true,
                children: [
                  {
                    path: '/estructura-geo/pais/departamento/ciudad/list',
                    name: 'Lista de ciudades',
                    component: Ciudades,
                    grupos: [
                      EGroupsNames.SUPERADMIN,
                      EGroupsNames.ADMINISTRADOR,
                    ],
                    layout: Dashboard,
                    requireAuth: true,
                  },
                ]
              },
            ]
          }]
      },
    ]
  },
  {
    name: 'Categoría de Contrato',
    path: '/contratos/categorias-contrato',
    icon: 'uil-archive',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    requireAuth: true,
    children: [
      {
        path: '/contratos/categorias-contrato/list',
        name: 'Lista de categorías',
        component: CategoriasContrato,
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        layout: Dashboard,
        requireAuth: true,
      },
      {
        name: 'Tipo de Contrato',
        path: '/contratos/categorias-contrato/tipo-contrato',
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        requireAuth: true,
        children: [
          {
            path: '/contratos/categorias-contrato/tipo-contrato/list',
            name: 'Lista de tipos',
            component: TiposContrato,
            grupos: [
              EGroupsNames.SUPERADMIN,
              EGroupsNames.ADMINISTRADOR,
            ],
            layout: Dashboard,
            requireAuth: true,
          },
        ]
      },
    ]
  },
  {
    name: 'Otros',
    path: '/otros',
    requireAuth: true,
    icon: 'uil-cog',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    children: [
      {
        path: '/otros/condiciones-pago',
        name: 'Condiciones de pago',
        component: CondicionesPago,
        grupos: [
          EGroupsNames.SUPERADMIN,
          EGroupsNames.ADMINISTRADOR,
        ],
        layout: Dashboard,
        requireAuth: true,
      },
      {
        path: '/otros/estados',
        name: 'Estados',
        component: Estados,
        grupos: [
          EGroupsNames.SUPERADMIN,
        ],
        layout: Dashboard,
        requireAuth: true,
      },
    ]
  },
  {
    path: '/centro-costos',
    name: 'Centro de costos',
    component: CentroCostos,
    icon: 'uil-box',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/plantillas',
    name: 'Plantillas',
    component: Plantillas,
    icon: 'uil-file-copy-alt',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/rolesFirma',
    name: 'Roles Firma',
    component: RolesFirma,
    icon: 'uil-th',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    layout: Dashboard,
    requireAuth: true,
  },
  // ]
  reportRoutes,
  {
    path: '/terceros',
    name: 'Terceros',
    header: 'Gestión de terceros',
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    icon: 'uil-briefcase',
    component: Terceros,
    layout: Dashboard,
    requireAuth: true,
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    header: 'Gestión de usuarios',
    component: Usuarios,
    grupos: [
      EGroupsNames.SUPERADMIN,
      EGroupsNames.ADMINISTRADOR,
    ],
    layout: Dashboard,
    icon: 'uil-users-alt',
    requireAuth: true,
  },
  {
    path: '/modulos',
    name: 'Módulos',
    component: Modulos,
    grupos: [
      EGroupsNames.SUPERADMIN,
    ],
    layout: Dashboard,
    icon: 'uil-folder-exclamation',
    requireAuth: true,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    grupos: [
      EGroupsNames.SUPERADMIN,
    ],
    layout: Dashboard,
    icon: 'uil-user-exclamation',
    requireAuth: true,
  },
  {
    path: '/cargos',
    name: 'Cargos',
    component: Cargos,
    grupos: [
      EGroupsNames.SUPERADMIN,
    ],
    layout: Dashboard,
    icon: 'uil-cloud-database-tree',
    requireAuth: true,
  },
];

export const dashboardRoutes = [
  ...menuRoutes,
];

export const routes: IRoutes[] = [
  // authRoutes,
  // thirdRoutes,
  ...menuRoutes,
  ...contractualRoutes,
  ...juridicoRoutes,
  ...precontractualRoutes,
  CreatePostcontractualRoute,
  EditPostcontractualRoute,
  CreateAsesoriasRoute,
  EditAsesoriasRoute,
  DetailTerceroRoute,
  KpisTerceroRoute,
  ContratoTerceroRoute
];

// interfaces de las rutas
export interface IRoutes {
  path?: string;
  name: string;
  header?: string;
  layout?: any;
  component?: any;
  requireAuth?: boolean;
  icon?: string;
  grupos?: string[];
  children?: this[];
};
export interface IRenderRoutes {
  routes: IRoutes[]
};
