import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ICriterios, ICriteriosForm } from '../../models/precontractual/criteriosModel';

/**
 * Permite obtener los registros en la bd
 */
export const listarCriterios = async (params?): Promise<ICriterios[]> => {
  let data: ICriterios[] = [];
  try {
    const resp = await getRequest(urlApi.PRE_CRITERIOS.LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarCriterios = async (id: number): Promise<ICriterios | null> => {
  let data: ICriterios | null;
  try {
    const resp = await getRequest(`${urlApi.PRE_CRITERIOS.SHOW}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarCriterios = async (
  values: ICriteriosForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.PRE_CRITERIOS.CREATE : `${urlApi.PRE_CRITERIOS.EDIT}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarCriterios = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.PRE_CRITERIOS.DELETE}${id}`;
  return await deleteItem(url, notification);
};
