import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ICargarVersiones, IVersiones } from '../../models/documentos/versionesModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerVersiones = async (params?): Promise<IVersiones[]> => {
  let data: IVersiones[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_VERSIONES, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarVersiones = async (
  values: IVersiones,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_VERSIONES : `${urlApi.EDIT_VERSIONES}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarVersiones = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_VERSIONES}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Genera url para edición de DOCX con Zoho
 * @param id identificador del documento
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const editarZohoUrl = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<string|false> => {
  const url = `${urlApi.EDIT_ZOHO_URL_VERSIONES}${id}`
  const resp = await updateItem(url, {}, notification, false, false, true);
  const data = resp?.data?.data;
  return data?.document_url || false;
};

/**
 * Permite crear un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const cargarArchivo = async (
  values: ICargarVersiones,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.UPLOAD_FILE_VERSIONES
  const resp = await postRequestForm(url, values);
  if (resp?.data?.code === 200) {
    notification(resp?.data?.message, mainTypeMsj.SUCCESS);
  } else {
    notification(resp?.data?.message, mainTypeMsj.ERROR);
  }
  return resp?.data?.code === 200;
};
