export enum userTypeActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  THIRD = 'THIRD',
};

export enum notificationTypeAction {
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
};

export enum mainTypeActions {
  HEADER = 'HEADER',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  CHANGE_LOADING = 'CHANGE_LOADING',
  TRUE_LOADING = 'TRUE_LOADING',
  FALSE_LOADING = 'FALSE_LOADING',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
}

export enum postcontractualTypeActions {
  ID = 'ID',
  PROCESO = 'PROCESO',
  POST_USER = 'POST_USER',
  REQUERIMIENTOS = 'REQUERIMIENTOS',
  USUARIOS = 'USUARIOS',
  TAREAS = 'TAREAS',
  DOCUMENTOS = 'DOCUMENTOS',
  ROLES = 'ROLES',
  APROBACIONES = 'APROBACIONES',
  STATE = 'STATE',
};

export enum contractualTypeActions {
  ID = 'ID',
  PROCESO = 'PROCESO',
  POST_USER = 'POST_USER',
  REQUERIMIENTOS = 'REQUERIMIENTOS',
  USUARIOS = 'USUARIOS',
  TAREAS = 'TAREAS',
  DOCUMENTOS = 'DOCUMENTOS',
  ROLES = 'ROLES',
  APROBACIONES = 'APROBACIONES',
  STATE = 'STATE',
};

export { UserProvider, userContext } from './userContext';
export { MainProvider, mainContext } from './mainContext';
export { ContractualProvider, contractualContext } from './contractualContext';
export { userLogin } from './actions/userActions';
