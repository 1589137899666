import { getRequest, updateItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import {useUpdateRequest} from "../../../shared/hooks/useUpdate";

// /**
//  * Permite obtener los registros en la bd
//  */
// export const obtenerProcesos = async (): Promise<IContractual[]> => {
//   let data: IContractual[] = [];
//   try {
//     const resp = await getRequest(urlApi.LIST_CONTRACTUAL);
//     data = resp.data.records;
//   } catch (error) {
//     console.error(error);
//   }
//   return data;
// };

// /**
//  * Permite obtener el registro por id en la bd
//  */
export const obtenerProceso = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_JURIDICO}${id}`);
    data = resp.data.record;

    if (resp?.data?.polizas) {
      data = {
        ...resp.data.record,
      }
    }
    if (resp?.data?.pathpdf) {
      data = {
        ...resp.data.record,
      }
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerUsuariosJuridico = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_JURIDICO_USUARIOS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

// export const obtenerStep2 = async (): Promise<IFormContra2[]> => {
//   let data: IFormContra2[] = [];
//   try {
//     const resp = await getRequest(urlApi.LIST_CONTRACTUAL);
//     data = resp.data.records;
//   } catch (error) {
//     console.error(error);
//   }
//   return data;
// };

// export const obtenerStep3 = async (): Promise<IFormContra3[]> => {
//   let data: IFormContra3[] = [];
//   try {
//     const resp = await getRequest(urlApi.LIST_CONTRACTUAL);
//     data = resp.data.records;
//   } catch (error) {
//     console.error(error);
//   }
//   return data;
// };

/**
 * Permite cambiar el estado de un proceso
 * @param values valores con rol que edita
 * @param notification funcion que permite generar una notificacion en el sistema 
 */

export const guardarJuridico = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = (values?.id || values?.json?.id) ? `${urlApi.EDIT_JURIDICO}${(values?.id || values?.json?.id)}` : `${urlApi.CREATE_JURIDICO}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const cambiarEstado = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = `${urlApi.STATES_JURIDICO}${values?.id}`;
  return await updateItem(url, values, notification, false, sendNotification, requestData);
};

/**
 * Permite obtener los registros en la bd
 */
 export const obtenerDocumentos = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_JURIDICO_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
 export const obtenerTareas = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_TAREA_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};


export const obtenerProcesosJuridicos = async (): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_JURIDICO);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerNovedad = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_JURIDICO_NOVEDADES}${id}`);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerNovedadPorProceso = async (param: any): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_PROCESO_JURIDICO_NOVEDADES}`, param);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const guardarNovedad = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = (values?.id || values?.json?.id) ? `${urlApi.EDIT_JURIDICO_NOVEDADES}${(values?.id || values?.json?.id)}` : `${urlApi.CREATE_JURIDICO_NOVEDADES}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const obtenerNovedades = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_JURIDICO_NOVEDADES;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const asignarUsuario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = (values?.id || values?.json?.id) ? `${urlApi.EDIT_JURIDICO_USUARIOS}${(values?.id || values?.json?.id)}` : `${urlApi.CREAR_JURIDICO_USUARIOS}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const obtenerUsuario = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_JURIDICO_USUARIOS}${id}`);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};


export const useObtenerProcesosDinamicosJuridicos = (
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetJuridicos = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: urlApi.DATATABLE_JURIDICO,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };

  return {
    handleGetJuridicos,
  };
};

export const obtenerListaFiltros = async () => {
  let data  = [];
  try {
    const resp = await getRequest(urlApi.LIST_FILTRO_JURIDICO);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
}

export const crearObservacion = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_OBSERVACION;
  return await updateItem(url, values, notification);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerObservaciones = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_OBSERVACION;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

