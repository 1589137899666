import React, { FC } from 'react';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { useOptionsTables } from '../../../shared/hooks/useOptionsTables';
import moment from 'moment';
import { diasDiff, formatStringtoDateString } from '../../../shared/helpers/Fecha';

const ListComponent: FC<IListComponent> = ({ columns, procesos, currenttime }) => {
  // Contextos
  const { optionsDataTable } = useOptionsTables();

 

  return (
    <MUIDataTable
      title={""}
      data={procesos?.map(proceso => ([
        [proceso.consecutivo, proceso.id],
        formatStringtoDateString(proceso?.final),
        diasDiff((Date.parse(proceso?.final) / 1000), currenttime)
      ]))}
      columns={columns}
      options={optionsDataTable()}
    />
  );
};
interface IListComponent {
  columns: MUIDataTableColumn[],
  procesos: any,
  currenttime: any,
};
export default ListComponent;
