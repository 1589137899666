import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { GroupedOptionsType, OptionTypeBase, OptionsType } from 'react-select';

const UniqueSelectField: FC<IUniqueSelectField> = ({ 
  invalid = false,
  disabled = false,
  options,
  ...props
}) => {
  const { setFieldValue, getFieldProps, setFieldTouched } = useFormikContext();
  const { t } = useTranslation();

  const getValue = () => {
    const value = getFieldProps(props.name)?.value;
    const option = options.find(opt => opt.value === value);
    return option ? {
      label: option.label,
      value: option.value,
    } : value;
  }
  
  return (
    <Select
      closeMenuOnSelect={true}
      defaultValue={getFieldProps(props.name)?.value}
      isMulti={false}
      isSearchable
      placeholder={t('Seleccione')}
      noOptionsMessage={() => t('No hay opciones')}
      classNamePrefix="select-field"
      className={invalid ? "multi-select-field is-invalid" : "multi-select-field"}
      options={options}
      isClearable
      {...props}
      isDisabled={disabled}
      onChange={(e) => {
        setFieldValue(props.name, (e?.value || 0));
      }}
      onBlur={() => {
        setFieldTouched(props.name, true);
      }}
      value={getValue()}
    />
  )
}

interface IUniqueSelectField {
  field: any;
  options: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
  id?: string;
}

export default UniqueSelectField;