import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Button, PopoverBody, Spinner, Table, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarDocumentosRequeridos, guardarDocumentosRequeridos, listarDocumentosRequeridos } from '../../../../core/services/precontractual/documentosRequeridosService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { IDocumentosRequeridos, IDocumentosRequeridosForm } from '../../../../core/models/precontractual/documentosRequeridosModel';
import { extensionDocumentoRequerido, nombreDocRequerido } from '../../../../shared/helpers/precontractual';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { EEstadosPre, IBorrarRegistro } from '../../../../shared/utilities';
import FormDocsRequeridos from '../Forms/FormDocsRequeridos';
import ModalMsj from '../../../../shared/components/ModalMsj';
import { handleVerDocumentoAnexo } from '../../../../shared/helpers/generales';

const TablaDocsRequeridos: FC<ITablaDocsRequeridos> = ({ tipo }) => {
  const { t } = useTranslation();
  const { mainState, mainDispatch, handleNotification, documentosTable } = useMainContext();
  const { estado, proceso, roles } = usePrecontractualContext();

  const [loading, setLoading] = useState(true);
  const [docsRequeridos, setDocsRequeridos] = useState<IDocumentosRequeridos[]>([]);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const habilitar = useMemo(() => {
    if (estado === EEstadosPre.DOCUMENTOS_SOLICITUD && roles.solicitante) {
      return true;
    } else if (estado === EEstadosPre.VERIFICACION_ADENDAS) {
      return proceso?.requiereadenda === 1 && proceso?.subestado === 2;
    } else {
      return false;
    }
  }, [proceso, estado, roles]);

  const listarDocumentos = async () => {
    const documentosRequeridos = await listarDocumentosRequeridos({
      filter: 'idprecontractual',
      valuefilter: proceso?.id,
    });
    if (tipo) {
      setDocsRequeridos(documentosRequeridos.filter(item => item.idanexo));
    } else {
      setDocsRequeridos(documentosRequeridos);
    }
    setLoading(false);
  }


  const onSubmit = async (values: IDocumentosRequeridosForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarDocumentosRequeridos(values, handleNotification);
    if (guardar) {
      documentosTable.refresh();
      await listarDocumentos();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarDocumentosRequeridos(id, handleNotification)) {
      await listarDocumentos();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Agregar Documento'),
        body: () => (
          <FormDocsRequeridos
            onSubmit={onSubmit}
            record={null}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = docsRequeridos.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar documento'),
        body: () => (record ? (
          <FormDocsRequeridos
            onSubmit={onSubmit}
            record={record}
          />
         ) : null
        )
      }
    }))
  };

  useEffect(() => {
    listarDocumentos();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando documentos...')}
          </span>
        </div>
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th align="center">{t('Nombre del Documento')}</th>
                <th align="center">{t('Tipo de Documento')}</th>
                <th align="center">{t('Obligatorio')}</th>
                <th align="center">{t('Acción')}</th>
              </tr>
            </thead>
            <tbody>
              {docsRequeridos.map(row => (
                <tr key={row.id}>
                  <td align="center">
                    {nombreDocRequerido(row)}
                    {row.requerido === 1 && !row.idanexo && (
                      <>
                        <UncontrolledPopover
                          placement="top"
                          target={`doc-req-${row.id}`}
                          trigger="hover"
                        >
                          <PopoverBody>
                            {t('No se ha cargado el documento')}
                          </PopoverBody>
                        </UncontrolledPopover>
                        <span className="ml-1">
                          <i className="uil-bell text-danger cursor-pointer" id={`doc-req-${row.id}`}></i>
                        </span>
                      </>
                    )}
                  </td>
                  <td align="center">{extensionDocumentoRequerido(row)}</td>
                  <td align="center">
                    {row.requerido === 1 ? t('Si') : t('No')}
                  </td>
                  <td align="center">
                    {!!row.idanexo && (
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleVerDocumentoAnexo(row.idanexo)}
                        size="sm"
                        outline
                        title={t('Ver Documento')}
                      >
                        <i className={tipo ? "uil-mr uil-eye" : "uil-eye"}></i>
                        {!!tipo && t('Descargar Documento')}
                      </Button>
                    )}
                    {habilitar && (
                      <>
                        <Button
                          color="primary"
                          className="mr-1"
                          onClick={() => handleEdit(row.id || 0)}
                          size="sm"
                          outline
                        >
                          <i className="uil-plus"></i> {t('Cargar Documento')}
                        </Button>
                        {!row.tipodocumento && (
                          <Button
                            color="danger"
                            className="mr-1"
                            onClick={() => changeModal(row.id || 0)}
                            size="sm"
                            outline
                            title={t('Eliminar')}
                          >
                            <i className="uil-trash-alt"></i>
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {docsRequeridos.length === 0 && (
            <Alert color="warning">{t('No se han agregado documentos requeridos.')}</Alert>
          )}
          {habilitar && (
            <ButtonCustom
              color="info"
              onClick={handleAdd}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-plus"></i>{t('Cargue Documentos Adicionales')}
            </ButtonCustom>
          )}
        </>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

interface ITablaDocsRequeridos {
  tipo?: number;
}

export default TablaDocsRequeridos;
