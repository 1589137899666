import React, { FC } from 'react';
import { IProveedores } from '../../../../core/models/precontractual/proveedoresModel';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'reactstrap';
import { handleVerDocumento, handleVerDocumentoAnexo } from '../../../../shared/helpers/generales';

const TablaDocsRenegociacion: FC<ITablaDocsRenegociacion> = ({ record }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th align="center">{t('Nombre del Documento')}</th>
          <th align="center">{t('Tipo de Documento')}</th>
          <th align="center">{t('Obligatorio')}</th>
          <th align="center">{t('Acción')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            {t(record.iddocnegociacion?.idcategoria?.nombre ?? '')}
          </td>
          <td align="center">
            {t(record.iddocnegociacion?.extension?.toUpperCase() ?? '')}
          </td>
          <td align="center">
            {t('Si')}
          </td>
          <td align="center">
            {!!record.iddocnegociacion && (
              <Button
                color="primary"
                className="mr-1"
                onClick={() => handleVerDocumentoAnexo(record.iddocnegociacion)}
                size="sm"
                outline
              >
                <i className="uil-mr uil-eye"></i>
                {t('Descargar Documento')}
              </Button>
            )}
          </td>
          
        </tr>
      </tbody>
    </Table>
  );
}

interface ITablaDocsRenegociacion {
  record: IProveedores;
}

export default TablaDocsRenegociacion;
