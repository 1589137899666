import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import ModalsFooter from '../../../shared/components/ModalsFooter';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { closeModal, falseLoading, trueLoading } from '../../../shared/contexts/actions/mainActions';
import { ICargaMasiva, ICargaMasivaForm } from '../../../core/models/documentos/cargaMasivaModel';
import { guardarCargaMasiva } from '../../../core/services/documentos/cargaMasivaService';
import UniqueFileField from '../../../shared/components/UniqueFileField';
import { downloadFile } from '../../../shared/helpers/generales';

const FormCargaMasiva: FC<IFormCargaMasiva> = ({
  callback,
  record,
  entidad,
  identidad,
  nombrecarga,
  plantilla,
}) => {
  const { mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();

  const habilitar = true;

  const initialValues: ICargaMasivaForm = {
    id: null,
    entidad,
    identidad,
    nombrecarga,
    activo: 1,
    ...record,
    idanexo: record?.idanexo?.id || 0,
    autor: record?.autor?.id || 0,
  } as ICargaMasivaForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    idanexo: Yup.number().min(1, FormValidation.required),
  });

  const onSubmit = async (values: ICargaMasivaForm) => {
    mainDispatch(trueLoading());
    const record = await guardarCargaMasiva(values, handleNotification);
    if (record) {
      if (callback) {
        callback(record);
      }
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormCargaMasiva" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            {!!plantilla && (
              <Col sm={12} md={12}>
                <Alert color="info">
                  <Button color="link"
                    onClick={() => {
                      downloadFile(plantilla, `plantilla-${nombrecarga}.xlsx`);
                    }}
                  >
                    {t('Descargar Plantilla')}
                  </Button>
                </Alert>
              </Col>
            )}
            <Col sm={12} md={12}>
              <FormGroup>
                <Label>{t('Documento Carga Masiva')}</Label>
                <Field
                  as={UniqueFileField}
                  name="idanexo"
                  invalid={errors.idanexo && touched.idanexo}
                  disabled={!habilitar}
                  category="anexos"
                  modulo="DocuCargaMasiva"
                  idmodulo={0}
                  record={record?.idanexo}
                  accept=".xlsx"
                />
                <FormFeedback>{errors.idanexo && t(errors.idanexo)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormCargaMasiva"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormCargaMasiva {
  record: ICargaMasiva | null;
  callback?: (record: ICargaMasiva) => Promise<void>;
  entidad: string | null;
  identidad: number | null;
  nombrecarga: string | null;
  plantilla?: string | null;
}

export default FormCargaMasiva;
