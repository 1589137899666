import React, { FC, useContext, useEffect, useState } from "react";
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveTreeMapHtml } from '@nivo/treemap'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';
import { mainContext } from "../../shared/contexts";
import { useTranslation } from "react-i18next";
import { obtenerDashboardService } from "../../core/services/usuarios/usuariosService";
import ContratosVencer from "./contratosVencer";
import ContratosVencidos from "./contratosVencidos";
import { closeModal, openModal } from "../../shared/contexts/actions/mainActions";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import ContratosPorEstado from './contratosPorEstado';

const colorScheme = [
  '#878ff7',
  '#4bd4a5',
  '#fdc442',
  '#f8738e',
];

const HomeComponent: FC = () => {
  const { t } = useTranslation();
  const { mainDispatch } = useContext(mainContext);
  const history = useHistory();

  const [dashboard, setDashboard] = useState<any>(undefined);

  const obtenerDashboard = async () => {
    const resp = await obtenerDashboardService();
    setDashboard(resp);
  }

  const handleEdit = (id: number) => {
    history.push(`/postcontractual/edit/${id}`);
    mainDispatch(closeModal());
  };

  const handleEditModule = (id: number, idmodulo: number) => {
    const esContractual = idmodulo === 1;
    const esPostcontractual = idmodulo >= 2 && idmodulo <= 7;
    if (esContractual) {
      history.push(`/contractual/edit/${id}`);
    }
    if (esPostcontractual) {
      history.push(`/postcontractual/edit/${id}`);
    }
    mainDispatch(closeModal());
  };

  const HayContratosVencidos = useMemo(() => {
    return dashboard?.convencidos?.some(({ items }) => items.length > 0)
  }, [dashboard])

  useEffect(() => {
    obtenerDashboard();
    // eslint-disable-next-line
  }, [])

  console.log(dashboard?.ciclo)

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader><h4 className="header-title2">{t('Ciclo de vida contratación')}</h4></CardHeader>
          <CardBody>
            <div style={{ height: 1200 }}>
              {dashboard?.ciclo && (
                <ResponsiveTreeMapHtml
                  data={dashboard?.ciclo}
                  identity="name"
                  value="loc"
                  margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                  labelSkipSize={12}
                  label="id"
                  labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                  parentLabelTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
                  colors={colorScheme}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.1]] }}
                  parentLabelSize={40}
                  onClick={({ data }) => data.idestado || data.idmodulo ? mainDispatch(openModal({
                    modal: {
                      show: true,
                      title: t('Contratos relacionados'),
                      body: () => (
                        <ContratosPorEstado
                          idestado={data.idestado}
                          idmodulo={data.idmodulo}
                          nombremodulo={data.nombremodulo}
                          handleEdit={handleEditModule}
                        />
                      )
                    }
                  })) : null}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardHeader><h4 className="header-title2">{t('Estado de contratos')}</h4></CardHeader>
          <CardBody>
            <div style={{ height: 600 }}>
              {dashboard?.estados && dashboard?.estadoscon &&
                <ResponsiveBar
                  data={dashboard?.estadoscon}
                  keys={dashboard?.estados}
                  indexBy="tipo"
                  margin={{ top: 10, right: 200, bottom: 90, left: 160 }}
                  padding={0.2}
                  layout="horizontal"
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={colorScheme}
                  borderColor={{ from: 'color' }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: t('CANTIDAD'),
                    legendPosition: 'middle',
                    legendOffset: 32
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -100
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 30,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={6}
                />
              }
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={HayContratosVencidos ? 6 : 12}>
        <Card>
          <CardHeader>
            <h4 className="header-title2">{t('Contratos por vencer')}</h4>
          </CardHeader>
          <CardBody>
            <div style={{ height: 400 }}>
              {dashboard?.conporvencer &&
                <ResponsivePie
                  data={dashboard?.conporvencer}
                  margin={{ top: 40, right: 120, bottom: 80, left: 120 }}
                  sortByValue={true}
                  innerRadius={0.5}
                  padAngle={0.7}
                  colors={colorScheme}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkColor={{ from: 'color' }}
                  sliceLabelsSkipAngle={10}
                  sliceLabelsTextColor="#333333"
                  onClick={(node, event) => mainDispatch(openModal({
                    modal: {
                      show: true,
                      title: t('Contratos por vencer'),
                      body: () => (
                        <ContratosVencer data={node?.data} timestamp={dashboard.timestamp} handleEdit={handleEdit} />
                      )
                    }
                  }))}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 95,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000'
                          }
                        }
                      ]
                    }
                  ]}
                />
              }
            </div>
          </CardBody>
        </Card>
      </Col>
      {HayContratosVencidos &&
        <Col md={6}>
          <Card>
            <CardHeader><h4 className="header-title2">{t('Contratos vencidos')}</h4></CardHeader>
            <CardBody>
              <div style={{ height: 400 }}>
                <ResponsivePie
                  data={dashboard?.convencidos}
                  margin={{ top: 40, right: 90, bottom: 80, left: 120 }}
                  sortByValue={true}
                  innerRadius={0.5}
                  padAngle={0.7}
                  colors={colorScheme}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkColor={{ from: 'color' }}
                  sliceLabelsSkipAngle={10}
                  sliceLabelsTextColor="#333333"
                  onClick={(node, event) => mainDispatch(openModal({
                    modal: {
                      show: true,
                      title: t('Contratos vencidos'),
                      body: () => (
                        <ContratosVencidos data={node?.data} timestamp={dashboard.timestamp} handleEdit={handleEdit} />
                      )
                    }
                  }))}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 95,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000'
                          }
                        }
                      ]
                    }
                  ]}
                />

              </div>
            </CardBody>
          </Card>
        </Col>
      }
    </Row>
  )
};
export default HomeComponent;
