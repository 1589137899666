import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IFormUbicacionesFirma, IUbicacionesFirma } from '../../models/documentos/ubicacionesFirmaModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerUbicacionesFirma = async (params?): Promise<IUbicacionesFirma[]> => {
  let data: IUbicacionesFirma[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_UBICACIONES_FIRMA, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarUbicacionesFirma = async (
  values: IFormUbicacionesFirma,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_UBICACIONES_FIRMA : `${urlApi.EDIT_UBICACIONES_FIRMA}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarUbicacionesFirma = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_UBICACIONES_FIRMA}${id}`;
  return await deleteItem(url, notification);
};
