import React, { FC } from 'react';
import { IResponseCargaMasiva } from '../../../core/models/documentos/cargaMasivaModel';
import { Alert, ModalBody, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const ErroresCargaMasiva: FC<IErroresCargaMasiva> = ({ response }) => {
  const { t } = useTranslation();
  return (
    <ModalBody>
      <Alert color="info">{t('Se encontraron los siguientes errores')}</Alert>
      <Table>
        <thead>
          <tr>
            <th align="center">{t('Celda')}</th>
            <th align="center">{t('Error')}</th>
          </tr>
        </thead>
        <tbody>
          {response.errors?.map(row => (
            <tr key={uuidv4()}>
              <td>{row.celda}</td>
              <td>{row.error}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ModalBody>
  )
}

interface IErroresCargaMasiva {
  response: IResponseCargaMasiva;
}

export default ErroresCargaMasiva;
