import React, { FC, useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { MobileStepper } from '@material-ui/core';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFirmaContext } from '../../shared/contexts/firmaContext';

const Steplineal: FC<IStepper> = ({
  getStepContent,
  steps,
  defaultStep = 0
}) => {

  // States
  const { activeStep, setActiveStep } = useFirmaContext();
  const { t } = useTranslation();

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const stepsShow = steps.filter(step => step.show);

  const findStepIndexById = (id: number) => {
    const step = stepsShow.find((step) => step.id === id);
    return step ? stepsShow.indexOf(step) : -1;
  };

  useEffect(() => {
    if (defaultStep) {
      setActiveStep(Number(defaultStep));
    }
  }, [defaultStep]);

  return (
    <>
      <Stepper alternativeLabel nonLinear activeStep={findStepIndexById(activeStep)} className="d-none d-sm-none d-md-none d-lg-flex">
        {stepsShow.map((step, index) => (
          <Step key={index}>
            <StepButton
              style={{ outline: 'none' }}
              onClick={handleStep(step.id)}
              completed={step.id < activeStep}
              disabled={!step.enabled}
            >
              {t(step.label)}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <MobileStepper
        steps={steps.length}
        position="static"
        variant="dots"
        activeStep={(activeStep - 1)}
        className="d-flex d-sm-flex d-md-flex d-lg-none"
        nextButton={
          <Button
            size="small"
            color="link"
            onClick={handleNext}
            disabled={activeStep === steps.length - 1 || !steps.find(step => step.id == (activeStep + 1))?.enabled}
          >
            {t('Siguiente')}
          </Button>
        }
        backButton={
          <Button
            size="small"
            color="link"
            onClick={handleBack}
            disabled={activeStep === 0 || !steps.find(step => step.id == (activeStep - 1))?.enabled}
          >
            {t('Atras')}
          </Button>
        }
      />
      <Card className="d-block d-sm-block d-md-block d-lg-none mb-0">
        <CardBody>
          <h5 className="text-center">{t(steps[activeStep - 1]?.label)}</h5>
        </CardBody>
      </Card>
      {getStepContent(activeStep)}
    </>
  );
}

export interface IStepper {
  getStepContent: (step: number) => React.ReactNode;
  steps: {
    id: number;
    label: string;
    enabled: boolean;
    show: boolean;
  }[];
  defaultStep: number;
}

export default Steplineal;
