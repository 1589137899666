import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Card, CardBody, Col, PopoverBody, Row, Spinner, UncontrolledPopover } from 'reactstrap';
import { useMainContext } from '../../shared/contexts/mainContext';
import { changeHeader } from '../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../shared/contexts/interfaces/mainInterfaces';
import { useParams } from 'react-router-dom';
import { useFirmaContext } from '../../shared/contexts/firmaContext';
import DatosForm from './components/DatosForm';
import Steplineal from './Steplienal';
import dev from '../../core/enviroments';
import OTPForm from './components/OTPForm';
import AprobacionForm from './components/AprobacionForm';
import FirmarForm from './components/FirmarForm';
import PrevisualizarForm from './components/PrevisualizarForm';
import { obtenerPDFFirma } from '../../shared/helpers/generales';

const FirmaDocumento: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { t, i18n: { language } } = useTranslation();
  const { mainDispatch } = useMainContext();
  const { imagen, obtenerFirma, codigo, loading, firma } = useFirmaContext();

  const steps = [
    {
      id: 1,
      label: t('Validar datos'),
      enabled: !codigo && !firma?.firmado,
      show: true
    },
    {
      id: 2,
      label: t('Código de acceso'),
      enabled: !codigo && !firma?.firmado,
      show: dev.blockchain_otp
    },
    {
      id: 3,
      label: t('Aprobación'),
      enabled: !!codigo,
      show: firma?.requiereaprobacion === 1 && firma?.tokenaparte === 2
    },
    {
      id: 4,
      label: t('Realizar firma'),
      enabled: !!codigo && firma?.aprueba === 1 && !firma?.firmado,
      show: true
    },
    {
      id: 5,
      label: t('Previsualizar'),
      enabled: !!codigo && !!imagen && !firma?.firmado,
      show: true
    },
    {
      id: 6,
      label: t('Finalizar'),
      enabled: !!firma?.firmado,
      show: true
    },
  ];

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Firma documento'),
        type: mainTypeHeader.SUBTITLE
      }
    }));
  }, [language]);

  useEffect(() => {
    obtenerFirma(token);
  }, [token]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return <DatosForm />
      case 2:
        return <OTPForm />
      case 3:
        return <AprobacionForm />
      case 4:
        return <FirmarForm />
      case 5:
        return <PrevisualizarForm />
    }
  }

  const pdf = useMemo(() => firma ? obtenerPDFFirma(firma) : null, [firma]);

  return (
    <Card>
      <CardBody>
        {loading ? (
           <div className="d-flex flex-column justify-content-center text-center">
            <Spinner color="primary" className="m-auto" />
            <span className="mt-3">
              {t('Cargando documento...')}
            </span>
          </div>
        ) : null}
        {(firma ? (
          <>
            {firma.firmado === 1 && (
              <>
                <Alert color="success" className="mt-4">
                  {t('El documento fue firmado correctamente. Puede cerrar esta ventana.')}
                </Alert>
                <Row>
                  <Col sm={12} md={{ size: 6, offset: 3 }}>
                    <a
                      href={pdf?.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-info btn-block"
                    >
                      {t('Ver documento firmado')} <i className="uil-file-alt"></i>
                    </a>
                  </Col>
                  {!!firma.idblockchain?.urlchecker && (
                    <Col sm={12} md={{ size: 6, offset: 3 }}>
                    <a
                      href={firma.idblockchain.urlchecker}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-success btn-block mt-2"
                      id="tooltip-blockchain"
                    >
                      {t('Validar documento')} <i className="uil-check"></i>
                    </a>
                    <UncontrolledPopover
                      placement="bottom"
                      target="tooltip-blockchain"
                      trigger="hover"
                    >
                      <PopoverBody>
                        {t('Confirme que contenido del documento firmado es válido.')}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                  )}
                </Row>
              </>
            )}
            {firma.firmado === 2 && (
              <Alert color="success" className="mt-4">
                {t('El documento fue desaprobado correctamente. Puede cerrar esta ventana.')}
              </Alert>
            )}
            {firma.firmado === null && (
              <>
                {firma.noAprobaciones?.length ? (
                  <Alert color="info" className="mt-4">
                    {t('Este documento ha sido desaprobado por uno de los aprobadores.')}
                  </Alert>
                ) : (
                  <Steplineal
                    getStepContent={getStepContent}
                    steps={steps}
                    defaultStep={firma?.firmado ? 6 : 1}
                  />
                )}
              </>
            )}
          </>
        ) : (!loading && (
          <Alert color="info" className="mt-4">
            {t('Este enlace ya no se encuentra disponible.')}
          </Alert>
        )))}
      </CardBody>
    </Card>
  )
}

export default FirmaDocumento;
