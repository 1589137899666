import { IPaises } from './paisesModel';
import { IEmpresas } from './empresasModel';
import { FormValidation } from '../../../shared/utilities';
import * as Yup from 'yup';
import { consultaDetallada, obtenerTerceros } from '../../services/administracion/tercerosService';

// Model form
export interface IFormTerceros {
  id?: number | null;
  tipo: number;
  idpaises: IPaises | string | number;
  idempresas?: IEmpresas | string | number | null;
  razonsocial: string;
  tiporazonsocial: number;
  documentoempresa: string;
  direccion: string;
  email: string;
  telefono: string;
  representantelegal: string;
  emailrepresentante: string | null;
  tiporepresentante: number;
  emailalternativo: string | null;
  emailfacturacion: string | null;
  documentorepresentante: string | null;
  contacto: string | null;
  activo: boolean | number;
  proveedor: boolean;
  cliente: boolean;
};

// Model Base
export interface ITerceros extends IFormTerceros {
  creado: any;
  actualizado: any;
};

let timeSearch: any;
// Esquema de formulario
export const formSchema = Yup.lazy<any>((values: ITerceros) => Yup.object().shape({
  id: Yup.number().nullable(),
  idpaises: Yup.number().min(1, FormValidation.required),
  // idempresas: Yup.number().min(1, FormValidation.required),
  razonsocial: Yup.string().required(FormValidation.required),
  tiporazonsocial: Yup.number().min(1, FormValidation.required),
  direccion: Yup.string().required(FormValidation.required),
  email: Yup.string().email(FormValidation.email).required(FormValidation.required),
  telefono: Yup.string().required(FormValidation.required),
  representantelegal: Yup.string().required(FormValidation.required),
  emailrepresentante: Yup.string().required(FormValidation.required).email(FormValidation.email), 
  tiporepresentante: Yup.number().min(1, FormValidation.required),
  emailalternativo: Yup.string().email(FormValidation.email),
  emailfacturacion: Yup.string().email(FormValidation.email),
  documentorepresentante: Yup.string().required(FormValidation.required),
  contacto: Yup.string(),
  activo: Yup.bool().when("id", {
    is: (id) => {
      if(id){
        return false;
      }
      return true;
    },
    then: Yup.bool().required(FormValidation.required).oneOf([true, false], FormValidation.required),
  }),
  proveedor: (!values.cliente) ? Yup.bool().test('comp', FormValidation.required, function (val) {
    return val ? true : false;
  }) : Yup.bool(),
  cliente: (!values.proveedor) ? Yup.bool().test('comp', FormValidation.required, function (val) {
    return val ? true : false;
  }) : Yup.bool(),
  documentoempresa: Yup.string().required(FormValidation.required)
    .test('checkDup', FormValidation.documentoDup, function (documento) {
      return new Promise<boolean>(async (resolve) => {
        let notExist = true;
        (timeSearch) && clearTimeout(timeSearch);
        timeSearch = setTimeout(async () => {
          if (documento && documento?.trim() !== '') {
            try {
              const resp = await consultaDetallada('documentoEmpresa', documento);
              if (resp.length > 0) {
                notExist = (values.id && resp[0].id === values.id) ? true : false
              }
            } catch (error) {
              console.log(error);
            }
          }
          resolve(notExist);
        }, 500);
      });
    }),
}));

// Estado inicial
export const initialState = (data?: ITerceros, tipo?: string | number): IFormTerceros => {
  return {
    id: data?.id || null,
    tipo: 1,
    idpaises: (data?.idpaises as IPaises)?.id || 0,
    // idempresas: (data?.idempresas as IEmpresas)?.id || 0,
    razonsocial: data?.razonsocial || '',
    tiporazonsocial: data?.tiporazonsocial || 0,
    documentoempresa: data?.documentoempresa || '',
    direccion: data?.direccion || '',
    email: data?.email || '',
    telefono: data?.telefono || '',
    representantelegal: data?.representantelegal || '',
    emailrepresentante: data?.emailrepresentante || '',
    tiporepresentante: data?.tiporepresentante || 0,
    emailalternativo: data?.emailalternativo || '',
    emailfacturacion: data?.emailfacturacion || '',
    documentorepresentante: data?.documentorepresentante || '',
    contacto: data?.contacto || '',
    activo: data?.activo === 2 ? false : true,
    proveedor: data?.proveedor || parseInt(tipo as string) === 1 ||false,
    cliente: data?.cliente || parseInt(tipo as string) === 2 || false,
  }
};
