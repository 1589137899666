import React, { FC } from 'react';
import { useVisorPDFContext } from '../../contexts/visorPDFContext';
import { useFirmaContext } from '../../contexts/firmaContext';

const UbicacionesImageView: FC = () => {
  const { firma, imagen } = useFirmaContext();
  const { pageNumber } = useVisorPDFContext();
  return (
    <div className="ubicaciones-image-view">
      {firma?.idubicaciones.map((ubicacion) => (pageNumber === ubicacion.hoja &&
        <div
          className="sign-item"
          style={{
            left: ubicacion.posx,
            top: ubicacion.posy
          }}
          key={ubicacion.id}
        >
          <img src={imagen} alt="Imagen firma" />
          <span>
            hash: xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          </span>
        </div>
      ))}
    </div>
  )
}

export default UbicacionesImageView;
