import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { CustomInput, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const RadioButtonField: FC<IRadioButtonField> = ({ 
  invalid = false,
  disabled = false,
  options = [],
  ...props
}) => {
  const { setFieldValue, values, getFieldProps } = useFormikContext();
  const { t } = useTranslation();
  return (
    <div className={invalid ? "d-flex is-invalid" : "d-flex"}>
      {options.map((item, index) => (
        <Label className="mr-4" key={`${props.name}-item-${index}`}>
          <CustomInput
            type="radio"
            name={props.name}
            label={t(item.label)}
            disabled={disabled}
            invalid={invalid}
            checked={getFieldProps(props.name)?.value === item.value}
            {...props}
            onChange={() => setFieldValue(props.name, item.value)}
            id={`${props.name}-${index}`}
            value={undefined}
          />
        </Label>
      ))}
    </div>
  )
}

interface IRadioButtonField {
  field: any;
  options: {
    value: number | string;
    label: string;
  }[];
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
}

export default RadioButtonField;
