import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { IFormAseUsuarios } from '../../models/asesorias/usuariosModel';
import { deleteItem, getRequest, updateItem } from "../peticionesService";
import {useUpdateRequest} from "../../../shared/hooks/useUpdate";

export const obtenerAsesoriaService = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_ASESORIAS}${id}`);
    const record = {
      ...resp.data.record,
      docautogestion: resp.data.docautogestion
    }
    data = record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const cambiarEstadoService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = `${urlApi.STATES_ASESORIAS}${values?.id}`;
  return await updateItem(url, values, notification, false, sendNotification, requestData);
};

export const obtenerUsuariosService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_USUARIOS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerAbogadosService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_ABOGADOS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite borrar un registro
 * @param id
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const borrarUsuarios = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_ASESORIAS_USUARIOS}${id}`;
  return await deleteItem(url, notification);
};


export const obtenerDocumentosService = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_ASESORIAS_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const guardarAsesoriaService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = (values?.id || values?.json?.id) ? `${urlApi.EDIT_ASESORIAS}${(values?.id || values?.json?.id)}` : `${urlApi.CREATE_ASESORIAS}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const listarAsesoriasService = async (): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerLogsService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_LOGS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const listarPreguntasService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_PREGUNTAS, filter? {filter: filter, valuefilter: valuefilter} : {});
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerAnsService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_ANS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarAsesoriasUsuarios = async (
  values: IFormAseUsuarios,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_ASESORIAS_USUARIOS : `${urlApi.EDIT_ASESORIAS_USUARIOS}${values.id}`
  return await updateItem(url, values, notification, false, sendNotification);
};


export const useObtenerProcesosDinamicosAsesorias = (
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetAsesorias = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: urlApi.DATATABLE_ASESORIAS,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };

  return {
    handleGetAsesorias,
  };
};

export const obtenerListaFiltros = async () => {
  let data  = [];
  try {
    const resp = await getRequest(urlApi.LIST_FILTRO_ASESORIAS);
    data = resp.data.records
  } catch (error) {
    console.error(error);
  }
  return data;
}
//crear comentarios en 88 respuesta asesoria
export const crearComentarios = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMENTARIO;
  return await updateItem(url, values, notification);
};

export const obtenerComentariosAsesorias = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_COMENTARIOS_ASESORIAS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

