import React, { FC } from 'react';
import { IProveedores } from '../../../../../core/models/precontractual/proveedoresModel';
import { useTranslation } from 'react-i18next';
import FormProveedor from '../../../../precontractual/components/Forms/FormProveedor';
import FormInfoBasicaPre from '../forms/FormInfoBasicaPre';
import { Alert } from 'reactstrap';

const ViewPreProveedores003: FC<IViewPreProveedores003> = ({ record }) => {
  const { t } = useTranslation();
  return (
    <>
      {record.adjudicar === 1 ? (
        <>
          <FormInfoBasicaPre proveedor={record} />
          <h5>{t('Aceptación de adjudicación')}</h5>
          <hr />
          <FormProveedor record={record} adjudicacion />
        </>
      ) : (
        <Alert color="warning mb-0">
          {t(`El proponente no puede avanzar con el proceso de adjudicación.`)}
        </Alert>
      )}
    </>
  )
}

interface IViewPreProveedores003 {
  record: IProveedores;
}

export default ViewPreProveedores003;
