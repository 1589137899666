import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IFirmas, IFormAprobacion, IFormFirmas, IFormPrevisualizar } from '../../models/documentos/firmasModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerFirmas = async (params?): Promise<IFirmas[]> => {
  let data: IFirmas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_FIRMAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarFirmas = async (
  values: IFormFirmas | IFormAprobacion | IFormPrevisualizar,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_FIRMAS : `${urlApi.EDIT_FIRMAS}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarFirmas = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_FIRMAS}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite mostrar un registro
 * @param token 
 * @returns 
 */
export const mostrarFirma = async (token: string): Promise<IFirmas|null> => {
  let data: IFirmas|null = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_FIRMAS}${token}`);
    data = resp.data.record;
    if (resp.data.firmaPadre && data) {
      data.idfirmapadre = resp.data.firmaPadre;
    }
    if (resp.data.noAprobaciones && data) {
      data.noAprobaciones = resp.data.noAprobaciones;
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};
