import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IOferta, IOfertaForm } from '../../../../core/models/precontractual/ofertaModel';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import NumberField from '../../../../shared/components/NumberField';
import UniqueSelectField from '../../../../shared/components/UniqueSelectField';

const FormOferta: FC<IFormOferta> = ({ onSubmit, record, idpreproveedor }) => {
  const { proceso, productos } = usePrecontractualContext();
  const { t } = useTranslation();

  const habilitar = true;

  const initialValues: IOfertaForm = {
    id: null,
    valorunitario: '',
    activo: 1,
    ...record,
    idprecontractual: proceso?.id ?? 0,
    idproducto: record?.idproducto.id ?? 0,
    idcargamasiva: record?.idcargamasiva?.id ?? 0,
    idpreproveedor: record?.idpreproveedor.id ?? idpreproveedor,
  } as IOfertaForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    idproducto: Yup.number().min(1, FormValidation.required),
    valorunitario: Yup.number().required(FormValidation.required).min(1, FormValidation.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormOferta" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Producto')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="idproducto"
                  invalid={errors.idproducto && touched.idproducto}
                  disabled={!habilitar}
                  options={productos.map(item => ({
                    value: item.id,
                    label: item.producto,
                  }))}
                />
                <FormFeedback>{errors.idproducto && t(errors.idproducto)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Valor Unitario')}</Label>
                <Field
                  as={NumberField}
                  name="valorunitario"
                  invalid={errors.valorunitario && touched.valorunitario}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{errors.valorunitario && t(errors.valorunitario)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormOferta"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormOferta {
  record: IOferta | null;
  onSubmit: (values: IOfertaForm) => Promise<void>;
  idpreproveedor?: number;
}

export default FormOferta;
