import React, { FC, useEffect } from "react";
import { useDrag } from "react-dnd";
import { IUbicacionesFirma } from '../../../core/models/documentos/ubicacionesFirmaModel';
import { useVisorPDFContext } from '../../contexts/visorPDFContext';

const UbicacionesCaja: FC<IUbicacionesCaja> = ({ id, item, children }) => {
  const { setShowMargin, editar } = useVisorPDFContext();

  const { posx, posy } = item;
  const [{ isDragging }, drag] = useDrag({
    item: { id, left: posx, top: posy, type: "box" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    setShowMargin(isDragging);
  }, [isDragging]);

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div
      ref={editar ? drag : null}
      style={{
        left: posx,
        top: posy,
      }}
      className={editar ? "caja-firma" : "caja-firma no-move"}
    >
      {children}
    </div>
  );
};

interface IUbicacionesCaja {
  id: number | null;
  item: IUbicacionesFirma;
}

export default UbicacionesCaja;
