import React, { FC, useMemo } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { useFirmaContext } from '../../../shared/contexts/firmaContext';
import { IFormAprobacion } from '../../../core/models/documentos/firmasModel';
import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import BooleanField from '../../../shared/components/BooleanField';
import { useTranslation } from 'react-i18next';
import dev from '../../../core/enviroments';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { useParams } from 'react-router-dom';
import { obtenerPDFFirma } from '../../../shared/helpers/generales';

const AprobacionForm: FC = () => {
  const { mainState } = useMainContext();
  const { firma, handleAprobacion, setActiveStep, activeStep } = useFirmaContext();
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const esFirma = window.location.href.includes('/documentos/firma/');

  const version = firma?.iddocumento;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: firma?.id || null,
      aprueba: firma?.aprueba || 0,
      firmado: firma?.firmado || null,
      comentario: firma?.comentario || '',
      option: 2,
    },
    validationSchema: Yup.lazy((values: IFormAprobacion): Yup.Lazy => Yup.object().shape({
      aprueba: Yup.number().min(1, FormValidation.required),
      comentario: values.aprueba === 2 ? Yup.string().required(FormValidation.required) : Yup.string(),
    })),
    onSubmit: async (values: IFormAprobacion) => {
      const data = {
        ...values,
        firmado: values.aprueba === 2 ? 2 : null,
      }
      await handleAprobacion(data, token);
      if (esFirma) setActiveStep(activeStep + 1);
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  const pdf = useMemo(() => firma ? obtenerPDFFirma(firma) : null, [firma]);

  return (
    <Form id="AprobacionForm" onSubmit={handleSubmit}>
      <h4>{t('Aprobación')}</h4>
      <Alert color="info" className="mt-4">
        Por favor revise el documento e indique si aprueba el mismo.
      </Alert>
      <hr />
      <FormikProvider value={formik}>
        <Row>
          <Col sm={12} md={{ size: 6, offset: 3 }}>
            {!!version && (
              <a
                href={pdf?.path}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success btn-block mb-2"
              >
                {t('Ver documento')}<i className="uil-file-alt"></i>
              </a>
            )}
          </Col>
          <Col sm={12} md={{ size: 6, offset: 3 }}>
            <FormGroup>
              <Label for="aprueba">{t('Aprueba')}</Label>
              <Field
                as={BooleanField}
                id="aprueba"
                name="aprueba"
                invalid={errors.aprueba && touched.aprueba}
              />
              <FormFeedback>{t(errors.aprueba as string)}</FormFeedback>
            </FormGroup>
          </Col>
          {values.aprueba === 2 && (
            <Col sm={12} md={{ size: 6, offset: 3 }}>
              <FormGroup>
                <Label for="comentario">{t('Comentarios')}</Label>
                <Field
                  as={Input}
                  type="textarea"
                  id="comentario"
                  name="comentario"
                  invalid={errors.comentario && touched.comentario}
                />
                <FormFeedback>{t(errors.comentario as string)}</FormFeedback>
              </FormGroup>
            </Col>
          )}
          <Col sm={12} md={12}>
            <hr />
            <ButtonCustom
              form="AprobacionForm"
              className="d-flex align-items-center"
              type="submit"
              color={values.aprueba === 1 ? 'primary' : 'danger'}
              loading={mainState.loading}
              disabled={mainState.loading}
              style={{ float: 'right' }}
            >
              {values.aprueba === 1 ? t('Continuar') : t('Finalizar')}
            </ButtonCustom>
          </Col>
        </Row>
      </FormikProvider>
    </Form>
  )
}

export default AprobacionForm;
