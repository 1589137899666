import React, { FC, createContext, useContext, useState } from 'react';
import { estadosPrecontractual, guardarPrecontractual, mostrarPrecontractual } from '../../core/services/precontractual/precontractualService';
import { IPrecontractual, IPrecontractualForm } from '../../core/models/precontractual/precontractualModel';
import { useMainContext } from './mainContext';
import { falseLoading, trueLoading } from './actions/mainActions';
import { guardarUsuarios, listarUsuarios } from '../../core/services/precontractual/usuariosService';
import { EEstadosPre, ERolsProceso, ETipoSolicitud } from '../utilities';
import { useUserContext } from './userContext';
import { ICalendario } from '../../core/models/precontractual/calendarioModel';
import { listarCalendario } from '../../core/services/precontractual/calendarioService';
import { IProducto } from '../../core/models/precontractual/productoModel';
import { listarProducto } from '../../core/services/precontractual/productoService';
import { ICriterios } from '../../core/models/precontractual/criteriosModel';
import { listarCriterios } from '../../core/services/precontractual/criteriosService';
import { IProveedores } from '../../core/models/precontractual/proveedoresModel';
import { listarProveedores } from '../../core/services/precontractual/proveedoresService';
import { IUsuarios } from '../../core/models/precontractual/usuariosModel';
import { IAprobaciones } from '../../core/models/precontractual/aprobacionesModel';
import { listarAprobaciones } from '../../core/services/precontractual/aprobacionesService';
import { IAdendas } from '../../core/models/precontractual/adendasModel';
import { listarAdendas } from '../../core/services/precontractual/adendasService';
import { ICancelaciones } from '../../core/models/precontractual/cancelacionesModel';
import { listarCancelaciones } from '../../core/services/precontractual/cancelacionesService';

interface IPrecontractualContext {
  adendas: IAdendas[];
  aprobaciones: IAprobaciones[];
  aprobacionesTodas: IAprobaciones[];
  calendario: ICalendario[];
  cancelaciones: ICancelaciones[];
  cambiarEstado: () => Promise<IPrecontractual | null>;
  devoluciones: IAprobaciones[];
  estado: number | null;
  guardarProceso: (values: IPrecontractualForm) => Promise<IPrecontractual | null>;
  limpiarProceso: () => void;
  obtenerAdendas: (idproceso?: number) => Promise<void>;
  obtenerActividades: (idproceso?: number) => Promise<void>;
  obtenerAprobaciones: (idproceso?: number) => Promise<void>;
  obtenerCancelaciones: (idproceso?: number) => Promise<void>;
  obtenerCriterios: (idproceso?: number) => Promise<void>;
  obtenerProceso: (id: number) => Promise<IPrecontractual | null>;
  obtenerProductos: (idproceso?: number) => Promise<void>;
  obtenerProveedores: (idproceso?: number) => Promise<void>;
  obtenerUsuarios: (idproceso?: number) => Promise<void>;
  criterios: ICriterios[];
  proceso: IPrecontractual | null;
  productos: IProducto[];
  proveedores: IProveedores[];
  roles: IRoles;
  usuarios: IUsuarios[];
  usuariosTodos: IUsuarios[];
}

interface IRoles {
  solicitante: boolean;
  registro_terceros: boolean;
  evaluador: boolean;
  aprobadorniv1: boolean;
  aprobadorniv2: boolean;
  aprobadorniv3: boolean;
  aprobadorniv4: boolean;
  aprobadoret2niv1: boolean;
  aprobadoret2niv2: boolean;
  aprobadoret2niv3: boolean;
  aprobadoret2niv4: boolean;
}

const PrecontractualContext = createContext<IPrecontractualContext | undefined>(undefined);

export const PrecontractualProvider: FC = ({ children }) => {
  const { mainDispatch, handleNotification } = useMainContext();
  const { userState } = useUserContext();

  const [adendas, setAdendas] = useState<IAdendas[]>([]);
  const [aprobaciones, setAprobaciones] = useState<IAprobaciones[]>([]);
  const [aprobacionesTodas, setAprobacionesTodas] = useState<IAprobaciones[]>([]);
  const [calendario, setCalendario] = useState<ICalendario[]>([]);
  const [cancelaciones, setCancelaciones] = useState<ICancelaciones[]>([]);
  const [criterios, setCriterios] = useState<ICriterios[]>([]);
  const [devoluciones, setDevoluciones] = useState<IAprobaciones[]>([]);
  const [proceso, setProceso] = useState<IPrecontractual | null>(null);
  const [productos, setProductos] = useState<IProducto[]>([]);
  const [proveedores, setProveedores] = useState<IProveedores[]>([]);
  const [usuarios, setUsuarios] = useState<IUsuarios[]>([]);
  const [usuariosTodos, setUsuariosTodos] = useState<IUsuarios[]>([]);
  const [roles, setRoles] = useState<IRoles>({
    solicitante: false,
    registro_terceros: false,
    evaluador: false,
    aprobadorniv1: false,
    aprobadorniv2: false,
    aprobadorniv3: false,
    aprobadorniv4: false,
    aprobadoret2niv1: false,
    aprobadoret2niv2: false,
    aprobadoret2niv3: false,
    aprobadoret2niv4: false,
  });

  const estado = proceso?.idestados?.codigo ? Number(proceso.idestados.codigo) : null;

  const asignarSolicitante = async (precontractual: IPrecontractual) => {
    const response = await guardarUsuarios({
      id: null,
      rol: ERolsProceso.SOLICITANTE,
      activo: 1,
      idprecontractual: precontractual.id ?? 0,
      idusuarios: userState.data?.sub ?? 0,

    }, handleNotification);
    return response;
  }

  const guardarProceso = async (values: IPrecontractualForm) => {
    mainDispatch(trueLoading());
    const response = await guardarPrecontractual(values, handleNotification);
    if (values.id === null && response) {
      await asignarSolicitante(response);
    }
    mainDispatch(falseLoading());
    return response;
  }

  const cambiarEstado = async () => {
    if (proceso?.id) {
      mainDispatch(trueLoading());
      const response = await estadosPrecontractual(proceso?.id, 'avanzar', handleNotification);
      if (response) {
        const proceso = await obtenerProceso(response.id ?? 0);
        const nuevoEstado = Number(proceso?.idestados.codigo);
        const finalizado = nuevoEstado === EEstadosPre.FINALIZADO;
        const requiereLicitacion = proceso?.requierelicitacion === 1;
      }
      mainDispatch(falseLoading());
      return response;
    } else {
      return null;
    }
  }

  const limpiarProceso = () => {
    setProceso(null);
  }

  const obtenerActividades = async (idproceso?: number) => {
    const calendario = await listarCalendario({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setCalendario(calendario);
  }

  const obtenerAdendas = async (idproceso?: number) => {
    const adendas = await listarAdendas({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setAdendas(adendas);
  }

  const obtenerAprobaciones = async (idproceso?: number) => {
    const aprobaciones = await listarAprobaciones({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    const activas = aprobaciones.filter(item => item.activo === 1);
    const devoluciones = aprobaciones.filter(item => item.aprueba === 2);
    setAprobaciones(activas);
    setDevoluciones(devoluciones);
    setAprobacionesTodas(aprobaciones);
  }

  const obtenerCancelaciones = async (idproceso?: number) => {
    const cancelaciones = await listarCancelaciones({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setCancelaciones(cancelaciones);
  }

  const obtenerCriterios = async (idproceso?: number) => {
    const criterios = await listarCriterios({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setCriterios(criterios);
  }

  const obtenerProductos = async (idproceso?: number) => {
    const productos = await listarProducto({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setProductos(productos);
  }

  const obtenerProceso = async (id: number) => {
    const proceso = await mostrarPrecontractual(id);
    setProceso(proceso);
    return proceso;
  }

  const obtenerProveedores = async (idproceso?: number) => {
    const proveedores = await listarProveedores({
      filter: 'idprecontractual',
      valuefilter: proceso?.id ?? idproceso,
    });
    setProveedores(proveedores);
  }

  const obtenerUsuarios = async (idproceso?: number) => {
    const userid = userState.data?.sub;
    const usuariosTodos = await listarUsuarios({
      filter: 'idprecontractual',
      valuefilter: idproceso ?? proceso?.id,
    });
    const usuarios = usuariosTodos.filter(user => user.activo === 1);
    setUsuarios(usuarios);
    setUsuariosTodos(usuariosTodos);
    setRoles({
      solicitante: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.SOLICITANTE),
      registro_terceros: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.REGISTRO_TERCEROS),
      evaluador: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.EVALUADOR),
      aprobadorniv1: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_NIV_1),
      aprobadorniv2: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_NIV_2),
      aprobadorniv3: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_NIV_3),
      aprobadorniv4: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_NIV_4),
      aprobadoret2niv1: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_ETAPA_2_NIV_1),
      aprobadoret2niv2: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_ETAPA_2_NIV_2),
      aprobadoret2niv3: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_ETAPA_2_NIV_3),
      aprobadoret2niv4: !!usuarios.find(item => item.idusuarios.id === userid && item.rol === ERolsProceso.APROBADOR_ETAPA_2_NIV_4),
    });
  }

  const contexto: IPrecontractualContext = {
    adendas,
    aprobaciones,
    aprobacionesTodas,
    calendario,
    cancelaciones,
    cambiarEstado,
    devoluciones,
    estado,
    guardarProceso,
    limpiarProceso,
    obtenerActividades,
    obtenerAdendas,
    obtenerAprobaciones,
    obtenerCancelaciones,
    obtenerCriterios,
    obtenerProductos,
    obtenerProceso,
    obtenerProveedores,
    obtenerUsuarios,
    criterios,
    proceso,
    productos,
    proveedores,
    roles,
    usuarios,
    usuariosTodos,
  };

  return (
    <PrecontractualContext.Provider value={contexto}>
      {children}
    </PrecontractualContext.Provider>
  );
};

export const usePrecontractualContext = () => {
  const context = useContext(PrecontractualContext);
  if (!context) {
    throw new Error('usePrecontractualContext debe utilizarse dentro de un PrecontractualProvider');
  }
  return context;
};
