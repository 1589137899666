import React, { createContext, useReducer, useState } from 'react';
import mainReducer, { mainInitialState } from './reducers/mainReducer';
import { IMainContext, mainTypeMsj } from './interfaces/mainInterfaces';
import { changeLanguage } from './actions/mainActions';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from './userContext';
import { listarAnexos } from '../../core/services/documentos/anexosService';
import { IAnexos } from '../../core/models/documentos/anexosModel';

const initialState: IMainContext = {
  mainState: mainInitialState(),
  mainDispatch: () => { },
  handleNotification: () => { },
  documentosTable: {
    refresh: () => {},
    time: 0,
    data: [],
    loaded: false,
    getData: async (modulo, idmodulo) => {}
  },
};
export const mainContext = createContext<IMainContext>(initialState);

export const MainProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userState } = useUserContext();
  const [state, dispatch] = useReducer(mainReducer, mainInitialState());
  const { i18n } = useTranslation();
  const { language } = state;

  const [time, setTime] = useState(Date.now());
  const [documentos, setDocumentos] = useState<IAnexos[]>([]);
  const [loadedDocumentos, setLoadedDocumentos] = useState(false);

  const refresh = () => {
    setTime(Date.now());
  }

  const listarDocumentos = async (modulo: string, idmodulo: string | number | null) => {
    const params = {
      filter: 'modulo,idmodulo',
      valuefilter: `${modulo},${idmodulo}`,
    };
    const data = await listarAnexos(params);
    setDocumentos(data);
    setLoadedDocumentos(true);
  }

  const documentosTable = {
    refresh,
    time,
    data: documentos,
    loaded: loadedDocumentos,
    getData: listarDocumentos,
  }

  /**
   * Permite mostrar un mensaje en la aplicación
   * @param msj mensaje que se quiere mostrar
   * @param type tipo de mensaje
   */
  const handleNotification = (
    msj: string,
    type: mainTypeMsj) => {
    enqueueSnackbar(msj, {
      variant: type,
    });
  };

  useEffect(() => {
    if (userState?.data?.locale) {
      const language = userState?.data?.locale;
      dispatch(changeLanguage({
        language
      }))
    }
  }, [userState]);

  useEffect(() => {
    i18n.changeLanguage(language || 'es');
  }, [language]);

  return (
    <mainContext.Provider
      value={{
        mainState: state,
        mainDispatch: dispatch,
        handleNotification,
        documentosTable,
      }}
    >
      {children}
    </mainContext.Provider>
  );
};


export const useMainContext = () => {
  const context = React.useContext(mainContext)
  if (context === undefined) {
    throw new Error('useMainContext must be used within a MainProvider')
  }
  return context
};

