import jwtDecode from 'jwt-decode';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = (info?: boolean) => {
  const user = getLoggedInUser();
  if (!user || !user?.data) {
    return (info) ? {
      isAutenticated: false,
      data: null
    } : false;
  }
  const decoded: any = jwtDecode(user?.data?.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return (info) ? {
      isAutenticated: false,
      data: null
    } : false;
  } else {
    return (info) ? user : true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = localStorage.getItem('usuario');
  return user ? (JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser };
