import React, { FC } from 'react';
// Model
import { initialState, formSchema, IFormTerceros, ITerceros } from '../../../../core/models/administracion/tercerosModel';
// Reactstrap
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  ModalBody,
  CustomInput,
} from 'reactstrap';
//FORM
import { Formik, Form, Field } from 'formik';
import { IPaises } from '../../../../core/models/administracion/paisesModel';
import { IEmpresas } from '../../../../core/models/administracion/empresasModel';
import { listRazonSocial, listTipoDocumento } from '../../../../core/services/listasDesplegables';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import OldNumberField from "../../../../shared/components/OldNumberField";
import { useTranslation } from 'react-i18next';

const FormTerceros: FC<IFormTercero> = ({
  onSubmit,
  onCancel,
  empresas,
  paises,
  data,
  tipo,
  showactivar = true
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialState(data, tipo)}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched }) => {
        return (
          <>
            <ModalBody>
              <Form id="formTercero">
                <Row>
                  {!!showactivar && (
                    <Col md={12}>
                      <FormGroup>
                        <Field
                          id="activo"
                          name='activo'
                          as={CustomInput}
                          checked={values.activo}
                          type="switch"
                          label={t('Activar tercero')}
                          invalid={errors.activo && touched.activo}
                        />
                        <FormFeedback style={{
                          display: (errors.activo) ? 'block' : 'none'
                        }}>{errors.activo && t(errors.activo)}</FormFeedback>
                      </FormGroup>
                    </Col>
                  )}
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Número de documento')}</Label>
                      <Field
                        component={OldNumberField}
                        name="documentoempresa"
                        placeholder={t('Número de documento')}
                        className={(errors.documentoempresa && touched.documentoempresa) ? 'is-invalid form-control' : 'form-control'}
                        invalid={errors.documentoempresa && touched.documentoempresa}
                      />
                      <FormFeedback>{errors.documentoempresa && t(errors.documentoempresa)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Tipo de tercero')}</Label>
                      <Row>
                        <Col>
                          <Field
                            id="proveedor"
                            name="proveedor"
                            as={CustomInput}
                            type="checkbox"
                            checked={values.proveedor}
                            label="Proveedor"
                            className="mb-2"
                            disabled={parseInt(tipo as string) === 1}
                            invalid={errors.proveedor && touched.proveedor}
                          />
                        </Col>
                        <Col>
                          <Field
                            id="cliente"
                            name="cliente"
                            as={CustomInput}
                            type="checkbox"
                            checked={values.cliente}
                            label={t('Cliente')}
                            className="mb-2"
                            disabled={parseInt(tipo as string) === 2}
                            invalid={errors.cliente && touched.cliente}
                          />
                        </Col>
                      </Row>
                      <FormFeedback style={{
                        display: ((errors.proveedor && touched.proveedor) || (errors.cliente && touched.cliente)) ? 'block' : 'none'
                      }}>{(errors.proveedor || errors.cliente) && (t(errors.proveedor || errors.cliente || ''))}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Nombre del tercero')}</Label>
                      <Field
                        as={Input}
                        name="razonsocial"
                        placeholder={t('Nombre del tercero')}
                        invalid={errors.razonsocial && touched.razonsocial}
                      />
                      <FormFeedback>{errors.razonsocial && t(errors.razonsocial)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Tipo de razón social')}</Label>
                      <Field
                        as={Input}
                        type="select"
                        name="tiporazonsocial"
                        invalid={errors.tiporazonsocial && touched.tiporazonsocial}
                      >
                        <option value="0" disabled>{t('Seleccione')}</option>
                        {
                          listRazonSocial.map(({ id, nombre }) => (
                            <option key={id} value={id}>{nombre}</option>
                          ))
                        }
                      </Field>
                      <FormFeedback>{errors.tiporazonsocial && t(errors.tiporazonsocial)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Dirección')}</Label>
                      <Field
                        as={Input}
                        name="direccion"
                        placeholder={t('Dirección')}
                        invalid={errors.direccion && touched.direccion}
                      />
                      <FormFeedback>{errors.direccion && t(errors.direccion)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Teléfono')}</Label>
                      <Field
                        as={Input}
                        name="telefono"
                        placeholder={t('Teléfono')}
                        invalid={errors.telefono && touched.telefono}
                      />
                      <FormFeedback>{errors.telefono && t(errors.telefono)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Correo electrónico')}</Label>
                      <Field
                        as={Input}
                        name="email"
                        placeholder={t('Correo electrónico')}
                        invalid={errors.email && touched.email}
                      />
                      <FormFeedback>{errors.email && t(errors.email)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Correo Electrónico Alterno 1')}</Label>
                      <Field
                        as={Input}
                        name="emailalternativo"
                        placeholder={t('Correo Electrónico Alterno 1')}
                        invalid={errors.emailalternativo && touched.emailalternativo}
                      />
                      <FormFeedback>{errors.emailalternativo && t('errors.emailalternativo')}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Correo Electrónico Alterno 2')}</Label>
                      <Field
                        as={Input}
                        name="emailfacturacion"
                        placeholder={t('Correo Electrónico Alterno 2')}
                        invalid={errors.emailfacturacion && touched.emailfacturacion}
                      />
                      <FormFeedback>{errors.emailfacturacion && t(errors.emailfacturacion)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('País de constitución')}</Label>
                      <Field
                        as={Input}
                        type="select"
                        name="idpaises"
                        invalid={errors.idpaises && touched.idpaises}
                      >
                        <option value="0" disabled>{t('Seleccione')}</option>
                        {
                          paises.map(({ id, nombre }) => (
                            (id) && <option key={id} value={id}>{nombre}</option>
                          ))
                        }
                      </Field>
                      <FormFeedback>{errors.idpaises && t(errors.idpaises)}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Nombre del representante legal')}</Label>
                      <Field
                        as={Input}
                        name="representantelegal"
                        placeholder={t('Nombre del representante legal')}
                        invalid={errors.representantelegal && touched.representantelegal}
                      />
                      <FormFeedback>{errors.representantelegal && t(errors.representantelegal)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Correo electrónico del representante legal')}</Label>
                      <Field
                        as={Input}
                        name="emailrepresentante"
                        placeholder={t('Correo electrónico del representante legal')}
                        invalid={errors.emailrepresentante && touched.emailrepresentante}
                      />
                      <FormFeedback>{errors.emailrepresentante && t(errors.emailrepresentante)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Tipo de documento del representante legal')}</Label>
                      <Field
                        as={Input}
                        type="select"
                        name="tiporepresentante"
                        invalid={errors.tiporepresentante && touched.tiporepresentante}
                      >
                        <option value="0" disabled>{t('Seleccione')}</option>
                        {
                          listTipoDocumento.map(({ id, nombre }) => (
                            (id) && <option key={id} value={id}>{nombre}</option>
                          ))
                        }
                      </Field>
                      <FormFeedback>{errors.tiporepresentante && t(errors.tiporepresentante)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Número de documento representante legal')}</Label>
                      <Field
                        component={OldNumberField}
                        name="documentorepresentante"
                        placeholder={t('Número documento representante legal')}
                        className={(errors.documentorepresentante && touched.documentorepresentante) ? 'is-invalid form-control' : 'form-control'}
                        invalid={errors.documentorepresentante && touched.documentorepresentante}
                      />
                      <FormFeedback>{errors.documentorepresentante && t(errors.documentorepresentante)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Persona de contacto')}</Label>
                      <Field
                        as={Input}
                        name="contacto"
                        placeholder={t('Persona de contacto')}
                        invalid={errors.contacto && touched.contacto}
                      />
                      <FormFeedback>{errors.contacto && t(errors.contacto)}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalsFooter
              idForm="formTercero"
              onCancel={onCancel}
            />
          </>
        )
      }}
    </Formik>
  );
};
interface IFormTercero {
  data?: ITerceros;
  paises: IPaises[],
  empresas: IEmpresas[],
  onSubmit: (values: IFormTerceros) => void
  onCancel?: () => void;
  tipo?: number | string;
  showactivar?: boolean;
};
export default FormTerceros;
