import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IDocumentosOferta, IDocumentosOfertaForm } from '../../../../core/models/precontractual/documentosOfertaModel';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import UniqueFileField from '../../../../shared/components/UniqueFileField';

const FormDocsOferta: FC<IFormDocsOferta> = ({ onSubmit, record }) => {
  const { proceso } = usePrecontractualContext();
  const { t } = useTranslation();

  const habilitar = true;

  const initialValues: IDocumentosOfertaForm = {
    id: null,
    activo: 1,
    ...record,
    idprecontractual: proceso?.id || 0,
    idanexo: record?.idanexo?.id || 0,
    idtipodocumento: record?.idtipodocumento?.id || 0,
    idpreproveedor: record?.idpreproveedor?.id || 0,
  } as IDocumentosOfertaForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    idtipodocumento: Yup.number().min(1, FormValidation.required),
    idanexo: Yup.number().min(1, FormValidation.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormDocsOferta" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Anexo')}</Label>
                <Field
                  as={UniqueFileField}
                  name="idanexo"
                  invalid={errors.idanexo && touched.idanexo}
                  disabled={!habilitar}
                  category={record?.idtipodocumento?.idcategoria?.nombremaquina || 'anexos'}
                  modulo="PrePrecontractual"
                  idmodulo={proceso?.id}
                  record={record?.idanexo}
                />
                <FormFeedback>{errors.idanexo && t(errors.idanexo)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormDocsOferta"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormDocsOferta {
  record: IDocumentosOferta | null;
  onSubmit: (values: IDocumentosOfertaForm) => Promise<void>;
}

export default FormDocsOferta;
