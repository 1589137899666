import React, { FC, useMemo } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { useFirmaContext } from '../../../shared/contexts/firmaContext';
import { useTranslation } from 'react-i18next';
import { IFormPrevisualizar } from '../../../core/models/documentos/firmasModel';
import { useParams } from 'react-router-dom';
import { VisorPDFProvider } from '../../../shared/contexts/visorPDFContext';
import PDFObject from '../../../shared/components/VisorDocumentos/PDFObject';
import { obtenerPDFFirma } from '../../../shared/helpers/generales';

const PrevisualizarForm: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { mainState } = useMainContext();
  const { t } = useTranslation();
  const { firma, codigo, imagen, setImagen, setActiveStep, activeStep, handleFirmar } = useFirmaContext();

  const handleVolver = () => {
    setImagen('');
    setActiveStep(activeStep - 1)
  }

  const handleFinalizar = async () => {
    const values: IFormPrevisualizar = {
      id: firma?.id || null,
      firmado: 1,
      codigo,
      imagen,
      token,
      option: 3,
    }
    await handleFirmar(values, token);
  }

  const pdf = useMemo(() => !!firma && obtenerPDFFirma(firma), [firma]);

  return (
    <>
      <h4>{t('Previsualizar documento')}</h4>
      <Alert color="info" className="mb-0">
        {t('Vista previa del documento a firmar.')}
      </Alert>
      <hr />
      {!!pdf && (
        <div className="text-center">
          <VisorPDFProvider
            entidad=''
            idproceso={0}
            editar={false}
            comentar={false}
          >
            <PDFObject
              path={pdf.path}
              nombre={pdf.nombre || 'descarga'}
              />
          </VisorPDFProvider>
        </div>
      )}
      <Row>
        <Col sm={12} md={12}>
          <hr />
          <ButtonCustom
            className="d-flex align-items-center"
            type="button"
            color="link"
            loading={mainState.loading}
            disabled={mainState.loading}
            style={{ float: 'left' }}
            onClick={handleVolver}
          >
            {t('Volver a firmar')}
          </ButtonCustom>
          <ButtonCustom
            className="d-flex align-items-center"
            type="button"
            color="primary"
            loading={mainState.loading}
            disabled={mainState.loading}
            style={{ float: 'right' }}
            onClick={handleFinalizar}
          >
            {t('Finalizar')}
          </ButtonCustom>
          </Col>
      </Row>
    </>
  )
}

export default PrevisualizarForm;
