import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IPreguntas, IPreguntasForm } from '../../../../core/models/precontractual/preguntasModel';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import UniqueFileField from '../../../../shared/components/UniqueFileField';

const FormPreguntas: FC<IFormPreguntas> = ({ 
  onSubmit,
  record,
  idpreproveedor,
  habilitarPreguntar,
  habilitarResponder,
}) => {
  const { proceso } = usePrecontractualContext();
  const { t } = useTranslation();

  const initialValues: IPreguntasForm = {
    id: null,
    pregunta: '',
    respuesta: '',
    activo: 1,
    ...record,
    idprecontractual: proceso?.id ?? 0,
    idpreproveedor: record?.idpreproveedor.id ?? idpreproveedor,
    iddocrespuesta: record?.iddocrespuesta?.id ?? 0,
    iddocpregunta: record?.iddocpregunta?.id ?? 0,
  } as IPreguntasForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    pregunta: Yup.string().when('iddocpregunta', {
      is: (iddocpregunta) => !iddocpregunta,
      then: Yup.string().required(FormValidation.required),
      otherwise: Yup.string().nullable(),
    }),
    respuesta: Yup.string(),
    iddocrespuesta: Yup.number(),
    iddocpregunta: Yup.number(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormPreguntas" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <Label>{t('Pregunta')}</Label>
                <Field
                  as={Input}
                  type="textarea"
                  name="pregunta"
                  invalid={errors.pregunta && touched.pregunta}
                  disabled={!habilitarPreguntar}
                />
                <FormFeedback>{errors.pregunta && t(errors.pregunta)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Documento Pregunta')}</Label>
                <Field
                  as={UniqueFileField}
                  name="iddocpregunta"
                  invalid={errors.iddocpregunta && touched.iddocpregunta}
                  disabled={!habilitarPreguntar}
                  category={'preguntas'}
                  modulo="PrePrecontractual"
                  idmodulo={proceso?.id}
                  record={record?.iddocpregunta}
                />
                <FormFeedback>{errors.iddocpregunta && t(errors.iddocpregunta)}</FormFeedback>
              </FormGroup>
            </Col>
            {habilitarResponder && (
              <>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label>{t('Respuesta')}</Label>
                    <Field
                      as={Input}
                      type="textarea"
                      name="respuesta"
                      invalid={errors.respuesta && touched.respuesta}
                      disabled={false}
                    />
                    <FormFeedback>{errors.respuesta && t(errors.respuesta)}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <FormGroup>
                    <Label>{t('Documento Respuesta')}</Label>
                    <Field
                      as={UniqueFileField}
                      name="iddocrespuesta"
                      invalid={errors.iddocrespuesta && touched.iddocrespuesta}
                      disabled={false}
                      category={'respuestas'}
                      modulo="PrePrecontractual"
                      idmodulo={proceso?.id}
                      record={record?.iddocrespuesta}
                    />
                    <FormFeedback>{errors.iddocrespuesta && t(errors.iddocrespuesta)}</FormFeedback>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormPreguntas"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormPreguntas {
  record: IPreguntas | null;
  onSubmit: (values: IPreguntasForm) => Promise<void>;
  idpreproveedor?: number;
  habilitarPreguntar: boolean;
  habilitarResponder: boolean;
}

export default FormPreguntas;
