import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Card, CardBody } from 'reactstrap';
import { ITokens } from '../../../core/models/usuarios/tokensModel';
import { tokensShowService } from '../../../core/services/usuarios/tokensService';
import DataTableSkeleton from '../../../shared/components/DataTableSkeleton';
import TokenPreProveedores from './TokenPreProveedores';
import { IProveedores } from '../../../core/models/precontractual/proveedoresModel';
import TokenPrePrecontractual from './TokenPrePrecontractual';
import { IPrecontractual } from '../../../core/models/precontractual/precontractualModel';

const Token: FC = () => {
  // Context
  const { tokenurl, id } = useParams<any>();
  const { t } = useTranslation();

  // State
  const [token, setToken] = useState<ITokens | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const obtenerToken = async () => {
    const data = await tokensShowService(id);
    if (data) {
      setToken(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (tokenurl) {
      obtenerToken();
    }
  }, [tokenurl])

  return (
    <Card>
      <CardBody>
        {loading ? (
          <DataTableSkeleton /> 
        ) : (
          <>
            {(token && token.activo === 1) ? (
              <>
                {token.entidad === 'PreProveedores' &&
                  <TokenPreProveedores
                    record={token.proceso as IProveedores}
                  />
                }
                {token.entidad === 'PrePrecontractual' &&
                  <TokenPrePrecontractual
                    record={token.proceso as IPrecontractual}
                  />
                }
              </> 
            ) : (
              <Alert color="info" className="mb-0">{t('El token solicitado no existe')}</Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default Token;
